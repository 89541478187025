export const URL_CONSTANTS = {
    GET_INFO: '/v2/get_info',
    CONFIG_DATA: '/v2/config',
    ADD_BUSINESS_DETAILS : '/v2/add_business_details',
    POST_FORM_DETAILS: '/v2/postDetails',
    FETCH_CITY_STATE: '/v2/pincode_city_state',
    SEND_OTP : '/v2/send_otp',
    VERIFY_OTP : '/v2/verify_otp',
    UPLOAD_IMAGE: '/v2/upload_images',
    DELETE_IMAGE: '/v2/delete_uploads',
    EXPERIAN_CHECK: '/v2/experian_check',
    REQUEST_LOGIN_OTP_URL: '/otp/request',
    VERIFY_LOGIN_OTP_URL: '/otp/verify',
    GET_RETAILER_DETAILS: '/merchant-partner/retailer/details',
    UPLOAD_AGREEMENT: '/merchant-partner/retailer/agreement/upload',
}