import { useState, useCallback, useEffect } from 'react';

/**
 * @function useFormField
 * @param {any} initialValue
 * @param {object} validationRules
 * @description use for check automatic validation
 */
function useFormField(initialValue, validationRules = {}) {
  const [fieldValue, setFieldValue] = useState(initialValue);
  const [isValid, setIsValid] = useState(true);
  const [isTouched, setIsTouched] = useState(false);
  const setFieldValueWithValidation = useCallback(value => {
    setIsTouched(true);
    // eslint-disable-next-line no-prototype-builtins
    if (validationRules.hasOwnProperty('max')) {
      if (value?.length <= validationRules.max) {
        setFieldValue(value);
      }
      return;
    }
    setFieldValue(value);
  }, []);

  /**
   * @description check validation
   */
  useEffect(() => {
    const keys = Object.keys(validationRules);
    keys.forEach(key => {
      switch (key) {
        case 'required':
          if (!fieldValue) {
            setIsValid(false);
            return;
          }
          setIsValid(true);
          break;
        case 'min':
          if (fieldValue.length < validationRules[key]) {
            setIsValid(false);
            return;
          }
          setIsValid(true);
          break;
        case 'maxNumber':
          if (Number(fieldValue) > validationRules[key]) {
            setIsValid(false);
            return;
          }
          setIsValid(true);
          break;
        case 'regex':
          if (!validationRules[key].test(fieldValue)) {
            setIsValid(false);
            return;
          }
          setIsValid(true);
          break;
        default:
        // nothing for default
      }
    });
  }, [fieldValue]);

  return [fieldValue, setFieldValueWithValidation, isValid, isTouched];
}

export default useFormField;
