import React from 'react';
// NAVIGATION
import { HashRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
// CONTAINERS
import { connect } from 'react-redux';
import { NoMatch } from './components/NoMatch';
import { User401 } from './containers/User401';
import RouteManager from './containers/RouteManager';
import { history } from './utils';

const AppRouter = (props) => {
  return (
    <Router history={history}>
      <div>
        <Switch>
          {
            props.config.length > 0 && props.config.map((val, key) => {
              return <Route path={`${val.path}`} component={RouteManager} key={key} />
            })
          }
          <Route exact path="/">
            <Redirect to="/step/1" />
          </Route>
          <Route exact path="/401" component={User401} />
          <Route path="*">
            <NoMatch />
          </Route>
        </Switch>
      </div>
    </Router>
  );
}

const mapStateToProps = ({ RouteConfigReducer }) => ({
  config: RouteConfigReducer.data
});
export default connect(mapStateToProps, null)(AppRouter);