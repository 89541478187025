import React from "react";
import "./styles.scss";
import ApplicationStatusHeader from "../../components/ApplicationStatusHeader";
import { VStepper, VStep } from "../../components/VStepper";
import { Card, Button } from "../../components";
import { connect } from "react-redux";
import { getInfo } from "../../redux/actions/draft-data.action";
import { nativeSupport, StorageUtils } from "../../utils";
import CheckBox from "../../components/Checkbox/CheckBox";
import AppHeader from "../../components/AppHeader";
import { GLOBAL_CONSTANT } from "../../constants/globalConstant";
import { NativeSupportHandlerLegacy } from "native-support-handler";
import getMobileOperatingSystem from "../../utils/platformDetection";
import Modal from "../../components/Modal";
import { RouteManagerUtil } from "../../utils/routeManagerUtil";
import html2pdf from "html2pdf.js";
import HtmlJSX from "../../views/tnc";
import { URL_CONSTANTS } from "../../constants/urlConstant";
import { apiCall } from "../../services";
import { toast } from "react-toastify";

class ApplicationStatus extends React.Component {
  constructor() {
    super();
    this.state = {
      acceptTnC: false,
      showTncModal: false,
      showTnC: true,
      tncLink: "",
      retailerData: {},
      downloadUrl:
        getMobileOperatingSystem() === "iOS"
          ? GLOBAL_CONSTANT.IOS_APP_URL
          : GLOBAL_CONSTANT.ANDROID_APP_URL,
    };
  }

  componentDidMount() {
    this.props.getInfo();
    this.getApplicationDetails();
  }

  /**
   * @function initiateNach
   * @description redirect to E-Nach page
   */
  initiateNach() {
    const { draftReducer } = this.props;
    nativeSupport
      .openDeepLink(draftReducer.deeplink)
      .then(() => {
        this.props.getInfo();
      })
      .catch((e) => {
        // handle error
      });
  }

  getApplicationDetails() {
    apiCall({
      method: "GET",
      url: URL_CONSTANTS.GET_RETAILER_DETAILS,
      options: {
        allowHeaderToken: true,
        useProxy2Server: true,
        useRequestSourceRetailer: true,
      },
    })
      .then((res) => {
        this.setState({
          retailerData: res?.data?.data,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  uploadAgreement(image) {
    apiCall({
      method: "POST",
      url: URL_CONSTANTS.UPLOAD_AGREEMENT,
      data: {
        file: image,
      },
      options: {
        allowHeaderToken: true,
        useRequestSourceRetailer: true,
        useProxy2Server: true,
        hasFormData: true,
      },
    })
      .then((res) => {
        console.log(res);
        this.getApplicationDetails();
      })
      .catch((err) => {
        console.log(err);
      });
  }
  /**
   * @function changeBankAccount
   * @description redirect to change bank account deeplink
   */
  changeBankAccount() {
    const { draftReducer } = this.props;
    nativeSupport.openDeepLinkAndClose(
      null,
      null,
      null,
      draftReducer.bank_change_deeplink
    );
  }

  /**
   * @function orderQR
   * @description redirect to order for QR
   */
  orderQR() {
    NativeSupportHandlerLegacy.openDeeplink(GLOBAL_CONSTANT.ORDER_QR_DEEP_LINK);
  }
  openTncModal() {
    this.setState({
      showTncModal: true,
    });
  }
  setAcceptTnC() {
    this.setState({
      acceptTnC: !this.state.acceptTnC,
    });
  }

  closeTncModal = () => {
    this.setState({
      showTncModal: false,
    });
  };

  goToNext() {
    const { stepId } = this.props.match.params || {};
    RouteManagerUtil.goToNext({ currentStep: stepId });
  }

  downloadPdf = async () => {
    try {
      const input = document.getElementById("tncPdfContent");
      const pdf = await html2pdf()
        .from(input)
        .set({
          margin: 1,
          filename: "TnC.pdf",
          image: { type: "jpeg", quality: 0.6 },
          html2canvas: { scale: 1 },
          jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
          pagebreak: { avoid: "ol, p , table, li, tr" },
        })
        .output("blob");
      await this.uploadAgreement(pdf);
      toast.success("Uploaded Successfully!");
    } catch (err) {
      console.log(err);
    }
  };

  render() {
    const { draftReducer } = this.props;
    const { downloadUrl } = this.state;

    return (
      <div className="application-status">
        <AppHeader
          backToNative
          title="Application Status"
          components={
            <ApplicationStatusHeader
              amount={draftReducer.amount}
              experianType={draftReducer.experian_flag}
              retailerName={draftReducer.form_data?.customer_name}
              distributorName={draftReducer.distributor_name}
            />
          }
        />

        <div className="application-status-body p-18 clr-dark">
          <VStepper>
            <VStep successIcon>
              <Card>
                <div className="font-regular fs-14">Basic Info</div>
              </Card>
            </VStep>
            <VStep successIcon>
              <Card>
                <div className="font-regular fs-14">KYC Info</div>
              </Card>
            </VStep>

            <VStep
              failureIcon={
                draftReducer.nach_status !==
                  GLOBAL_CONSTANT.NACH_STATUS.APPROVED &&
                this.state.retailerData.disbursal_info?.facility_amount > 0
              }
              successIcon={
                !(
                  draftReducer.nach_status !==
                    GLOBAL_CONSTANT.NACH_STATUS.APPROVED &&
                  this.state.retailerData.disbursal_info?.facility_amount > 0
                )
              }
            >
              <Card>
                <div className="font-regular fs-14">Terms of Loan</div>
                {draftReducer.nach_status !==
                  GLOBAL_CONSTANT.NACH_STATUS.APPROVED &&
                  this.state.retailerData.disbursal_info?.facility_amount >
                    0 && (
                    <div className="flex tnc-flex-column">
                      <div className="fs-11 flex-both-cntr mr-16">
                        <CheckBox
                          hasChecked={this.state.acceptTnC}
                          containerClassName="algn-self-start m-r-10"
                          handleCheckBox={() => this.setAcceptTnC()}
                        />
                        <div className="w-100">
                          <p>
                          I have read and accept 
                            <span
                              onClick={() => this.openTncModal()}
                              className="clr-primary"
                            >
                              Terms and Conditions. 
                            </span>
                          </p>
                          <p>
                          I hereby appoint & authorize Resilient Innovations Private Limited (RIPL) as agent to obtain my credit information from credit bureaus and facilitate the services for me as detailed in the T&C.
                          </p>
                        </div>
                      </div>
                      <Button
                        text="Submit"
                        buttonClassName="flex small-button m-b-15 ml-16 mr-16"
                        onClick={() => this.downloadPdf()}
                        disabled={!this.state.acceptTnC}
                      />
                    </div>
                  )}
              </Card>
            </VStep>
            <VStep
              failureIcon={
                draftReducer.nach_status !==
                GLOBAL_CONSTANT.NACH_STATUS.APPROVED
              }
              successIcon={
                draftReducer.nach_status ===
                GLOBAL_CONSTANT.NACH_STATUS.APPROVED
              }
            >
              <Card
                containerClassName={`${
                  draftReducer.nach_status !==
                  GLOBAL_CONSTANT.NACH_STATUS.APPROVED
                    ? "enach-pending-container"
                    : ""
                }`}
              >
                {draftReducer.nach_status ===
                GLOBAL_CONSTANT.NACH_STATUS.APPROVED ? (
                  <div className="font-regular fs-14">Link NACH</div>
                ) : (
                  <div className="font-regular fs-14">
                    <p className="clr-coral fs-14 font-heavy">Link NACH</p>
                    {nativeSupport.isNative() ? (
                      <>
                        <p className="clr-blue-grey fs-12 font-light m-t-10">
                          {draftReducer.nach_text}
                        </p>

                        {draftReducer.nach_check && (
                          <Button
                            text="Retry"
                            buttonClassName="m-t-10 small-button"
                            onClick={() => this.initiateNach()}
                          />
                        )}

                        {draftReducer.nach_check === false &&
                          draftReducer.bank_change_deeplink && (
                            <Button
                              text="Change Bank A/C"
                              buttonClassName="m-t-10 small-button"
                              onClick={() => this.changeBankAccount()}
                            />
                          )}
                      </>
                    ) : (
                      <a href={downloadUrl}>
                        <Button
                          text="Download BharatPe App"
                          buttonClassName="m-t-10 small-button"
                        />
                      </a>
                    )}
                  </div>
                )}
              </Card>
            </VStep>
            {draftReducer.nach_status ===
              GLOBAL_CONSTANT.NACH_STATUS.APPROVED && (
              <VStep pendingIcon>
                <Card>
                  <div className="font-regular fs-14">
                    <p className="fs-14 font-heavy clr-success">
                      Application Submitted
                    </p>
                    <p className="clr-blue-grey fs-12 font-light m-t-10">
                      Your Application is under review and will be processed in
                      the next{" "}
                      <span className="font-heavy clr-dark">3 days</span>.
                    </p>
                  </div>
                </Card>
              </VStep>
            )}
          </VStepper>
        </div>
        {/* <Footer containerClassName={`${false ? 'isDisabled': ''} ${this.props.footerClass}`}>
      </Footer> */}

        <div className="tnc-container">
          <Modal
            visible={this.state.showTncModal}
            onClose={() => this.closeTncModal()}
            animation="slideUp"
            showCloseButton={true}
            closeMaskOnClick={false}
            height={"100%"}
          >
            {/* <HtmlJSX
              {...{
                ...this.state.retailerData,
                date: new Date().toISOString().slice(0, 10),
                lat: StorageUtils.get("lat"),
                lon: StorageUtils.get("lon"),
              }}
            /> */}
            <iframe src="tnc.html" className="width-100" />
          </Modal>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ DraftReducer, FormConfigReducer }) => ({
  draftReducer: DraftReducer.data,
  configReducer: FormConfigReducer?.data,
  tnc: DraftReducer.data?.tnc,
});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  getInfo: (data) => dispatch(getInfo(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ApplicationStatus);
