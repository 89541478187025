import React from "react";
// LIBRARIES
import cx from "classnames";
import PropTypes from 'prop-types';
// STYLES
import "./style.scss";

const KEYBOARD_TYPE = {
  UPPER: 'UPPER_CASE',
  LOWER: 'LOWER_CASE',
  PATTERN: 'PATTERN'
};
class InputDefault extends React.Component {
  renderLabel() {
    const {
      label,
      labelClassName,
      labelstyle,
      readonly,
      requiredField,
    } = this.props;
    return (
      <label
        className={cx(`input__label ${readonly && "active"}`, labelClassName)}
        style={labelstyle}
      >
        {label}&nbsp;
        {requiredField ? <span className="required-text">*</span> : <span>(Optional)</span> }
      </label>
    );
  }

  /**
   * @function onChange
   * @param {string} value 
   * @description events for formatting input value 
   */
  onChange(value) {
    const { onFormValueChange, keyboardType } = this.props;
    switch(keyboardType) {
      case KEYBOARD_TYPE.UPPER:
        value = value.toUpperCase();
      break;
      case KEYBOARD_TYPE.LOWER:
        value = value.toLowerCase();
        break;
      default:
        // noting for default
    }
    onFormValueChange && onFormValueChange(value)
  }

  render() {
    const { props } = this;
    let { inputRef } = props;
    const {
      type,
      primary,
      labelText,
      secondary,
      tertiary,
      flat,
      outline,
      small,
      medium,
      large,
      full,
      centered,
      isValid,
      hasLabel,
      validation,
      className,
      containerClassName,
      textClassName,
      text,
      dropdown,
      textStyle,
      containerStyle,
      readonly,
      style,
      children,
      placeholder,
      errorMsg,
      isPlanValid,
      isNumberValid,
      choosePlan,
      ...others
    } = props;
    
    return (
      <div className="material" style={props.borderstyle}>
        {type !== "textarea" ? (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center"
            }}
          >
            <input
              readOnly={readonly ? true : false}
              type={type}
              onChange = {({target}) => this.onChange(target.value)}
              {...others}
              ref={ref => {
                inputRef = ref;
              }}
              className={cx(
                "input__element",
                {
                  "input__element--primary": primary,
                  "input__element--secondary": secondary,
                  "input__element--flat": flat,
                  "input__element--outline": outline,

                  "input__element--small": small,
                  "input__element--medium": medium,
                  "input__element--large": large,
                  "input__element--full": full,

                  "input__element--valid": isValid && isValid !== null,
                  "input__element--invalid": !isValid && isValid !== null,

                  "input__element--centered": centered
                },
                className
              )}
              placeholder={placeholder}
            />
            {hasLabel && this.renderLabel()}
          </div>
        ) : (
          <textarea
            {...others}
            ref={ref => {
              inputRef = ref;
            }}
            type={type}
            className={cx(
              "input__element",
              {
                "input__element--primary": primary,
                "input__element--secondary": secondary,
                "input__element--flat": flat,
                "input__element--outline": outline,

                "input__element--small": small,
                "input__element--medium": medium,
                "input__element--large": large,
                "input__element--full": full,

                "input__element--valid": isValid && isValid !== null,
                "input__element--invalid": !isValid && isValid !== null,

                "input__element--centered": centered
              },
              className
            )}
            style={style}
          />
        )}
      </div>
    );
  }
}
InputDefault.propTypes = {
  isValid: PropTypes.bool,
  requiredField: PropTypes.bool,
};
InputDefault.defaultProps = {
  isValid: true,
  requiredField: false,
};

export default InputDefault;