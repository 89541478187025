import React, { useCallback, useEffect } from 'react';
import './styles.scss';
import { nativeSupport, firebase, FirebaseEventConstant } from '../../utils';
import AppHeader from '../AppHeader';

export const NoMatch = () => {
    useEffect(() => {
        firebase.logEvent(FirebaseEventConstant.NO_MATCH);
    }, []);

    const goToHome = useCallback(() => {
        nativeSupport.closeWebView();
    }, []);

    return (
        <div className="no-match">
            <AppHeader backToNative />
            <div className="flex-both-cntr no-match-body">
                <div>
                    <h1>You are lost somewhere!!!</h1>
                    <span className="clr-primary underline" onClick={goToHome}>Go to Home</span>
                </div>
            </div>
        </div>
    )
}