import {
  RESEND_OTP_ACTION_LOADING,
  RESEND_OTP_ACTION_SUCCESS,
  RESEND_OTP_ACTION_FAILURE,
} from './constants';
import { INITIAL_STATE } from '../../constants/globalConstant';

export const getResendLoginOtpReducer = (state = INITIAL_STATE, action) => {
  const { type, data, error } = action;

  switch (type) {
    case RESEND_OTP_ACTION_LOADING:
      return {
        ...state,
        dataFetched: false,
        isFetching: true,
        success: false,
        error: '',
      };

    case RESEND_OTP_ACTION_SUCCESS:
      return {
        ...state,
        isFetching: false,
        dataFetched: true,
        success: true,
        data,
      };

    case RESEND_OTP_ACTION_FAILURE:
      return {
        ...state,
        isFetching: false,
        dataFetched: true,
        success: false,
        error,
      };

    default:
      return state;
  }
};
