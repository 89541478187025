import { SHOW_OTP_MODAL_SUCCESS, SHOW_OTP_MODAL_FAILURE } from "../actionTypes";


// show OTP modal
export function showOtpModal() {
  return {
    type: SHOW_OTP_MODAL_SUCCESS,
  }
}

// hide OTP modal
export function hideOtpModal() {
  return {
    type: SHOW_OTP_MODAL_FAILURE,
  }
}
