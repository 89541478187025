(() => {
    /**
     * @description defined **values** function polyfill
     */
    if (typeof Object.values !== 'function') {
        Object.defineProperty(Object, "values", {
            value: function values(obj) {
                var keys = Object.keys(obj);
                var valueArray = [];
                for (var i = 0; i < keys.length; i++) {
                    valueArray.push(obj[keys[i]]);
                }
                return valueArray;
            },
            writable: true,
            configurable: true,
            enumerable: false,
        });
    }
})();