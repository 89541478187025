/**
 *  Imports
 */
import config from "../config";
import axios from 'axios';
import store from '../redux/store';
import { setLoader, removeLoader } from '../redux/actions';
import { toast } from "react-toastify";
import { MESSAGE_CONSTANT } from "../constants/msgConstant";
import { history, nativeSupport, StorageUtils } from "../utils";
import getURLParameter from "../utils/getURLParameter";
import { GLOBAL_CONSTANT } from "../constants/globalConstant";

const defaultInterceptorConfig = {
  defaultLoader: true,
  handleRetry: false,
  useMetaData: false,
  allowHeaderToken: false,
  useProxyServer: false,
  useRequestSourceRetailer : false,
  hasFormData: false
};

let loaderTimerRef = null;

console.log("isProd", process.env.NODE_ENV, process.env.NODE_ENV === 'production');

const appToken = getURLParameter(window.location.href, 'wid') || getURLParameter(window.location.href, 'token');
const merchantVisa = getURLParameter(window.location.href, 'visa');
const BASE_PATH = process.env.NODE_ENV === 'production' ? config.BASE_PATH_PROD: config.BASE_PATH_DEV;
const PROXY_BASE_PATH = process.env.NODE_ENV === 'production' ? config.PROXY_BASE_PATH_PROD : config.PROXY_BASE_PATH_DEV;
const PROXY_2_BASE_PATH = process.env.NODE_ENV === 'production' ? config.PROXY_2_BASE_PATH_PROD: config.PROXY_2_BASE_PATH_DEV;

/**
 * @function getLatLong
 * @description get lat, long from localstorage (in case of merchant app 'lon' key set in localstorage)
 */
const getLatLong = () => {
  return {
    lat: StorageUtils.get('lat'),
    lng: StorageUtils.get('lon'),
  }
}

/**
 * @function getLatLong
 * @description get lat, long from localstorage (in case of merchant app 'lon' key set in localstorage)
 */
const getPlatformName = () => {
  return nativeSupport.isNative() ? GLOBAL_CONSTANT.PLATFORMS.APP : GLOBAL_CONSTANT.PLATFORMS.WEB;
}

/**
 * @function getNativeHeaders
 * @description return default header for Merchant App
 */
const getNativeHeaders = () => {
  let headers = {};
  if (merchantVisa) {
    headers = {
      visa: merchantVisa,
    };
  }
  return headers;
};

/**
 * @function getAppInfo
 * @description return app info
 */
const getAppInfo = () => {
  const latLong = getLatLong();
  const appInfo = {
    app_version:config.app_version,
    client: config.appName,
    lat: latLong.lat,
    long: latLong.lng,
    platform: getPlatformName(),
  };
  return appInfo;
}

const getDefaultParams = (allowHeaderToken) => {
  let params = `app_info=${encodeURIComponent(JSON.stringify(getAppInfo()))}`;
  if (appToken) {
    if (!allowHeaderToken) {
      params += `token=${encodeURIComponent(appToken)}`;
    }
    return params;
  }

  history.replace('/401');
}

const getDefaultBody = (allowHeaderToken) => {
  let body = {
    app_info: getAppInfo(),
  };

  if (appToken) {
    if (!allowHeaderToken) {
      body = {
        ...body,
        token: appToken,
      };
    }
    return body;
  }

  history.replace('/401');
}


/**
 * @function getTokenHeaders
 * @description
 * - generate default headers which can be send with request
 */
const getTokenHeaders = () => {
  let headers = {};
  if (appToken) {
    headers = {
      token: appToken,
    };
  }
  return headers;
};

/**
 * @function jsonToFormData
 * @description
 * - generate form data from json
 */
const jsonToFormData = (jsonObj = {}) => {
  const formData = new FormData();
  // eslint-disable-next-line no-restricted-syntax
  for (const formValue of Object.entries(jsonObj)) {
    const [dKey, dVal] = formValue;
    formData.append(dKey, dVal);
  }
  return formData;
};


/**
 *  API call via Promise
 */
const api = axios.create({
  baseURL: BASE_PATH
});

if (appToken) {
  api.defaults.headers['Content-Type'] = 'application/json;charset=UTF-8';
}



api.interceptors.request.use(configs => {
  configs.options = {...defaultInterceptorConfig, ...configs.options};
  if (nativeSupport.isNative()) {
    configs.headers = { ...getNativeHeaders(), ...configs.headers };
  }

  if (configs.options.allowHeaderToken) {
    configs.headers = { ...getTokenHeaders(), ...config.headers };
  }

  if (configs.options.useRequestSourceRetailer) {
    configs.headers = { ...getTokenHeaders(), 'Request-Source' : 'RETAILER_APP', ...config.headers };
  }
  if (configs.options.hasFormData) {
    // eslint-disable-next-line no-param-reassign
    configs.data = jsonToFormData(configs.data);
  }



  if (configs.options.useProxyServer) {
    configs.baseURL = PROXY_BASE_PATH;
  }
  if (configs.options.useProxy2Server) {
    configs.baseURL = PROXY_2_BASE_PATH;
  }

  if (configs.method === 'get') {
    const defaultParams = getDefaultParams(configs.options.allowHeaderToken);
    configs.url += configs.url.includes('?') ? `&${defaultParams}`: `?${defaultParams}`;
  } else {
    const defaultBody = getDefaultBody(configs.options.allowHeaderToken);
    if(!configs.options.hasFormData)
      configs.data = {...defaultBody, ...configs.data};
  }

  // show default loader
  if (configs.options.defaultLoader) {
    clearTimeout(loaderTimerRef);
    store.dispatch(setLoader('Loading..'));
  }

  if (configs.data === undefined) {
    configs.data = null;
  }
  return configs;
});

api.interceptors.response.use(
  resp => {
    const { options } = resp.config;
    // hide default loader when it's show on request time
    if (options.defaultLoader) {
      loaderTimerRef = setTimeout(() => {
        store.dispatch(removeLoader(''));
      }, 100);
    }
    return resp;
  },
  err => {
    const { config } = err;
    // hide default loader when it's show on request time
    if (config.options.defaultLoader) {
      store.dispatch(removeLoader(''));
    }
    
    // if you have no specific plan B for errors, let them be handled here with a notification
    const { data, status } = err.response || {status: 0};
    let message = '';
    if (500 < status) {
      message = data.message || MESSAGE_CONSTANT.SOMETHING_WRONG;
    } else if (status === 401) {
      history.push('/401');
      message = data?.message || MESSAGE_CONSTANT.UNAUTHORIZED;
    } else {
      message = data?.message || MESSAGE_CONSTANT.SOMETHING_WRONG;
    }

    toast.error(message);
    return {status, data};
  }
);

export default api;
