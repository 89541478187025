import React from "react";
import "./styles.scss";

const VStepper = (props) => {

  return (
    <div className="v-stepper">
      {props.children}
    </div>
  );
}

export default VStepper;
