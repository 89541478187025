import { INITIAL_STATE } from "../../constants/globalConstant";
import { VERIFY_LOGIN_OTP_ACTION, VERIFY_LOGIN_OTP_ACTION_SUCCESS, VERIFY_LOGIN_OTP_ACTION_FAILURE, VERIFY_LOGIN_OTP_ACTION_RESET } from "../actionTypes";

const getVerifyLoginOtpReducer = (state = INITIAL_STATE, action) => {
  const { type, data, error } = action;
  switch (type) {
    case VERIFY_LOGIN_OTP_ACTION:
      return {
        ...state,
        dataFetched: false,
        isFetching: true,
      };
    case VERIFY_LOGIN_OTP_ACTION_SUCCESS:
      return {
        ...state,
        isFetching: false,
        dataFetched: true,
        success: true,
        data,
      };
    case VERIFY_LOGIN_OTP_ACTION_FAILURE:
      return {
        ...state,
        isFetching: false,
        dataFetched: true,
        success: false,
        error,
      };
    case VERIFY_LOGIN_OTP_ACTION_RESET:
      return INITIAL_STATE;
    default:
      return state;
  }
};

export { getVerifyLoginOtpReducer };
