import React from 'react';
// LIBRARIES
import cx from 'classnames';
// STYLES
import './styles.scss';

class Card extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  renderDescription(text) {
    return { __html: text };
  }

  render() {
    const { props } = this;
    const {
      title,
      showTitleSeparator,
      description,
      children,
      containerClassName,
      containerStyles,
      ...others
    } = props;

    return (
      <div
        {...others}
        className={cx("custom-card", containerClassName)}
        style={containerStyles}
      >
        
        { title && <h2 className = "custom-card__title" > {title } </h2> }
        {showTitleSeparator ? <hr className="custom-card-separator"/>: null}
        <div>
          {props.children}
        </div>
      </div>
    );
  }
}

Card.defaultProps = {
  title: "",
  description: "",
};
export default Card;
