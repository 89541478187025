import React from 'react';
// REDUX
import { connect } from "react-redux";
import AppRouter from './routes';
import { getInfo } from './redux/actions/draft-data.action';
import { ToastContainer, toast, Slide } from 'react-toastify';
import getLatLong from './utils/jeoLocation';
import { nativeSupport, firebase, StorageUtils } from './utils';
import { getConfigData, setLoader, removeLoader } from './redux/actions';
import getURLParameter from './utils/getURLParameter';
import LoginContainer from './containers/LoginContainer';
import Loaders from './components/Loaders';
import { requestLoginOtpReset } from './redux/actions/loginOtp.action';
import { verifyLoginOtpActionReset } from './redux/actions/verifyLoginOtp.action';


class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isValidUser: false,
      platformChecked: false,
    };

    // init native support handler
    nativeSupport.initNativeSupport().then(() => {
      nativeSupport.isNative(true);
      nativeSupport.subScribeBackButton();
    });
  }

  componentDidMount() {

    // timeout because native detection take some time around 500ms
    setTimeout(() => {
      firebase.initFirebase().catch(() => {});
      if (nativeSupport.isNative()) {
        this.getAppInfo();
      }
      this.setState({
        platformChecked: true
      });
    }, 1000);
  }

  componentDidUpdate(prevProps) {
    const { verifyLoginOtpReducer: prevVerifyLoginOtpReducer } = prevProps;
    const { verifyLoginOtpReducer } = this.props;
    if (prevVerifyLoginOtpReducer.success !== verifyLoginOtpReducer.success && verifyLoginOtpReducer.success) {
      this.getAppInfo();
      this.props.requestLoginOtpReset();
      this.props.verifyLoginOtpActionReset();
    }
  }

  /**
   * @function getAppInfo
   * @description getting App Info and App Config from server
   */
  getAppInfo() {
    if (nativeSupport.isNative()) {
      // get lat long from url and set to localstorage in case of native app
      const lat = getURLParameter(window.location.href, 'lat');
      const long = getURLParameter(window.location.href, 'lon');
      StorageUtils.set('lat', lat);
      StorageUtils.set('lon', long);

      // request for getInfo
      this.props.getInfo({}, (status) => {
        this.setState({isValidUser: true});
        if (status) {
          this.props.getConfigData();
        }
      });
    } else {
      setTimeout(() => {
        this.props.setLoader();
      }, 100);
      // get lat/long
      getLatLong(15000).then(({lat, long}) => {
        StorageUtils.set('lat', lat);
        StorageUtils.set('lon', long);
        // request for getInfo
        this.props.getInfo({}, (status) => {
          this.setState({isValidUser: true});
          if (status) {
            this.props.getConfigData();
          }
        });
      }).catch(() => {
        this.props.removeLoader();
      });
    }
  }

  render() {
    const { loader } = this.props;
    const { platformChecked, isValidUser } = this.state;
    return (
      <>
        {
          !nativeSupport.isNative() && !isValidUser && platformChecked && (
            <LoginContainer />
          )
        }
        {isValidUser && <AppRouter />}
        
        { !platformChecked && (
          <div className="flex-both-cntr main-loading">
            Loading...
          </div>
        )}

        <ToastContainer
          position={toast.POSITION.BOTTOM_CENTER}
          autoClose={2500}
          hideProgressBar={true}
          closeButton={false}
          transition={Slide}
          draggable={false}
          pauseOnFocusLoss={false}
          closeOnClick={false}
          className="toaster"
        />

        <div>
          <Loaders.Full
            isVisible={loader && loader.isLoading}
          />
        </div>
      </>
    );
  }
}

const mapStateToProps = ({
  VerifyLoginOtpReducer,
  loader,
}) => ({
  verifyLoginOtpReducer: VerifyLoginOtpReducer,
  loader,
});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  getInfo: (data, callback) => dispatch(getInfo(data, callback)),
  getConfigData: () => dispatch(getConfigData()),
  setLoader: () => dispatch(setLoader()),
  removeLoader: () => dispatch(removeLoader()),
  requestLoginOtpReset: () => dispatch(requestLoginOtpReset()),
  verifyLoginOtpActionReset: () => dispatch(verifyLoginOtpActionReset()),
});
export default connect(mapStateToProps, mapDispatchToProps)(App);