/* eslint-disable */
import React from "react";
import "./styles.scss";

const HtmlJSX = React.forwardRef(
  (
    {
      date = "", 
      address, 
      ip = "",
      lat = "",
      lon = "",
      profile, 
      bank_details, 
      disbursal_info, 
      lender_info, 
    } = {},
    ref
  ) => {

    return (
      <div id="tncPdfContent" className="p4 tnc-doc c_black" ref={ref}>
        <p className="font-extra-heavy full-width text-center fs-18 title underline">
          DISTRIBUTOR FACILITY AGREEMENT
        </p>
        <p className="font-regular fs-14 mb2">
          This Distributor
          <span className="font-extra-heavy">{` Facility Agreement (“Agreement”) `}</span>
          is made and executed at the place mentioned in
          <span className="font-extra-heavy">{` Schedule I `}</span>
          (Terms of the Facility) and on the date mentioned in
          <span className="font-extra-heavy">{` Schedule I `}</span>
          (Terms of the Facility) by and between:
        </p>
        <p className="font-regular fs-14">
          {`${lender_info?.full_name} a non-banking finance company/NBFC
          incorporated under the provision of Companies Act, 1956 and governed
          under the provision of Companies Act, 2013 including its amendments
          thereto and, having its registered office at ${lender_info?.address} (hereinafter
          referred to as the`}
          <span className="font-extra-heavy">{` “Lender” `}</span>
          which expression shall, unless repugnant to the context or meaning
          thereof, be deemed to mean and include its successor(s) and permitted
          assign(s)) of the
          <span className="font-extra-heavy">{` One Part;`}</span>
        </p>
        <p className="font-extra-heavy full-width text-center fs-14 my2">
          AND
        </p>
        <p className="font-regular fs-14">
          <span className="font-extra-heavy italic">{`[Details from the Schedule I] `}</span>
          , hereto as the borrower (wherever the context so requires)
          (hereinafter referred to as the
          <span className="font-extra-heavy">{` “Borrower” `}</span>
          unless repugnant to the context or meaning thereof, be deemed to mean
          and include: (i) in the event that the Borrower is a company within
          the meaning of the Companies Act, 1956/2013 as may be applicable or a
          Limited Liability Partnership, incorporated under the Limited
          Liability Partnership Act, 2008, its successors; (ii) in the event
          that the Borrower is a partnership firm for the purposes of the Indian
          Partnership Act, 1932, the partners for the time being and from time
          to time and their respective legal heirs, executors and
          administrators, legal representatives and successors; (iii) in the
          event that the Borrower is a sole proprietorship, the sole proprietor
          and his/ her legal heirs, administrators, executors and legal
          representatives;
        </p>
        <p className="font-regular fs-14">
          The Lender and the Borrower are hereinafter collectively referred to
          as the
          <span className="font-extra-heavy italic">{`“Parties” `}</span>
          and each individually as the
          <span className="font-extra-heavy italic">{`“Party”.`}</span>
        </p>
        <p className="font-extra-heavy full-width fs-14 my2">
          WHEREAS:
        </p>
        <ol type="A" className="pl2 font-regular fs-14">
          <li>
            The Lender is a non-banking financing company, registered with the
            Reserve Bank of India, having registration no. B-13.01946 and is
            inter alia engaged in the business of advancing loans.
          </li>
          <li>
            The Borrower has approached the Lender and has requested for grant
            of facility for the purpose{" "}
            <span className="font-extra-heavy italic">{` as mentioned in Schedule I `}</span>
            and in reliance on the acceptance of the terms, conditions,
            assurances, representations and warranties of the Borrower, the
            Lender has agreed to grant facility to the Borrower, subject to the
            terms and conditions contained in this Agreement.
          </li>
          <li>
            The Parties hereto are now desirous of inter alia entering into this
            Agreement to set out the terms and conditions in relation to the
            Facility.
          </li>
        </ol>
        <p className="font-extra-heavy full-width fs-14 my2">
          Now, therefore, in view of the foregoing and in consideration of the
          mutual covenants and agreements herein set forth, the parties hereby
          agree as follows:
        </p>
        <ol type="1" className="font-regular fs-14">
          <li>
            <p className="font-extra-heavy full-width fs-14 title">
              DEFINITIONS AND INTERPRETATION
            </p>
            <ol type="1" className="pl3 font-regular fs-14">
              <li className="font-extra-heavy full-width fs-14 sub-title">
                Definitions
                <ol className="pl5">
                  <li className="font-regular fs-14">
                    <span className="font-extra-heavy">{`“Access Code” `}</span>
                    means any authentication mode as approved by Lender
                    including without limitation, combination of user name and
                    password.
                  </li>
                  <li className="plfont-regular fs-14">
                    <span className="font-extra-heavy">{`“Annualised Rate of Interest” `}</span>
                    means the Interest Rate as mentioned in Schedule I (Terms of
                    the Facility).
                  </li>
                  <li className="font-regular fs-14">
                    <span className="font-extra-heavy">
                      {`“Authorization” `}
                    </span>
                    means any consent, registration, filing, agreement,
                    notarization, certificate, license, approval, permit,
                    authority or exemption from, by or with any Authority,
                    whether given by express action or deemed given by failure
                    to act within any specified time period and all corporate,
                    creditors and shareholders’ approvals or consents.
                  </li>
                  <li className="font-regular fs-14">
                    <span className="font-extra-heavy">
                      {`“Availability  Period ” `}{" "}
                    </span>{" "}
                    means the period as specified in Schedule I (Terms of the
                    Facility) of this Agreement, for which the Facility is
                    sanctioned by the Lender to the Borrower.
                  </li>
                  <li className="font-regular fs-14">
                    <span className="font-extra-heavy">{`“BharatPe” `}</span>
                    means Resilient Innovation Private Limited.
                  </li>
                  <li className="font-regular fs-14">
                    <span className="font-extra-heavy">{`“Borrower” `}</span>
                    means the Person who has availed/availing credit facility
                    from the Lender.
                  </li>
                  <li className="font-regular fs-14">
                    <span className="font-extra-heavy">{`“Borrower Account” `}</span>
                    means the bank account of the Borrower as{" "}
                    <span className="font-extra-heavy">
                      {" "}
                      mentioned in Schedule I or as updated by the borrower in
                      the BharatPe platform,
                    </span>{" "}
                    unless otherwise notified by the Borrower in writing.
                  </li>
                  <li className="font-regular fs-14">
                    <span className="font-extra-heavy">{`“Chargeable  Interest” `}</span>
                    shall have the same meaning ascribed to such term under
                    Clause 6.1 to this Agreement.
                  </li>
                  <li className="font-regular fs-14">
                    <span className="font-extra-heavy">{`“Due Date” `}</span>
                    means the date(s) on which any amounts are due from the
                    Borrower to the Lender including this Agreement or any other
                    Financing Document as demanded by the Lender.
                  </li>
                  <li className="font-regular fs-14">
                    <span className="font-extra-heavy">{`“Events of Default” `}</span>
                    shall have the meaning ascribed to it under the terms
                    herein.
                  </li>
                  <li className="font-regular fs-14">
                    <span className="font-extra-heavy">{`“Facility” `}</span>
                    shall have the meaning ascribed to it under the terms
                    herein.
                  </li>
                  <li className="font-regular fs-14">
                    <span className="font-extra-heavy">{`“Facility Application” `}</span>
                    means the application made by the Borrower in the form
                    specified by the Lender for availing the Facility, through
                    online mode or otherwise and where the context so requires,
                    all other information, particulars submitted by the Borrower
                    to the Lender with a view to avail the Facility.
                  </li>
                  <li className="font-regular fs-14">
                    <span className="font-extra-heavy">{`“Final Settlement Date” `}</span>
                    means the date on which all the Outstanding Amounts have
                    been fully paid and the Facility has been irrevocably
                    discharged to the satisfaction of the Lender.
                  </li>
                  <li className="font-regular fs-14">
                    <span className="font-extra-heavy">{`“Financing Documents” `}</span>
                    means this Agreement and such other documents (including the
                    security documents as the case may be) as may be executed or
                    required to be executed between the Lender and the Borrower
                    in order to perfect or validate this Agreement.
                  </li>
                  <li className="font-regular fs-14">
                    <span className="font-extra-heavy">{`“Government Authority” `}</span>
                    means any governmental department, commission, board,
                    bureau, agency, regulatory authority, instrumentality, court
                    or other judicial, quasi-judicial or administrative body,
                    whether central, state, provincial or local, having
                    jurisdiction over the subject matter or matters in question.
                    For avoidance of doubt, it is hereby clarified that the term
                    “Government Authority” does not include any bank/financial
                    institution acting solely in its capacity as a lender to the
                    Borrower.
                  </li>
                  <li className="font-regular fs-14">
                    <span className="font-extra-heavy">{`“Interest/ Interest Rate” `}</span>
                    means the rate of interest mentioned in Schedule I (Terms of
                    the Facility) and shall have the meaning as assigned to such
                    term in clause 6.
                  </li>
                  <li className="font-regular fs-14">
                    <span className="font-extra-heavy">{`“Laws” `}</span>
                    means any statute, law, regulation, ordinance, rule,
                    judgment, order, decree, bye-laws, rule of law, directives,
                    guidelines policy, requirement, or any governmental
                    restriction, governmental norms or any similar form of
                    decision of, or determination by, or any interpretation or
                    administration having the force of law of any of the
                    foregoing, by any Government Authority having jurisdiction
                    over the matter in subject, whether in effect as of the date
                    of this Agreement or hereafter.
                  </li>
                  <li className="font-regular fs-14">
                    <span className="font-extra-heavy">{`“Material Adverse Effect” `}</span>
                    means adverse effect on: (a) the ability of the Borrower to
                    observe and perform in a timely manner their respective
                    obligations under any of the Financing Documents to which it
                    is or would be a party or; (b) the legality, validity,
                    binding nature or enforceability of any of the Financing
                    Documents; or (d) the Business or financial condition of the
                    Borrower which is reasonably likely to impair its ability to
                    service the Facility as and when becoming due; or (e) the
                    rights and remedies of the Lender under the Financing
                    Documents.
                  </li>
                  <li className="font-regular fs-14">
                    <span className="font-extra-heavy">{`“Outstanding Amounts” `}</span>
                    mean principal amount of the Facility outstanding from time
                    to time, and all Chargeable Interest, Penal Interest,
                    prepayment charges, costs, commissions, fees & charges,
                    expenses and other amounts due under or in respect of this
                    Agreement / in respect of the Facility.
                  </li>
                  <li className="font-regular fs-14">
                    <span className="font-extra-heavy">{`“OTP” `}</span>
                    shall mean one time password sent to the Borrower’s/
                    Retailer’s cell phone/ registered mobile number for the
                    purpose of authentication
                  </li>
                  <li className="font-regular fs-14">
                    <span className="font-extra-heavy">{`“Payment Mechanism” `}</span>
                    means ECS, ACH, NEFT, RTGS or payment by way of cheque, as
                    the case may be.
                  </li>
                  <li className="font-regular fs-14">
                    <span className="font-extra-heavy">{`“Person” `}</span>
                    shall, unless specifically provided otherwise, mean any
                    individual, corporation, sole proprietorship, partnership,
                    association of persons, company, joint stock company, trust
                    or Government Authority, as the context may admit.
                  </li>
                  <li className="font-regular fs-14">
                    <span className="font-extra-heavy">{`“Prepayment” `}</span>
                    means the premature repayment of the Facility as per the
                    terms and conditions approved by the Lender in this regard
                    and prevailing at the time of such premature repayment by
                    the Borrower.
                  </li>
                  <li className="font-regular fs-14">
                    <span className="font-extra-heavy">{`“Purpose/ End Use” `}</span>
                    means the purpose for which the Facility has been agreed to
                    be utilised by the Borrower, as mentioned in
                    <span className="font-extra-heavy">{` Schedule I `}</span>
                    (Terms of the Facility) to this Agreement.
                  </li>
                  <li className="font-regular fs-14">
                    <span className="font-extra-heavy">{`“RBI”  `}</span>
                    means the Reserve Bank of India.
                  </li>
                  <li className="font-regular fs-14">
                    <span className="font-extra-heavy">{`“Tax” `}</span>
                    means any tax, levy, impost, duty or other charge or
                    withholding of a similar nature (including any penalty or
                    interest payable in connection with the failure to pay or
                    delay in paying any of the same).
                  </li>
                  <li className="font-regular fs-14">
                    <span className="font-extra-heavy">{` “Tenure” `}</span>
                    means the period as specified in
                    <span className="font-extra-heavy">{` Schedule I `}</span>
                    (Terms of the Facility) of this Agreement, within which the
                    Facility has to be repaid by the Borrower to the Lender
                    along with interest, cost, expenses, fees & charges and
                    other amount as specified in this Agreement.
                  </li>
                  <li className="font-regular fs-14">
                    <span className="font-extra-heavy">{` “Tranche” `}</span>
                    shall mean the revolving credit facility granted hereunder
                    in the relevant Facility amount, the principal amount
                    thereof for the time being advanced and outstanding under
                    this Agreement.
                  </li>
                </ol>
              </li>
              <li>
                <p className="font-regular fs-14 sub-title">
                  <span className="font-extra-heavy">{`Principles of Interpretation: `}</span>
                  In this Agreement, unless the context otherwise requires:
                </p>
                <ol className="pl5">
                  <li>
                    The headings are for convenience or reference only and shall
                    not be used in and shall not affect the construction or
                    interpretation of this Agreement.
                  </li>
                  <li>
                    The words “include” and “including” are to be construed
                    without limitation.
                  </li>
                  <li>
                    Words importing a particular gender shall include all
                    genders.
                  </li>
                  <li>
                    References to any law shall include references to such law
                    as it may, after the date of this Agreement, from time to
                    time be amended, supplemented or re-enacted.
                  </li>
                  <li>
                    The Schedule(s) annexed to this Agreement form an integral
                    part of this Agreement and will be of full force and effect
                    as though they were expressly set out in the body of the
                    Agreement;
                  </li>
                  <li>
                    Reference to any agreement, including this Agreement, deed,
                    document, instrument, rule, regulation, notification,
                    statute or the like shall mean a reference to the same as
                    may have been duly amended, modified or replaced. For the
                    avoidance of doubt, a document shall be construed as
                    amended, modified or replaced only if such amendment,
                    modification or replacement is executed in compliance with
                    the provisions of such document(s);
                  </li>
                  <li>
                    In the event of any disagreement or dispute between the
                    Lender and the Borrower regarding the materiality or
                    reasonableness of any matter, the opinion of Lender as to
                    the materiality shall be final and binding on the Borrower.
                  </li>
                </ol>
              </li>
            </ol>
          </li>
          <li>
            <p className="font-extra-heavy full-width fs-14 title">FACILITY</p>
            <ol type="A" className="pl2 font-regular fs-14">
              <li>
                The Borrower hereby unconditionally and irrevocably authorises
                the Lender to grant the Facility to the Borrower towards the
                Purpose/ End use, as mentioned in Schedule I. The Borrower
                undertakes to comply with all the terms and conditions set forth
                herein.
              </li>
              <li>
                If in future, the Borrower approaches the Lender for grant of an
                additional facility or increase in the amount of Facility, the
                Lender shall have the sole discretion for granting the same and
                the Lender can either proceed with the execution of fresh
                Facility Agreement with the Borrower or execute a supplemental
                Facility Agreement.
              </li>
              <li>Disbursement shall be made only to Borrower.</li>
              <li>
                The Lender shall have the right to adjust and/or set off any
                Outstanding Amounts or other dues against any subsequent amount
                of the Facility due to be disbursed by the Lender to the
                Borrower. The Borrower also authorizes BharatPe to deduct on the
                instruction of the Lender all outstanding amounts under the
                Agreement from the prepaid card of the Borrower / POS payments/
                QR transactions of the Borrower / other payments collected for
                the benefit of the Borrower for repayment of the Facility
                availed by the Borrower along with all applicable interest,
                fees, costs and any other charges.
              </li>
              <li>
                Notwithstanding anything stated herein, the
                continuation/modification of the Facility and terms shall be at
                sole and absolute discretion of the Lender and the Lender may at
                any time in its sole discretion and without assigning any reason
                call upon the Borrower to pay the Outstanding Balance and upon
                such demand by the Lender, the Borrower shall, within 48 hours
                of being so called upon, pay the whole of the Outstanding
                Balance to the Lender without any delay or demur.
              </li>
              <li>
                The Lender may, at its discretion, maintain appropriate entries
                in its books of accounts in relation to the Facility and such
                entries shall be final and binding upon the Borrower
              </li>
            </ol>
          </li>
          <li>
            <p className="font-extra-heavy full-width fs-14 title">
              FACILITY DISBURSAL
            </p>
            <ol type="A" className="pl2 font-regular fs-14">
              <li>
                The Borrower agrees to avail Facility in order to clear the
                amount(s) of invoices billed by the Borrower to external third
                parties referred to as (“Retailer/s”).
              </li>
              <li>
                The Borrower shall register and cause Retailers to register on
                BharatPe platform using a mobile number trusted by each party
                and download the BharatPe app to enable BharatPe Services. The
                registered mobile numbers of the Retailers and Borrower are
                updated from time to time on Platform with such other details of
                the Retailers. The Borrower ensures and undertakes the
                responsibility of genuine details shared of each Retailer which
                are added on Platform.
              </li>
              <li>
                The Borrower enters the details of final undisputed invoices on
                a platform provided by BharatPe (“Platform”) and raisesFacility
                Tranche Request as mentioned in Schedule II, pertaining to
                single or multiple invoices. The Borrower warrants that the said
                undisputed invoices are genuine and have not been financed
                earlier by any third party. All Invoices must have Unique
                Invoice Number which is required to be shared with the Lender
                through BharatPe and must be authenticated by the Borrower at
                its own discretion and the Facility Tranche Request is
                authenticated and accepted through an OTP Mechanism. The OTP
                mechanism for verification shall be operated and generated by
                BharatPe.
              </li>
              <li>
                In case any time during the Availability Period of the Facility
                against the respective undisputed invoice(s), Lender (or
                BharatPe on behalf of the Lender) recognizes/doubts the
                authenticity of said Invoice, Borrower without any demur,
                protest and on demand within 02 business days, shall repay the
                Facility in form & manner as directed by Lender (or BharatPe on
                behalf of the Lender).
              </li>
              <li>
                The collective disbursed outstanding amount “Limit” shall be as
                mentioned in Schedule 1, if applicable.
              </li>
              <li>
                Lender shall, within 2 business days from the date of Facility
                Tranche Request, disburse an amount, after deducting Chargeable
                Interest from the Facility Tranche amount to Borrower Account.
                Such date of disbursal of Facility Tranche from the Lender’s
                accounts (“Disbursal Date”) shall be treated as the start of the
                Tenure.
              </li>
              <li>
                The Borrower warrants to Lender the smooth and timely repayments
                for such Facility.
              </li>
              <li>
                For abundant clarity, the Facility details including but not
                limited to Interest Rate, Due Date, principal amount shall be
                available on Platform.
              </li>
            </ol>
          </li>
          <li>
            <p className="font-extra-heavy full-width fs-14 title">
              MODE OF DISBURSAL
            </p>
            <ol type="A" className="pl2 font-regular fs-14">
              <li className="font-regular fs-14">
                On the instruction of the Borrower, the Facility Tranche shall
                be disbursed directly through RTGS/NEFT/IMPS to the Borrower’s
                Account as contained in Schedule I hereto.
              </li>
              <li className="font-regular fs-14">
                Subject to the terms and conditions of this Agreement, the
                Borrower hereby agrees and acknowledges to accept, at the time
                of the drawdown, the ‘Facility Tranche Request Form’ contained
                in Schedule II hereto.
              </li>
            </ol>
          </li>
          <li>
            <p className="font-extra-heavy full-width fs-14 title">
              PRINCIPAL REPAYMENT & PERIOD
            </p>
            <p>
              The amount of each Facility Tranche shall be repayable by Borrower
              to the Lender via BharatPe systems, as bullet repayment, at the
              end of Tenure of each Facility Tranche on Due Date.
            </p>
          </li>
          <li>
            <p className="font-extra-heavy full-width fs-14 title">INTEREST</p>
            <ol>
              <li className="font-regular fs-14">
                The Borrower authorises the Lender to deduct an amount
                equivalent to the total Interest due on the Facility
                (‘Chargeable Interest’) from the Facility Tranche amount. The
                Lender shall set-off/ amortise the Chargeable Interest on a
                daily basis till the end of the Tenure (as specified under
                Schedule I)/ pre-payment by the Borrower towards Interest due
                for the Facility.
              </li>
              <li className="font-regular fs-14">
                The Borrower agrees that the Lender has adopted risk based
                pricing which is arrived at after taking into account broad
                parameters like customer profile, financials, sources of funds,
                risk profile of the customer, nature of lending etc. and hence
                rate of interest may differ across Borrowers.
              </li>
              <li className="font-regular fs-14">
                The Lender at its sole discretion, may change the prevailing
                Interest Rate on the Facility, either due to change in its
                policies, prevailing market condition or issuance of RBI
                guidelines and notifications with respect to the same or for any
                other reason whatsoever and in such an event the term 'Interest
                Rate' shall for all purposes mean the revised interest rate,
                which shall always be construed as agreed to be paid by the
                Borrower and hereby secured. The change in the Interest Rate
                shall be effected only prospectively.
              </li>
              <li className="font-regular fs-14">
                In case of delay or default in payment, additional Penal
                Interest at the rate as specified under Schedule I, over and
                above the Interest Rate shall be payable on the Outstanding
                Amount.
              </li>
            </ol>
          </li>
          <li>
            <p className="font-extra-heavy full-width fs-14 title">
              FEES & REPAYMENT
            </p>
            <ol type="A" className="pl2 font-regular fs-14">
              <li>
                The Borrower shall, on or before or after the disbursement of
                the Facility, bear, pay and reimburse to the Lender all cost,
                fee, charges, including stamp duty charges, applicable on the
                Financing Documents and any increased costs expenses incurred
                and/or to be incurred by the Lender, on a full indemnity basis,
                in connection with the Facility.
              </li>
              <li>
                The Borrower shall, on or before the disbursement of the
                Facility, pay to the Lender processing/service fee calculated at
                the rate provided in
                <span className="font-extra-heavy">{` Schedule I `}</span>
                (Terms of the Facility) to this Agreement, on the amount of the
                Facility sanctioned by the Lender along-with applicable GST. The
                processing/service fee shall be non-refundable. The Lender shall
                be entitled to recover the non-refundable processing fees and
                GST. Provided that, in case the borrower does not default in any
                repayment of the Facility and other charges and costs and does
                not prepay the facility, the processing fee will be refunded to
                the borrower at the end of the Tenure of the facility.
              </li>
              <li>
                All fees and charges payable by the Borrower to the Lender under
                this Clause shall be reimbursed by the Borrower to the Lender
                within 7 (seven) days from the date of notice of demand from the
                Lender and shall be debited to the Borrower Account.
              </li>
              <li>
                The Lender have appointed Resilient Innovations Private Limited
                (BharatPe) having registered office at 90/20, Malviya Nagar, New
                Delhi 110017 as its collection agent and for such other services
                as agreed between the Lender and BharatPe, from time to time.
                All Outstanding Balance shall be payable/paid as may be directed
                & advised by Lender or BharatPe (acting on behalf of the
                Lender).
              </li>
              <li>
                The Borrower shall repay the Facility, if not demanded earlier
                by Lender pursuant to a Facility Agreement, as stipulated in and
                in accordance with and subject to the terms and conditions of
                the Repayment Schedule set out in the BharatPe platform
              </li>
              <li>
                No notice, reminder or intimation in any manner shall be given
                by the Lender to the Borrower regarding its obligation and
                responsibility to ensure prompt and regular payment of the
                Outstanding Amounts to the Lender on Due Dates. It shall be
                entirely the Borrower's responsibility to ensure prompt and
                regular payment of the Outstanding Amount.
              </li>
              <li>
                The Borrower agrees that the repayment of the amount of Facility
                together with Interest, Penal Interest, if any, and all such
                other sums due and payable by the Borrower to the Lender shall
                be payable to the Lender Account by way of a payment mechanism
                approved by the Lender, provided that the Lender may, at its
                sole discretion, require the Borrower to adopt or switch to any
                alternative mode of payment and the Borrower shall comply with
                such request, without demur or delay. The Borrower undertakes to
                remit all Outstanding Amounts to the Lender on the respective
                Due Date.
              </li>
              <li>
                Any instruction under the payment mechanism which is revoked/
                dishonoured shall make the Borrower liable for payment of
                charges as per the prevailing rules of the Lender in force from
                time to time, in addition to any Penal Interest that may be
                levied by the Lender and without prejudice to the Lender's right
                to take appropriate legal action against the Borrower for such
                revocation / dishonour.
              </li>
              <li>
                The Lender expressly reserves its right to call upon the
                Borrower to pay the whole or part of the Outstanding Amounts at
                any time after the date of first Drawdown in the event of a
                default by the Borrower under any Financing Document.
              </li>
              <li>
                Lender may charge Pre-Payment Charges, if applicable and as
                specified under Schedule I, in accordance with RBI regulation.
              </li>
              <li>
                Subject to sole discretion of the Lender, in the event of any
                change in Repayment Schedule (at the request of the Borrower or
                due to an Event of Default), the Borrower shall be liable to pay
                rescheduling charges at the rate specified this Agreement. Such
                payment of rescheduling charges shall be in addition to any
                other rights and remedies available with the Lender in the Event
                of Default or otherwise.
              </li>
            </ol>
          </li>
          <li>
            <p className="font-extra-heavy full-width fs-14 title">
              CREDIT RISK AND COLLECTION OF REPAYMENT
            </p>
            <ol type="A" className="pl2 font-regular fs-14">
              <li>
                All credit risk on the collection/receivables of Outstanding
                Amount from Borrower shall be borne by the Lender. BharatPe
                shall bear no credit risk.
              </li>
              <li>
                BharatPe shall provide the Lender support to collect the Loan
                and any Interest or charges thereupon, without prejudice to
                legal rights of BharatPe and/or Lender, including but not
                limited to such other arrangements as it may deem fit to protect
                the money.
              </li>
            </ol>
          </li>
          <li>
            <p className="font-extra-heavy full-width fs-14 title">
              PENAL INTEREST
            </p>
            <ol type="A" className="pl2 font-regular fs-14">
              <li>
                Upon occurrence of any of the events mentioned under this
                Facility Agreement, the Borrower shall be liable to pay Penal
                Interest which shall be in addition to the Interest payable by
                the Borrower
              </li>
              <li>
                The Borrower expressly agrees that the rate of Penal Interest is
                a fair estimate of the loss likely to be suffered by the Lender
                by reason of such delay/default on the part of the Borrower.
              </li>
              <li>
                Penal Interest shall accrue from day to day and shall be
                computed on the basis of 365 (three hundred and sixty) days a
                year (irrespective of leap year).
              </li>
              <li>
                Penal Interest shall be computed for (I) in case the Penal
                Interest is payable due to default/delay in any payment, then
                the period commencing from the Due Date of payment of the amount
                in default/delay up to the payment of amount in default/delay
                along-with Penal Interest and (ii) in case of occurrence of any
                other Event of Default, for the period during which the Event of
                Default or breach, as the case may be, persists.
              </li>
              <li className="font-extra-heavy">
                Penal Interest Rate: as specified in Schedule I to this
                Agreement 1
              </li>
            </ol>
          </li>
          <li>
            <p className="font-extra-heavy full-width fs-14 title">TAXES</p>
            <p>
              The Borrower shall make all payments to be made by it hereunder
              without and free from any Tax deduction and/or other deduction
              and/or withholding and/or statutory levies/duties/charges
              <span className="font-extra-heavy">(“Withholding”)</span>, unless
              a Withholding is required by Law.
            </p>
          </li>
          <li>
            <p className="font-extra-heavy full-width fs-14 title">
              PURPOSE OF THE FACILITY
            </p>
            <ol type="A" className="pl2 font-regular fs-14">
              <li>
                The Borrower undertakes and confirms that the entire Facility
                amount shall be utilized/ deployed only for the Purpose/End Use
                as stated under Schedule I (Terms of the Facility) and for no
                other purpose that shall include without limitation to invest in
                share market, real estate or in any subsidiary/ associates of
                the Borrower.
              </li>
              <li>
                Any default, fraud, legal incompetence during the currency of
                the limits, non-compliance of agreed terms and conditions,
                non-submission of required papers, any other irregularities by
                the Borrower will enable the Lender to recall the Facility.
              </li>
              <li>
                The Borrower further confirms and/or undertakes that the
                Facility shall not be utilized for the following:
                <ol type="i" className="pl2 font-regular fs-14">
                  <li>Subscription to or purchase of shares/debentures;</li>
                  <li>
                    Extending unsecured loans to subsidiary company/ associates
                    or for making inter corporate deposits;
                  </li>
                  <li>
                    Any speculative purposes or any anti-social purpose or any
                    unlawful purpose.
                  </li>
                </ol>
              </li>
            </ol>
          </li>
          <li>
            <p className="font-extra-heavy full-width fs-14 title">COVENANTS</p>
            <ol type="A" className="pl2 font-regular fs-14">
              <li>
                The Borrower agrees to promptly notify, in writing, the Lender
                about any litigation, arbitration, investigative, regulatory or
                administrative proceeding/action, any court cases by or against
                having a Material Adverse Effect.
              </li>
              <li>
                All terms and conditions of this Agreement including the
                Repayment Schedule in relation to the Facility shall remain same
                even if any amount under the Facility is being taken over
                by/assigned to any new lender.
              </li>
              <li>
                The Borrower declares that all the amounts including the amount
                of own contribution paid/ payable in connection with the
                Facility, is/ shall be through legitimate source and does not/
                shall not constitute an offence of money laundering under the
                Prevention of Money Laundering Act, 2002.
              </li>
              <li>
                The Borrower shall perform, on request of the Lender, such acts
                as may be necessary to carry out the intent of the Financing
                Documents.
              </li>
              <li>
                The Borrower shall deliver to the Lender in form and detail,
                such details, information, documents etc. to the satisfaction of
                the Lender, as may reasonably be required, within such period as
                required by the Lender from time to time.
              </li>
              <li>
                {" "}
                In case of a death of the Borrower, the Borrower understands and
                accepts that the outstanding amounts in respect of the credit
                facility shall constitute a charge on the estate of the Borrower
                and shall be defrayed in priority to all other payments.{" "}
              </li>
              <li>
                In case the Borrower is a body corporate, it shall not induct
                any person on the board of directors or as partners who have
                been identified as a wilful defaulter by the RBI. The Borrower
                confirms that neither it nor any member of its organisation has
                been declared as wilful defaulter.
              </li>
              <li>
                The Borrower hereby agrees, undertakes and covenants that unless
                the Lender otherwise agrees in writing, so long as the Facility
                or any part thereof is outstanding and an Event of Default has
                occurred and continuing, until full and final payment of all
                money owing hereunder, the Borrower
                <span className="font-extra-heavy">{` SHALL NOT`}</span>:
                <ol type="i" className="pl2 font-regular fs-14">
                  <li>
                    Grant any facilities; grant any credit (except in the
                    ordinary course of business) to or for the benefit of any
                    Person other than itself.
                  </li>
                  <li>
                    Allow its principal shareholders/ directors/ promoters/
                    partners to withdraw monies brought in by them or withdraw
                    the profits earned in the business/capital invested in the
                    business.
                  </li>
                </ol>
              </li>
            </ol>
          </li>
          <li>
            <p className="font-extra-heavy full-width fs-14 title">
              UNCONDITIONAL RIGHT TO CANCEL THE UN-UTILIZED PORTION OF THE
              FINANCIAL FACILITY
            </p>
            <p>
              During the Availability period, the Lender may, in its sole
              discretion, cancel the Facilities, if any Event of Default or
              Potential Event of Default has occurred or if it becomes unlawful
              for the Lender to disburse or continue the Facilities to the
              Borrower. Further the Borrower unconditionally agrees, undertakes
              and acknowledges that the Lender has an unconditional right to
              cancel the un-utilised portion of the Facility, whether in part or
              in full, at any time during the currency of the Facility/Loan
              without any prior intimation for such cancellation to the Borrower
            </p>
          </li>
          <li>
            <p className="font-extra-heavy full-width fs-14 title">
              REPRESENTATIONS AND WARRANTIES
            </p>
            <ol type="A" className="pl2 font-regular fs-14">
              <li>
                The Borrower hereby represents and warrants to the Lender on a
                continuing basis that:
                <ol type="i" className="pl2 font-regular fs-14">
                  <li>
                    <span class="underline">
                      Confirmation of Facility Application
                    </span>
                    <p>
                      The Borrower acknowledges and confirms that all the
                      factual information provided by the Borrower to the Lender
                      in the Loan Application or otherwise in order to avail the
                      Facility and any prior or subsequent information or
                      explanation given to the Lender in this regard is true and
                      accurate in all material respects as at the date it was
                      provided and does not omit to state a material fact
                      necessary in order to make the statements contained
                      therein misleading in the light of the circumstances under
                      which such statements were or are made.
                    </p>
                  </li>
                  <li>
                    <p className="underline"> Authority and Capacity </p>
                    <ol className="pl5" type="1">
                      <li>
                        The Borrower has the corporate power, authority and all
                        material permits, approvals, authorizations, licenses,
                        registrations, and consents including registrations, to
                        own and operate its assets and to carry on its business
                        in substantially the same manner as it is currently
                        conducted.
                      </li>
                      <li>
                        The Borrower have the legal right, power and authority
                        to enter into, deliver and perform the obligation under
                        this Agreement and all other documents and instruments
                        required to be executed pursuant thereto or in
                        connection therewith, and such documents, when executed,
                        will constitute valid and binding obligations and be
                        enforceable against the Borrower in accordance with
                        their respective terms; and
                      </li>
                      <li>
                        The Borrower represent that all consents, and actions
                        of, filings with and notices to any Governmental
                        Authority as may be required to be obtained by them in
                        connection with the execution, delivery and performance
                        by the Borrower under this Agreement.
                      </li>
                      <li>
                        It has obtained all required Authorisations to enable it
                        to enter into, exercise its rights and comply with its
                        obligations in the Agreement and to make the Agreement
                        admissible in evidence in courts of India and all such
                        Authorisations are in full force and effect;
                      </li>
                      <li>
                        The Borrower has not, by reason of actual or anticipated
                        financial difficulties, commenced, or intends to
                        commence, negotiations with one or more of its creditors
                        with a view to rescheduling any of its indebtedness;
                      </li>
                      <li>
                        The value of the assets of the Borrower is more than its
                        liabilities (taking into account contingent liabilities)
                        and it has sufficient capital to carry on its business;
                      </li>
                      <li>
                        The Borrower has informed the Lender about all
                        loans/finances/advances availed by the Borrower from
                        other banks/financial institutions/third parties up to
                        the date of this Agreement to the Lender.
                      </li>
                      <li>
                        The Borrower acknowledges that it makes the
                        representations and warranties set out in Clause 14 with
                        the intention of inducing the Lender to grant the
                        Facility to the Borrower.
                      </li>
                      <li>
                        The representations and warranties set out above shall
                        be deemed to be repeated on each day till all amounts
                        under the Agreement have been repaid in full by the
                        Borrower to the satisfaction of the Lender.
                      </li>
                    </ol>
                  </li>
                  <li>
                    <p className="underline">
                      {" "}
                      Electronic Confirmation of Agreement{" "}
                    </p>
                    <ol type="a" className="pl5">
                      <li>
                        For ease of operation of the Borrower, Borrower shall
                        apply for Facility provided by the Lender using online
                        secure platforms as may be specified by the Lender
                        (hereinafter referred to as “Online Facility”).
                      </li>
                      <li>
                        The Borrower has consented to execute the Agreement,
                        Facility Tranche Request and any other related documents
                        through OTP/ Access Code.
                      </li>
                      <li>
                        It shall be the sole responsibility of the Borrower to
                        ensure that the OTP/ Access Code is not compromised or
                        shared with any unauthorized users.
                      </li>
                      <li>
                        The Borrower expressly agrees and acknowledges to have
                        read and understood the terms applicable for usage of
                        the Online Facility and be bound by such terms.
                      </li>
                      <li>
                        The Lender shall have no obligation to verify the
                        authenticity of any transaction / instruction received
                        or purported to have been received from the Borrower
                        through the Online Facility or purporting to have been
                        sent by the Borrower other than by means of verification
                        of the OTP/ Access Code.
                      </li>
                      <li>
                        All the records of the Lender with respect to the online
                        request for facility arising out of the use of the
                        Online Facility and arising out of the use of the OTP/
                        Access Code shall be conclusive proof of the genuineness
                        and accuracy of the transaction and shall be binding on
                        the Borrower.
                      </li>
                      <li>
                        The Borrower acknowledges and accepts that the Lender
                        may permit/allow anybody quoting the correct OTP/ Access
                        Code and other details to conduct the type of operations
                        which are permitted under this Agreement.
                      </li>
                    </ol>
                  </li>
                </ol>
              </li>

              <li>
                <p className="underline"> Compliance with Laws </p>
                <p>
                  {" "}
                  The Borrower has complied with all the applicable Laws and is
                  not a party to any litigation, arbitration or administrative
                  or regulatory proceedings or investigations of a material
                  character and that the Borrower is not aware, to the best of
                  its knowledge and belief, of any facts likely to give rise to
                  such litigation, arbitration or administrative or regulatory
                  proceedings or investigations or to material claims against
                  the Borrower.
                </p>
                <ol type="1" className="pl5">
                  <li>
                    <p className="underline"> Litigation </p>
                    <p>
                      {" "}
                      Where applicable, the Borrower shall supply to the Lender,
                      promptly upon becoming aware of them, details of any
                      filing by any creditor (financial creditor or operational
                      creditor) which are made or threatened against them, in
                      accordance with the provisions of the Insolvency and
                      Bankruptcy Code, 2016 or any analogous laws.
                    </p>
                  </li>
                  <li>
                    <p className="underline">
                      {" "}
                      Compliance of Know Your Customer (KYC) Policy:
                    </p>
                    <p>
                      {" "}
                      The Borrower is fully aware of the KYC Policy of the
                      Lender and RBI and confirms that the
                      information/clarification/documents/signage provided by it
                      on its identity, address, authorised signatory, PAN and
                      all other material facts are true and correct and the
                      transaction, etc. are bonfire and as per Law. The Borrower
                      further confirms that it has disclosed all
                      facts/information as are required to be disclosed for the
                      adherence and compliance of the provisions related to the
                      KYC Policy. The Lender shall have received the
                      documentation and other information that is required by
                      RBI under applicable KYC guidelines. The Lender reserve
                      the right to recall the Facility or close the account in
                      case the required documents are not provided by the
                      Borrower to the Lender.
                    </p>
                  </li>
                  <li>
                    <p>
                      {" "}
                      The Lender shall, without notice to or without any consent
                      of the Borrower, be absolutely entitled and have full
                      right, power and authority to make disclosure of any
                      information relating to Borrower including personal
                      information, details in relation to documents, Loan,
                      defaults, security, obligations of Borrower, to the Credit
                      Information Bureau of India (CIBIL) and/or any other
                      governmental/regulatory/statutory or private
                      agency/entity, credit bureau, RBI, the Lender’s other
                      branches/ subsidiaries / affiliates / rating agencies,
                      service providers, other Lenders / financial institutions,
                      any third parties, any assignees/potential assignees or
                      transferees, who may need the information and may process
                      the information, publish in such manner and through such
                      medium as may be deemed necessary by the publisher/
                      Lender/ RBI, including publishing the name as part of
                      wilful defaulter’s list from time to time, as also use for
                      KYC information verification, credit risk analysis, or for
                      other related purposes. The Borrower waives the privilege
                      of privacy and privity of contract.
                    </p>
                  </li>
                  <li>
                    <p>
                      The execution and delivery of this Agreement and documents
                      to be executed in pursuance hereof, and the performance of
                      the Borrower's obligations hereunder and thereunder does
                      not and will not (I) contravene any applicable Law,
                      statute or regulation or any judgment or decree to which
                      any of the Borrowers and/or their Assets and/or business
                      and/or their undertaking is subject, or (ii) conflict with
                      or result in any breach of, any of the terms of or
                      constitute default of any covenants, conditions and
                      stipulations under any existing agreement or contract or
                      binding to which any of the Borrowers are a party or
                      subject or (iii) conflict or contravene any provision of
                      the memorandum and the articles of association and/or any
                      constituting/governing documents of Borrowers.
                    </p>
                  </li>
                </ol>
              </li>
              <li>
                <p className="underline">No default</p>
                <p>
                  The Borrower and/or its group companies, affiliates have no
                  over dues/not defaulted in repayment of any amount due and
                  payable to any other bank/financial institutions.
                </p>
              </li>
              <li>
                <p className="underline">Material Adverse Effect</p>
                <p>
                  There are no facts or circumstances, conditions or
                  occurrences, which could collectively or otherwise be expected
                  to result in the Borrower being unable to perform their
                  respective obligations under the Financing Documents to which
                  they are expressed to be a party, or which could affect the
                  legality, validity, binding nature or enforceability of this
                  Agreement or other Financing Documents or is otherwise
                  expected to have a Material Adverse Effect.
                </p>
              </li>
            </ol>
          </li>
          <li>
            <p className="font-extra-heavy">
              {" "}
              EVENT OF DEFAULT AND CONSEQUENCES{" "}
            </p>
            <p></p>
            <p>
              The Borrower expressly and irrevocably hereby agrees and declares
              that each of the following events or events similar thereto shall
              constitute an “Events of Default” and or Potential Event of
              Default: The following events shall constitute events of default
              (each an “Event of Default”), and upon the occurrence of any of
              them the entire Outstanding Balance shall become immediately due
              and payable by the Borrower and further enable the Lender inter
              alia to recall the entire Outstanding Balance and/or enforce any
              security and transfer/sell the same and/or take, initiate and
              pursue any actions/proceedings as deemed necessary by the Lender
              for recovery of the dues, or such other action as the Lender may
              deem fit:
            </p>
            <ol className="pl5" type="a">
              <li>
                {" "}
                If any default shall have occurred in payment of Principal,
                Penal Interest/Charges or any part thereof and/or in payment of
                any other amounts due and payable to the Lender in terms of this
                Agreement and/or in terms of any other agreement(s) /
                document(s) which may be subsisting or which may be executed
                between the Borrower and the Lender hereafter; and/or
              </li>
              <li>
                If default shall have occurred in the performance of any other
                covenants, conditions or agreements on the part of the Borrower
                under this Agreement or any other agreement(s) between the
                Borrower and the Lender in respect of the Facility or any other
                loan; and/or
              </li>
              <li>
                Any misrepresentations or misstatement by the Borrower; and/or
              </li>
              <li>
                Routing of the Undisputed Invoices in a manner inconsistent with
                the terms of this Agreement. and/or
              </li>
              <li>
                If any information given by the Borrower to the Lender in the
                Facility Application or otherwise is found to be misleading or
                incorrect in any material respect or any representation or
                warranty referred to in Clause 14 is found to be incorrect;
                and/or
              </li>
              <li>
                Failure on the Borrower’s part to perform any of the obligations
                or terms or conditions or covenants applicable in relation to
                the Facility including under this document/other documents
                including non-payment in full of any part of the Outstanding
                Balance when due or when demanded by Lender/BharatPe; and/or
              </li>
              <li>
                Occurrence of any circumstance or event which adversely affects
                Borrower’s ability/capacity to pay/repay the Outstanding Balance
                or any part thereof or perform any of the obligations; and/o
              </li>
              <li>
                The event of death, insolvency, cessation, failure in business
                of the Borrower, or change or termination of
                employment/profession/business for any reason whatsoever; and/or
              </li>
              <li>
                The Borrower entering into any arrangement or composition with
                its creditor or committing any act the consequences of which may
                lead the Borrower becoming insolvent/bankrupt. and/or
              </li>
              <li>
                If the Borrower fails to furnish any information or documents
                required by the Lender/BharatPe; and/or
              </li>
              <li>
                If the Borrower fails to inform the Lender of the occurrence of
                any Event of Default or any event which after the notice or
                lapse of time, or both, would become an Event of Default; and/or
              </li>
              <li>
                Where any payment made by the Borrower to the Lender falls short
                of payment required to be made by the Borrower with respect to
                the amount due from the Borrower to the Lender; and/or
              </li>
              <li>
                The Borrower commits an act of insolvency or makes an
                application for declaring himself an insolvent or an order is
                passed against the Borrower declaring him an insolvent. and/or
              </li>
              <li>
                If any permission, authorization, issued by the competent
                authority(ies) with respect to legality of the Secured Property
                or any improvement or construction is withdrawn or cancelled or
                withheld for any reason whatsoever. and/or
              </li>
              <li>
                If the Borrower makes any default under any credit facility
                agreement or arrangement entered into by the Borrower with the
                Lender, its subsidiaries, affiliates or any bank, financial
                institution / non-banking financial company or its agent and/or
                other creditors. and/or
              </li>
              <li>
                The occurrence of any other event or circumstance, which would
                or is likely to prejudicially affect in any manner the capacity
                of the Borrower to repay the loan, the opinion of the Lender in
                this regard being conclusive. and/or
              </li>
              <li>
                If the Lender apprehends or has reason to believe that the
                Borrower is utilizing the amount borrowed or any part thereof
                for any purpose other than that for which the Facility has been
                sanctioned. and/or
              </li>
              <li>
                At any time, it is or becomes unlawful for the Borrower or the
                Agent to perform or comply with any or all of their respective
                business or any of the Security ceases to be effective. and/or
              </li>
              <li>
                The Borrower commits any breach of the terms and conditions of
                this Agreement.
              </li>
            </ol>
            <p>
              On and any time after the occurrence of Event of Default, the
              Lender may, without prejudice to any other rights that it may have
              under this Agreement or applicable Law (including right to
              accelerate payment obligations of the Borrower under the Financing
              Documents) take one or more of the following actions:{" "}
              <span className="font-extra-heavy">(a) </span> recall or declare
              the Outstanding Amounts to be forthwith due and payable, whereupon
              such amounts shall become forthwith due and payable without
              presentment, demand, protest or any other notice of any kind, all
              of which are hereby expressly waived, anything, contained herein
              to the contrary notwithstanding;{" "}
              <span className="font-extra-heavy">(b)</span> exercise any and all
              rights specified in the Financing Documents including, without
              limitation, to enforce any security created/provided;
              <span className="font-extra-heavy">(c)</span>
              to initiate, appropriate proceedings for recovery of its dues by
              invoking the jurisdiction of appropriate court at its sole
              discretion, in addition to taking further action or actions under
              any other statute in force; and/or (d) exercise such other
              remedies as permitted or available under applicable law in the
              sole discretion of the Lender; and/or (e) disclose the name of the
              Borrower, and its promoters/directors/partners to RBI, TransUnion
              CIBIL and/or any other authorised agency. The Borrower agrees and
              acknowledges that the Lender may institute any actions against the
              borrower through BharatPe and/or any other authorized agency.
            </p>
          </li>
          <li>
            <p className="font-extra-heavy full-width fs-14 title">
              SUCCESSION
            </p>
            <ol type="A" className="pl2 font-regular fs-14">
              <li>
                In case of the death of the Borrower, where the Borrower is an
                individual and the Lender agrees to continue extending the
                Facility, the legal representative of the Borrower, with such
                other requirements as the Lender may deem fit.
              </li>
            </ol>
          </li>
          <li>
            <p className="font-extra-heavy full-width fs-14 title">
              MISCELLANEOUS
            </p>
            <ol type="A" className="pl2 font-regular fs-14">
              <li>
                <span className="underline">
                  Governing Law and Jurisdiction
                </span>
                <ol type="i" className="pl2 font-regular fs-14">
                  <li>
                    This Agreement and the rights and obligations of the Parties
                    hereunder shall be construed in accordance with and be
                    governed by the laws of India.
                  </li>
                  <li>
                    The Parties agree that the courts in New Delhi shall have
                    exclusive jurisdiction to settle any disputes which may
                    arise out of or in connection with the Financing Documents.
                  </li>
                  <li>
                    The Borrower irrevocably waive any objection, now or in
                    future, to the venue of any Proceedings being the courts at
                    New Delhi or any claim that any such Proceedings have been
                    brought in an inconvenient forum.
                  </li>
                  <li>
                    Nothing contained herein shall limit any right of the Lender
                    to take Proceedings in any other court of competent
                    jurisdiction, nor shall the taking of proceedings in one or
                    more jurisdictions preclude the taking of proceedings in any
                    other jurisdiction whether concurrently or not and the
                    Borrower irrevocably waive any objection it may have now or
                    in the future to the laying of the venue of any Proceedings
                    on the grounds that such Proceedings have been brought in an
                    inconvenient forum.
                  </li>
                </ol>
              </li>
              <li>
                <span className="underline">Arbitration</span>
                <ol type="i" className="pl2 font-regular fs-14">
                  <li>
                    Without prejudice to the other legal remedies available to
                    the Lender under applicable law (including under the
                    SARFAESI Act, 2002 and Insolvency and Bankruptcy Code,
                    2016), any dispute arising out of or in connection with the
                    Financing Documents shall be referred to and finally
                    resolved by arbitration under the Arbitration and
                    Conciliation Act, 1996 (as amended from time to time).
                  </li>
                  <li>
                    The arbitration shall be referred to a sole arbitrator
                    appointed by the Lender. The seat and venue of the
                    arbitration shall be New Delhi. The language of the
                    arbitration and the award of the arbitrator shall be in the
                    English language. The award of the arbitrator shall be final
                    and binding on the Parties and the expenses of the
                    arbitration shall be borne in such manner as the arbitrator
                    may determine.
                  </li>
                </ol>
              </li>
              <li>
                <span className="underline">Indemnity</span>
                <p>
                  Without prejudice to and in addition to other provisions
                  contained in the Financing Documents, the Borrower hereby
                  agrees to indemnify the Lender/BharatPe and its directors,
                  officers, representatives and agents against any losses,
                  liabilities, claims, damages or the like (including, without
                  limitation, reasonable attorneys' fees and expenses) which may
                  be sustained or incurred by any of them as a result of, or in
                  connection with, or arising out of:
                </p>
                <ol type="i" className="pl2 font-regular fs-14">
                  <li>
                    the Borrower failing to comply with the provisions of any
                    Financing Documents and applicable Laws; and / or
                  </li>
                  <li>the occurrence of any Event of Default; and / or</li>
                  <li>
                    levy by any Government Authority of any charge, Taxes or
                    penalty in connection with regularising or perfecting any of
                    the Financing Documents as may be required under applicable
                    Law at any time during the currency of the Facility, or
                    getting any of the Financing Documents admitted into
                    evidence, or relying on any Financing Documents for proving
                    any claim; and/or
                  </li>
                  <li>
                    the exercise of any of the rights by the Lender under this
                    Agreement and any of the Financing Documents; and/or
                  </li>
                  <li>
                    any of the representations and warranties of the Borrower
                    under the Financing Documents are found to be false or
                    untrue or incorrect on a future date.
                  </li>
                </ol>
              </li>
              <li>
                <span className="underline">Confidentiality</span>
                <p>
                  Any information supplied by a Party to another Party pursuant
                  hereto which is by its nature can reasonably be construed to
                  be proprietary or confidential or is marked “confidential” (
                  <span className="font-extra-heavy">{` “Confidential Information” `}</span>
                  ) shall be kept confidential by the recipient unless or until
                  compelled to disclose the same (I) by judicial or
                  administrative process, or (ii) by law, or unless the same
                  (iii) is in or is a part of public domain, or (iv) is required
                  to be furnished to the bankers or investors or potential
                  investors in the either Party, or (v) is required to be
                  furnished to any Government Authority having jurisdiction over
                  the recipient, or (vi) can be shown by the receiving Party to
                  the reasonable satisfaction of the disclosing Party to have
                  been known to the receiving Party prior to it being disclosed
                  by the disclosing Party to the receiving Party, or (vii)
                  subsequently comes lawfully into the possession of the
                  receiving Party from a third party, and in such cases the
                  confidentiality obligations shall cease to the extent required
                  under the foregoing circumstances.
                </p>
              </li>
              <li>
                <span className="underline">Amendments and Waivers</span>
                <p>
                  This Agreement (including the schedules, annexure and
                  appendices hereto) may not be amended, supplemented or
                  modified and no other Financing Document may be amended,
                  supplemented or modified and no term or condition thereof may
                  be waived without the written consent of the Parties to such
                  Financing Document.
                </p>
              </li>
              <li>
                <span className="underline">Severability</span>
                <p>
                  Any provision of this Agreement or any other Financing
                  Document which is prohibited or unenforceable shall be
                  ineffective to the extent of prohibition or unenforceability
                  but shall not invalidate the remaining provisions of this
                  Agreement or any Financing Document.
                </p>
              </li>
              <li>
                <span className="underline">Survival</span>
                <ol type="i" className="pl2 font-regular fs-14">
                  <li>
                    This Agreement shall be in force until all the Outstanding
                    Amounts under this Agreement have been fully and irrevocably
                    paid in accordance with the terms and provisions hereof.
                  </li>
                  <li>
                    The obligations of the Borrower under the Financing
                    Documents will not be affected by:
                    <ol type="i" className="pl2 font-regular fs-14">
                      <li>
                        any unenforceability, illegality or invalidity of any
                        obligation of any Person under a Financing Document; or
                      </li>
                      <li>
                        the breach, frustration or non-fulfilment of any
                        provisions of, or claim arising out of or in connection
                        with a Financing Document.
                      </li>
                    </ol>
                  </li>
                </ol>
              </li>
              <li>
                <span className="underline">Right of Set-off</span>
                <p>
                  In addition to any rights now or hereafter granted under
                  Applicable Law or otherwise, and not by way of limitation of
                  any such rights, upon the occurrence and continuation of an
                  Event of Default, the Lender is hereby authorised by the
                  Borrower to, from time to time, without presentment, demand,
                  protest or other notice of any kind to the Borrower, or to any
                  other Person, set off and/or appropriate and/or apply any and
                  all deposits (general or special) at any time held or owing by
                  the Lender (including, without limitation, by any branches and
                  agencies other than the lending office of Lender) to or for
                  the credit or the account of the Borrower against and on
                  account of the obligations and liabilities of the Borrower to
                  the Lender under this Agreement or under any of the other
                  Financing Documents.
                </p>
              </li>

              <li>
                <span className="underline"> Notices </span>
                <p>
                  All notices and other communications provided at various
                  places in this Agreement shall be in writing and (a) sent by
                  hand delivery, or (b) prepaid registered post with
                  acknowledgment due, or (c) by e-mail followed by prepaid
                  registered post with acknowledgment due, at the address and/or
                  email first above written. All such notices and communications
                  shall be deemed to have been delivered effective: (I) if sent
                  by email, when sent (provided the email enters the sent folder
                  of the sender), (ii) if sent by prepaid registered post, 3
                  (three) Business Days after its dispatch.
                </p>
              </li>
              <li>
                <span className="underline">Effectiveness </span>
                <p>
                  This Agreement shall become binding on the Parties hereto on
                  and from the date hereof and shall be in force and effect till
                  the Final Settlement Date.
                </p>
              </li>
              <li>
                <span className="underline">Entire Agreement</span>
                <p>
                  This Agreement and other Financing Documents shall represent
                  the entire understanding of the Parties on the subject matter
                  hereof and shall override all the previous understanding and
                  agreement between the Parties hereto.
                </p>
              </li>
            </ol>
          </li>
          <li>
            <span className="font-extra-heavy">
              {" "}
              CONFIRMATION OF THE AGREEMENT{" "}
            </span>
            <p>
              {" "}
              The Borrower has read the Agreement and Facility Tranche Request
              and has understood the details mentioned in the same.
            </p>
          </li>
        </ol>
        <p className="font-extra-heavy full-width text-center fs-14 my2">
          SCHEDULE I
        </p>
        <p className="font-extra-heavy fs-14">
          This is with reference to your Facility Application for a Facility
          from {lender_info?.full_name}. In this regard, please find below
          the Most Important Terms and Conditions (MITC) for the Facility:
        </p>
        <p className="font-extra-heavy fs-14 sub-title flex flex-justify-center">
          MOST IMPORTANT TERMS AND CONDITIONS
        </p>
        <table className="my2 fs-14 font-regular">
          <tbody>
            <tr>
              <th>S.NO.</th>
              <th>PARTICULARS</th>
              <th>DETAILS</th>
            </tr>
            <tr>
              <td>1.</td>
              <td>Facilty ID</td>
              <td>{disbursal_info?.facility_id || ""}</td>
            </tr>
            <tr>
              <td>2.</td>
              <td>Lender</td>
              <td>{lender_info?.full_name || ""}</td>
            </tr>
            <tr>
              <td>3.</td>
              <td>Date of Agreement</td>
              <td>{disbursal_info?.agreement_date || ""}</td>
            </tr>
            <tr>
              <td>4.</td>
              <td>Place of Agreement</td>
              <td>{address?.city || ""}</td>
            </tr>
            <tr>
              <td>5.</td>
              <td>Agreement No.</td>
              <td>{disbursal_info?.facility_id || ""}</td>
            </tr>
            <tr>
              <td>6.</td>
              <td>Name of Borrower</td>
              <td>{profile?.name || ""}</td>
            </tr>
            <tr>
              <td>7.</td>
              <td>Registered Mobile Number</td>
              <td>{profile?.registered_mobile || ""}</td>
            </tr>
            <tr>
              <td>8.</td>
              <td>Alternate Mobile Number</td>
              <td>{profile?.alternate_mobile || ""}</td>
            </tr>
            <tr>
              <td>9.</td>
              <td>
                Borrower's constitution (Please tick mark whichever is
                applicable)
              </td>
              <td>{disbursal_info?.borrowers_constitution}</td>
            </tr>
            <tr>
              <td>10.</td>
              <td>Business of the Borrower</td>
              <td>{disbursal_info?.business_of_borrower}</td>
            </tr>
            <tr>
              <td>11.</td>
              <td>Address of Borrower</td>
              <td>{address?.address}</td>
            </tr>
            <tr>
              <td>12.</td>
              <td>City, State and Pincode</td>
              <td>
                {address?.city}, {address?.state}, {address?.pin_code}
              </td>
            </tr>
            <tr>
              <td>13.</td>
              <td>Email Address</td>
              <td>{profile?.email}</td>
            </tr>
            <tr>
              <td>14.</td>
              <td>End use</td>
              <td>Business</td>
            </tr>
            <tr>
              <td>15.</td>
              <td>Facility Amount</td>
              <td>{disbursal_info?.facility_amount || 0}</td>
            </tr>
            <tr>
              <td>16.</td>
              <td>Availability Period</td>
              <td>
                The period of 12 months commencing from the date of execution of
                this Agreement or by such extended time as may be allowed by the
                Lender.
              </td>
            </tr>
            <tr>
              <td>17.</td>
              <td>Tenure</td>
              <td>{disbursal_info?.tenure}</td>
            </tr>

            <tr>
              <td>18.</td>
              <td className="font-extra-heavy">Penal Interest Rate</td>
              <td className="font-extra-heavy">{`${disbursal_info?.penal_interest_rate}% compound interest per day on the Outstanding Amounts after the Grace Period`}</td>
            </tr>
            <tr>
              <td>19.</td>
              <td className="font-extra-heavy">
                Grace Period(in days) (If applicable)
              </td>
              <td className="font-extra-heavy">{disbursal_info?.grace_period || 0}</td>
            </tr>
            <tr>
              <td>20.</td>
              <td className="font-extra-heavy">
                Interest/ Interest Rate %age (Annualised)
              </td>
              <td className="font-extra-heavy">
                {disbursal_info?.interest_rate} %
              </td>
            </tr>
            <tr>
              <td>21.</td>
              <td>Prepayment Charges</td>
              <td className="underline">
                Upto 1% of the Principal amount applicable if borrower prepays
                before the Tenure
              </td>
            </tr>
            <tr>
              <td>22.</td>
              <td>Borrower Bank Account</td>
              <td className="pl5">
                <ol>
                  <li>
                    Bank Account Number [
                    {bank_details?.account_number}]
                  </li>
                  <li>Beneficiary Name [{bank_details?.beneficiary_name}]</li>
                  <li>Bank name [{bank_details?.bank_name}]</li>
                  <li>IFSC code [{bank_details?.ifsc_code}]</li>
                </ol>
              </td>
            </tr>
            <tr>
              <td>23.</td>
              <td>Documents to be provided by the Borrower</td>
              <td>
                <ol type="i">
                  <li>
                    Documents required for establishing the identity of the
                    Borrower (individual): Identity proof can be such Exchange
                    Partner's passport, Permanent Account Number (PAN account),
                    Driving License, Aadhar Card, Voter Card etc.
                  </li>
                  <li>
                    Documents required for establishing the identity of the
                    Borrower (company/ sole proprietorship): Certificate of
                    Incorporation, Sole proprietor’s registration
                    document/declaration, GST Registration, Shops and
                    Establishment certificate, Copy of PAN Card of Entity,
                    Proprietors.
                  </li>
                  <li>
                    ocuments required as address proof of the Borrower
                    (individual): Aadhar Card, Telephone/ Mobile/ Electricity/
                    Gas/ Water bills of public operators, Bank Statement with
                    Photo & Address etc.
                  </li>
                  <li>
                    Documents required as address proof of the Borrower
                    (company/ sole proprietorship): GST registration, Registered
                    Lease Deed, Trade license, Telephone/ Mobile/ Electricity/
                    Gas/ Water bills of public operators;
                  </li>
                  <li>Latest bank statement of the Borrower;</li>
                  <li>Any other documents as specified by the Lender.</li>
                </ol>
                Please note that the requirements of the above documents may
                differ on the basis the Borrower Constitution
              </td>
            </tr>
          </tbody>
        </table>
        <p className="font-extra-heavy fs-14 sub-title">TABLE OF CHARGES</p>
        <table className="my2 fs-14 font-regular">
          <tbody>
            <tr>
              <th>Type of Charges</th>
              <th></th>
              <th>Type of Charges</th>
              <th></th>
              <th>Type of Charges</th>
              <th></th>
            </tr>
            <tr>
              <td>Late Payment Penal Interest %age</td>
              <td>{disbursal_info?.penal_interest_rate}</td>
              <td>Prepayment Charges</td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td>Stamping Charges</td>
              <td>{disbursal_info?.processing_fee}</td>
              <td>Processing Fee</td>
              <td>0</td>
              <td>Other Charges</td>
              <td></td>
            </tr>
          </tbody>
        </table>

        <p className="font-extra-heavy fs-14 sub-title">
          Documents to be submitted: KYC Documents as per Lender’s KYC Policy,
          MITC and Facility Agreement
        </p>
        <p className="font-extra-heavy fs-14 sub-title">
          Declaration / Undertaking/Representation by Borrower
        </p>
        <ol className="pl2 font-regular fs-14">
          <li>
            I/We hereby apply for a Facility as per the terms of the Facility
            Agreement as given below and declare that all the particulars,
            information and details provided and other documents submitted by
            me/us are true, correct, complete and up-to-date in all respects and
            that I/We have not withheld any material information.
          </li>
          <li>
            I/We hereby authorize Lender (and authorized agents of the Lender
            such as Resilient Innovation Private Limited (“BharatPe”)) to
            exchange or share information and details relating to my application
            to its group companies, Credit Bureaus/Rating Agencies, Services
            Providers, banks/financial institutions, governmental/ regulatory
            authorities, or any other third parties for KYC information
            verification, credit risk analysis, or for other related purposes,
            as may be required or deemed fit, for the purpose of processing this
            Facility application and/or related offerings or other products /
            services that I/We may apply for from time to time.
          </li>
          <li>
            By submitting this application, I/We hereby expressly authorize
            Lender (and authorized agents of the Lender such as BharatPe) to
            send me communications regarding loans, insurance and other products
            from Lender/BharatPe, its group companies and / or third parties
            through telephone calls / SMSs / emails / post etc. including but
            not limited to promotional, transactional communications. I/We
            confirm that I/ We shall not challenge receipt of such
            communications by me as unsolicited communication, defined under
            TRAI Regulations on Unsolicited Commercial Communications.
          </li>
          <li>
            I/We authorize Lender to evaluate my transaction history on the
            BharatPe platform in order to check my eligibility for the Facility
            and understand and acknowledge that Lender has the absolute
            discretion, without assigning any reasons to reject my application
            and that Lender is not answerable / liable to me, in any manner
            whatsoever, for rejecting my application.
          </li>
          <li>
            I / We agrees and accept that Lender (and authorized agents of the
            Lender such as BharatPe) may in its sole discretion, by its self or
            through authorised persons, advocate, agencies, bureau, etc. verify
            any information given, check credit references, employment details
            and obtain credit reports to determine creditworthiness from time to
            time.
          </li>
          <li>
            I/We hereby confirm that I/We and the Ultimate Beneficial Owner (in
            applicable cases) am/ is not a US person or a resident for Tax
            purpose in any country other than India. Further, I/We hereby
            understand that the onus on forthwith informing/ notifying any
            change in this regard is on me/ us. Lender will not be held liable /
            responsible for the same.{" "}
          </li>
          <li>
            I/ We have no objection in sharing my/our Aadhaar details and in
            authenticating me/us with Aadhaar based authentication system and
            hereby give my voluntary consent as required under the Aadhaar Act,
            2016 and regulations cum amendments framed thereunder to provide my
            identity information (Aadhaar number, biometric information &
            demographic information) for Aadhaar based authentication for the
            purpose of availing loan from Lender.
          </li>
          <li>
            I hereby consent to receiving information from Central KYC Registry
            through SMS/Email on the above registered number /email address.
          </li>
          <li>
            I/ We confirm that the funds shall be used for the stated purpose
            and will not be used for any speculative or antisocial purpose.
          </li>
          <li>
            I/ We understand that the sanction of the loan is at the sole
            discretion of the Lender and upon me/ us executing necessary
            documents.
          </li>
          <li>
            I/We understand that the Tenure /repayment / Interest/other terms
            and conditions of the loan are subject to changes as a consequence
            to any changes in the money market conditions or on account of any
            other statutory or regulatory requirements or at Lender’s
            discretion. Lender reserves the right to review and amend the terms
            of the loan to such extent as it may deem fit. The change in
            Interest Rate and charges will be on prospective basis.
          </li>
          <li>
            {`I/ We understand that the purchase of any insurance products is
            purely voluntary, and is not linked to availing of any other
            facility from ${lender_info?.full_name}.`}
          </li>
          <li>
            I/ We hereby confirm that the above contents and the terms and
            conditions that shall be applicable to the Facility have been
            explained to me in the language understood by me
          </li>
          <li>
            I/We confirm that I/we have read and understood the above
            Declaration, and that the details provided by me/ us are correct.
          </li>
        </ol>
        <div
          style={{
            color: "black",
            fontSize: "0.875rem",
            fontFamily: "Regular",
            width: "100%",
            padding: "1rem"
          }}
        >
          <div
            style={{
              color: "black",
              fontFamily: "ExtraHeavy",
              display: "flex",
              flexDirection: "column",
              alignItems: "start",
              width: "100%"
            }}
          >
            <div
              style={{
                color: "black",
                fontFamily: "ExtraHeavy",
                display: "flex",
                justifyContent: "center",
                width: "100%",
                marginTop: "2px",
                marginBottom: "2px"
              }}
            >
              <p style={{ margin: "1px" }}> SCHEDULE II </p>
            </div>
            <div
              style={{
                color: "black",
                fontFamily: "ExtraHeavy",
                display: "flex",
                justifyContent: "center",
                width: "100%",
                marginTop: "2px",
                marginBottom: "2px"
              }}
            >
              <p style={{ margin: "1px" }}> SAMPLE </p>
            </div>
            <div
              style={{
                color: "black",
                fontFamily: "ExtraHeavy",
                display: "flex",
                justifyContent: "center",
                width: "100%",
                marginTop: "2px",
                marginBottom: "2px"
              }}
            >
              <p class="m1"> Facility Tranche Request Form </p>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "start",
              width: "100%"
            }}
          >
            <p style={{ margin: "1px" }}> To, </p>
            <p style={{ margin: "1px" }}>
              {" "}
              {lender_info?.full_name}{" "}
            </p>
          </div>
          <div
            style={{ display: "flex", justifyContent: "center", width: "100%" }}
          >
            <p style={{ margin: "1px" }}> Sub:Facility Tranche Request </p>
          </div>
          <div style={{ marginTop: "1px", marginBottom: "1px" }}>
            <span>
              {" "}
              The capitalized terms used hereunder shall have the meaning
              ascribed to it under the Facility Application and{" "}
            </span>
            <span>
              Facility Agreement dated {disbursal_info?.agreement_date || ""} for grant of a Facility ID{" "}
              {disbursal_info?.facility_id || ""}.{" "}
            </span>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              width: "100%",
              fontFamily: "ExtraHeavy",
              margin: "5px"
            }}
          >
            <p style={{ margin: "1px" }}>
              {" "}
              Most Important Terms and Conditions (MITC){" "}
            </p>
          </div>
          <ol type="1" style={{ padding: "2px" }}>
            <li style={{ margin: "2px" }}>
              The Borrower hereby unconditionally and irrevocably authorises the
              Lender to grant the Facility of INR __________________________ to
              the Borrower’s bank account as mentioned in Facility Agreement
              Schedule I, towards the Purpose/ End use and undertake to comply
              with all the terms and conditions set forth herein.
            </li>
            <li style={{ margin: "2px" }}>
              This Facility Tranche Request is irrevocable. The Borrower shall
              not refuse to accept disbursement of the Facility, except with
              approval of the Lender.
            </li>
            <li style={{ margin: "2px" }}>
              The Borrower hereby confirms that no Event of Default or potential
              event of default has occurred or is continuing.
            </li>
            <li style={{ margin: "2px" }}>
              The Borrower acknowledges and confirms that the below mentioned
              are the most important terms and conditions in the application for
              the Facility Tranche pertaining to single or multiple invoices
              (and which would apply in respect of the Facility, if the request
              for the Facility Tranche is accepted by the Lender) and they shall
              be read in conjunction with the Facility Application, Facility
              Agreement and Facility Tranche Request(s) Form:
              <table>
                <tbody>
                  <tr>
                    <td>Facility ID</td>
                    <td>{disbursal_info?.facility_id || ""}</td>
                  </tr>
                  <tr>
                    <td>Borrower</td>
                    <td> {profile?.name || ""} </td>
                  </tr>
                  <tr>
                    <td>Lender</td>
                    <td>{lender_info?.full_name}</td>
                  </tr>
                  <tr>
                    <td>Available Limit</td>
                    <td>{disbursal_info?.facility_amount || 0}</td>
                  </tr>
                  <tr>
                    <td>Tranche amount</td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>Tranche ID</td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>End Use</td>
                    <td>Business</td>
                  </tr>
                  <tr>
                    <td> Tenure </td>
                    <td>{disbursal_info?.tenure}</td>
                  </tr>
                  <tr>
                    <td> Interest/ Interest Rate (%)  </td>
                    <td>
                      {disbursal_info?.interest_rate} %
                    </td>
                  </tr>
                  <tr>
                    <td> Penal Interest (%) </td>
                    <td>
                      {" "}
                      {`${disbursal_info?.penal_interest_rate}% compound interest per day on the Outstanding Amounts after the Grace Period`}
                    </td>
                  </tr>
                  <tr>
                    <td> Chargeable Interest </td>
                    <td> 0 Deducted upfront </td>
                  </tr>
                  <tr>
                    <td>Cost & Other Charges</td>
                    <td> 0 Deducted upfront </td>
                  </tr>
                  <tr>
                    <td>Repayment & Due Date</td>
                    <td> Bullet Repayment at the end of Tenure </td>
                  </tr>
                </tbody>
              </table>
            </li>
            <li style={{ margin: "2px" }}>
              The Borrower authorises the Lender to deduct an amount equivalent
              to the total Interest due on the Facility (‘Chargeable Interest’)
              from the Facility Amount to be disbursed to the Borrower. The
              Lender shall set-off/ amortise the Chargeable Interest on a daily
              basis till the end of the Availability Period/ Tenure /pre-payment
              by the Borrower towards Interest due for the Facility.
            </li>
            <li style={{ margin: "2px" }}>
              The Borrower acknowledges and confirms that the Lender shall have
              the discretion to change prospectively the rate of interest and
              other charges applicable to the Facility.
            </li>
            <li style={{ margin: "2px" }}>
              The Borrower acknowledges and confirms having received a copy of
              Facility Agreement alongwith all the enclosures.
            </li>
            <li style={{ margin: "2px" }}>
              To the extent unaltered, the terms and conditions herein shall be
              read and interpreted in accordance with the Facility Agreement.
            </li>
            <li style={{ margin: "2px" }}>
              Confirmation of the Facility Tranche Request Form <br />
              The Borrower has read the Facility Tranche Request Form and MITC
              and has understood the details mentioned in the same.
              <table>
                <tbody>
                  <tr>
                    <td>IP Address</td>
                    <td>{ip}</td>
                  </tr>
                  <tr>
                    <td>Lat</td>
                    <td>{lat}</td>
                  </tr>
                  <tr>
                    <td>Long</td>
                    <td>{lon}</td>
                  </tr>
                  <tr>
                    <td>Mobile Number for eSign</td>
                    <td>{profile?.registered_mobile}</td>
                  </tr>
                  <tr>
                    <td>TimeStamp</td>
                    <td>{date}</td>
                  </tr>
                </tbody>
              </table>
            </li>
          </ol>
        </div>
      </div>
    );
  }
);

export default HtmlJSX;