import React, { useState, useEffect, useCallback } from 'react';
// LIBRARIES
import PropTypes from "prop-types";
// STYLES
import './styles.scss';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Header from '../Header';
import { nativeSupport } from '../../utils';

const AppHeader = ({ title, noTitle, configReducer, ...props }) => {

  const [headerSubTitle, setHeaderSubTitle] = useState('');
  const [stepId, setStepId] = useState(1);
  const [hideBack, setHideBack] = useState(false);
  const globalTitle = configReducer.title;
  
  /**
   * @description set screen title
   */
  useEffect(() => {
    const { stepId } = props.match.params || {};
    const screen = (configReducer.screens && configReducer.screens[stepId]) || {};
    if (!noTitle) {
      setHeaderSubTitle(title || screen?.title);
    }
    setStepId(Number(stepId));

    if (Number(stepId) !== 1) {
      nativeSupport.setNativeRoute(false);
      return;
    }
    nativeSupport.setNativeRoute(true);
  }, []);

  useEffect(() => {
    if (!nativeSupport.isNative() && stepId === 1) {
      setHideBack(true);
      return;
    }
    setHideBack(false);
  }, [stepId]);

  /**
   * @function goToBack
   * @description header back button handling
   */
  const goToBack = useCallback(() => {
    if (nativeSupport.getNativeRoute()) {
      nativeSupport.closeWebView();
      return;
    }
    nativeSupport.openRetailerApp();
  }, []);

  return (
    <div className="app-header">
      <Header hideBack={hideBack} stopback={nativeSupport.isNative()} gotoBack={goToBack} title={globalTitle} subTitle={headerSubTitle} { ...props } />
    </div>
  )
}

AppHeader.propTypes = {
  configReducer: PropTypes.object,
  title: PropTypes.string,
  noTitle: PropTypes.bool,
};

AppHeader.defaultProps = {
  configReducer: {},
  title: '',
  noTitle: false,
};

const mapStateToProps = ({
  FormConfigReducer,
}) => ({
  configReducer: FormConfigReducer?.data
});

const mapDispatchToProps = null;

export default connect(
  mapStateToProps,
  mapDispatchToProps)(withRouter(AppHeader));
