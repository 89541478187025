import Compressor from 'compressorjs';

/**
 * @function base64ToFile
 * @param {String} dataURI
 * @param {String} fileName
 * @description convert base64 to File object
 */
const base64ToFile = (dataURI, fileName) => {
    const arr = dataURI.split(',')
    const mime = arr[0].match(/:(.*?);/)[1]
    const bstr = atob(arr[1])
    let n = bstr.length
    const u8arr = new Uint8Array(n)

    while (n--) {
        u8arr[n] = bstr.charCodeAt(n)
    }
    return new File([u8arr], fileName, { type: mime })
}

// convert file to base64
const getBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
}

// return base64 data after successfully compress the image
const compressImage = (imageData, quality = 0.4) => {
    return new Promise((resolve, reject) => {
      new Compressor(imageData, {
            quality,
            strict: false,
            checkOrientation: true,
            success: (result) => {
              // convert blob file into base64
              getBase64(result).then((result) => {
                resolve(result);
              }, (err) => {
                alert(err);
              })
            },
            error: (err) => {
              reject(err);
            }
        }
      );
    });
}

export default {
    base64ToFile,
    compressImage,
    getBase64,
};
