import React from 'react';
// LIBRARIES
import cx from 'classnames';
// STYLES
import './styles.css';

const loaderGIF = require('../../assets/icons/loader/loader.gif');

const Modal = ({ isVisible }) => (
  <div
    className={cx('loader__container loader--modal', {
      'is-visible': isVisible
    })}
  >
    <img src={loaderGIF} alt="" className="loader__element" />
  </div>
);

Modal.defaultProps = {
  isVisible: false
};
export default Modal;
