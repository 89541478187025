import React from 'react';
// STYLES
import './styles.scss';
import PropTypes from 'prop-types';
import dropdownIcon from '../../assets/dropdown.png';
import { RadioGroup, Radio } from '../RadioGroup';
import Modal from '../Modal';
import cx from 'classnames';

class Dropdown extends React.Component {

  uniqueNameForRadio = new Date().getTime().toString();

  constructor(props) {
    super(props);
    this.state = {
      isDropdownOpen: false,
      selected: '',
      defaultText: '',
      labelColorChange:false
    };
    this.openDropdown = this.openDropdown.bind(this);
    this.onCloseModal = this.onCloseModal.bind(this);
    this.onRadioChange = this.onRadioChange.bind(this);
  }

  componentDidMount() {
    this.setState({defaultText: this.props.defaultText, selected: this.props.selected});
    if (this.props.selected !== undefined) {
      this.updateDropdownText(this.props.selected);
    }
  }

  // update dropdown whenever props selected value change
  componentDidUpdate(prevProps) {
    if (prevProps.selected !== this.props.selected) {
      this.setState({selected: this.props.selected});
      this.updateDropdownText(this.props.selected);
    }
  }


  // on click dropdown to show modal
  openDropdown() {
    this.setState({isDropdownOpen: true,labelColorChange :true});
  }

  // on close button to hide modal
  closeDropdown(selectedVal) {
    if (typeof this.props.onClose === 'function') {
      this.props.onClose(this.state.selected);
    }
    this.updateDropdownText(selectedVal);
  }

  // update dropdown text
  updateDropdownText(selectedVal) {
    if (selectedVal === undefined) {
      selectedVal = this.state.selected;
    }

    let defaultText = this.props.defaultText;
    const selectedObj = this.props.options.find((val) => val.key === selectedVal);
    const nextDefaultText = (selectedObj && selectedObj.value) || '';
    this.setState({isDropdownOpen: false, defaultText: nextDefaultText || defaultText});
  }

  // on close button to hide modal
  onCloseModal() {
    this.closeDropdown();
  }

  // callback on change radio button
  onRadioChange(selectedVal) {
    this.setState({selected: selectedVal});

    // notify selected value to parent component
    this.props.onFormValueChange(selectedVal);
    this.closeModal(selectedVal);
  }

  // close Modal
  closeModal(selectedVal) {
    if (this.props.autoClose) {
      this.closeDropdown(selectedVal);
    }
  }

  render() {
    const { label, options, labelColor, readonly, requiredField } = this.props;
    const { selected, defaultText, labelColorChange } = this.state;
    

    return (
      <div className={cx("dropdown-container input__container", readonly? 'readonly': '')}>
        {label? (<label className={`input__label ${defaultText !== this.props.defaultText ? 'valid': ''}`}>
          {label}&nbsp;
          {requiredField ? <span className="required-text">*</span> : <span>(Optional)</span>}
        </label>): null}
        <div onClick={this.openDropdown} className="dropdown-select">
          <span style={ labelColor && (labelColorChange ? {color: "var(--dark)",fontSize: "105%",fontFamily: "Avenir-Heavy",fontWeight: "900",
          lineHeight: "1.71"} 
          : selected?.length>0 ? {color: "var(--dark)",fontSize: "105%",fontFamily: "Avenir-Heavy",fontWeight: "900",
          lineHeight: "1.71"} : {color:"darkgray"}) }>{defaultText}</span>
          <img src={dropdownIcon} />
        </div>
        <Modal visible={this.state.isDropdownOpen} onClose={this.onCloseModal} animation="slideUp" height='auto'>
            <RadioGroup name={this.uniqueNameForRadio} selectedValue={selected} onChange={this.onRadioChange}>
              {
                options.map((val, key) => {
                  return (
                    <Radio value={val.key} title={val.value} className="dib" key={key} />
                  )
                })
              }
            </RadioGroup>
        </Modal>
      </div>
    )
  }
}

Dropdown.propTypes = {
  label: PropTypes.string,
  defaultText: PropTypes.string,
  selected: PropTypes.string,
  options: PropTypes.array,
  readonly: PropTypes.bool,
  onFormValueChange: PropTypes.func,
  onClose: PropTypes.func,
  close: PropTypes.func,
  autoClose: PropTypes.bool,
  requiredField: PropTypes.bool,
}

Dropdown.defaultProps = {
  label: '',
  defaultText: '--- Select ---',
  selected: '',
  options: [],
  autoClose: true,
  readonly: false,
  onFormValueChange: () => {},
  onClose: () => {},
  close: () => {},
  requiredField: false,
}


export default Dropdown;
