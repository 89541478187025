import { GLOBAL_CONSTANT } from "../constants/globalConstant";
import moment from "moment";

// check validation before submit
export const ValidationV2 = {
    checkValidation: function(value, validationRule, fieldName = 'value', fieldType = GLOBAL_CONSTANT.FORM_FIELD_TYPES.TEXT) {
      let isValid = true;
      let validationType = '';
      let validationValue = '';
      if (!validationRule) {
        return true;
      }

      const validationList = ValidationV2.getValidationRulesJson(validationRule);
      for (const key of Object.keys(validationList)) {
        const val = validationList[key];

        // check form field is not required then skip checking other validations (required will always come at the first position from server)
        if (key === 'required' && val !== 'true') {
          if (!value) {
            break;
          }
        }

        // check field value match with validator
        if (!this.isValid(value, key, val, fieldType)) {
          isValid = false;
          validationType = key;

          if (key === 'regex' || key === 'required') {
            validationValue = fieldName;
          } else {
            validationValue = val;

            // format validation date into (DD/MM/YYYY) for showing in validation error message
            const {FORM_FIELD_VALIDATION_TYPES, FORM_FIELD_TYPES} = GLOBAL_CONSTANT;
            if (fieldType === FORM_FIELD_TYPES.DATE && (key === FORM_FIELD_VALIDATION_TYPES.MIN || FORM_FIELD_VALIDATION_TYPES.MIN)) {
              validationValue = moment(+new Date(validationValue)).format('DD/MM/YYYY');
            }
          }
          break;
        }
      }
      return {
        isValid,
        validationType,
        validationValue
      };
  },

  isValid: function(value, validationKey, validationValue, fieldType) {
    const { FORM_FIELD_VALIDATION_TYPES } = GLOBAL_CONSTANT;
    if (!validationValue) {
      return true;
    }

    // check field value with it's validator
    switch (validationKey) {
      case FORM_FIELD_VALIDATION_TYPES.REQUIRED:
        if (validationValue === 'true') {
          if (!value) {
            return false;
          }
        }
        break;
      case FORM_FIELD_VALIDATION_TYPES.MIN:
      case FORM_FIELD_VALIDATION_TYPES.MAX:
        return ValidationV2.checkMinMax(value, validationKey, validationValue, fieldType);
      case FORM_FIELD_VALIDATION_TYPES.REGEX:
        if (!eval(validationValue).test(value)) {
          return false;
        }
        break;
      default:
        // default check
    }
    return true;
  },

  checkMinMax: (value, validationKey, validationValue, fieldType) => {
    const { FORM_FIELD_TYPES, FORM_FIELD_VALIDATION_TYPES } = GLOBAL_CONSTANT;
    let transformValue = 0;
    let transformValidationValue = 0;

    // convert values according to Field Type
    switch (fieldType) {
      case FORM_FIELD_TYPES.DATE:
        transformValue = +new Date(value);
        transformValidationValue = +new Date(validationValue);
        break;
      case FORM_FIELD_TYPES.NUMBER:
        transformValue = Number(value);
        transformValidationValue = Number(validationValue);
        break;
      default:
        transformValue = value?.length || 0;
        transformValidationValue = Number(validationValue);
    }
    
    // check min case
    if (validationKey === FORM_FIELD_VALIDATION_TYPES.MIN) {
      if (transformValue < transformValidationValue) {
        return false;
      }
      return true;
    }

    // check max case
    if (transformValue > transformValidationValue) {
      return false;
    }
    return true;
  },

  getValidationRulesJson: (rules) => {
    const validationList = rules.split('||');
    const rulesJson = {};
    for (const validation of validationList) {
      const [key, val] = validation.split(':');
      rulesJson[key] = val;
    }

    return rulesJson;
  },
}