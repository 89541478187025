// LIBRARIES
import React from "react";
import "./styles.css";
import Camera from "../../assets/camera.svg";
import Retry from "../../assets/retry.svg";
import { nativeSupport, ImageCompression } from "../../utils";
import PropTypes from 'prop-types';
import DotLoader from "../DotLoader";

export const IMAGE_UPLOAD_STATUS = {
  INIT: 'INIT',
  PENDING: 'PENDING',
  SUCCESS: 'SUCCESS',
  FAILED: 'FAILED',
};

class ImageUpload extends React.Component {

  uniqueID = new Date().getTime();
  lastImageData = {};

  constructor(props) {
    super(props);
    this.fileInputChange = this.fileInputChange.bind(this);
    this.retryUploading = this.retryUploading.bind(this);
  }

  /**
   * @function onNativeCameraOpen
   * @param {string} type
   * @description request to open native back camera
   */
  onNativeCameraOpen(type = 'BACK') {
    nativeSupport.onNativeCameraOpen(type).then((data) => {
      if (data.base64) {
        this.processImageCompress(ImageCompression.base64ToFile(data.base64, data.fileName), data.fileName);
      }
    }).catch((e) => {
      alert('Something went wrong!');
    })
  }

  // file change event
  fileInputChange(event) {
    const fileElem = document.querySelector(`#imgupload${this.props.data.name}${this.uniqueID}`);
    const file = event.target.files[0];
    if (file) {
      this.processImageCompress(file, file.name, file.size, file.type);
      // compress selected image

      // reset input<file> value
      if (fileElem) {
        fileElem.value = null;
      }
    }
  }

  /**
   * @function sendImageToParent
   * @description send image to parent component as props
   */
  sendImageToParent(base64, name, size, type) {
    if (this.props.sendImages) {
      this.lastImageData = {imageUrl: base64, imageName: name, imageSize: size, imageType: type};
      this.props.sendImages(this.props.data, this.lastImageData);
    }
  }

  /**
   * @function retryUploading
   * @description retry to upload previous image
   */
  retryUploading() {
    this.props.sendImages(this.props.data, this.lastImageData);
  }

  /**
   * @function processImageCompress
   * @param {File|Blob} file
   * @param {String} name
   * @param {Number} size
   * @param {String} type
   * @description image compression start
   */
  processImageCompress(file, name, size, type) {
    ImageCompression.compressImage(file).then((compressBase64)  => {
      this.sendImageToParent(compressBase64, name, size, type);
    }, (err) => {
      alert(err.message);
    });
  }

  // image edit/delete buttons click event
  imageButtonsClick(event, type) {
    switch (type) {
      case 'delete':
        this.props.deleteImage(this.props.data);
        break;
      default:
        // noting for default
    }
    event.preventDefault();
    event.stopPropagation();
  }

  /**
   * @function getRequiredOptionalLabel
   * @description return required/optional label
   */
  getRequiredOptionalLabel() {
    const {requiredField} = this.props;
    return (
      <>
        &nbsp;
        {requiredField ? <span className="required-text">*</span> : <span>(Optional)</span>}
      </>
    )
  }

  render() {
    const { props, uniqueID } = this;
    return (
      <div className="image-upload-wrap">
          <input type="file" id={`imgupload${props.data.name}${uniqueID}`} className="dsp-n" capture="user" accept="image/*" onChange={this.fileInputChange} />
          { props.uploadStatus !== IMAGE_UPLOAD_STATUS.INIT ?
            <div className={`image-upload-item ${props.uploadStatus === IMAGE_UPLOAD_STATUS.PENDING ? 'uploading' : ''}`}>
              <div className="image-section algn-cntr">
                <p className="clr-dark p-v10 algn-lft font-heavy check-icon flex-both-cntr">
                  {props.label}
                  {this.getRequiredOptionalLabel()}
                </p>
                <img src={props.data.image} alt="" />
                <div className="image-btns">
                  <span className="delete-img" onClick={(event) => this.imageButtonsClick(event, 'delete')}>&nbsp;</span>
                  {/* <span className="delete-img" onClick={(event) => this.imageButtonsClick(event, 'delete')}>&nbsp;</span> */}
                </div>
              </div>
            </div>
          :
            <div className="image-upload-item">
              {
                nativeSupport.isNative() ? 
                  <label onClick={() => this.onNativeCameraOpen()}>
                  <span className="action flex-both-cntr fs-14">
                    <div>
                      <p className="clr-dark font-heavy">
                        {props.label}
                        {this.getRequiredOptionalLabel()}
                      </p>
                    </div>
                    <div className="font-heavy clr-primary flex-both-cntr">
                      <img className="upload_doc-img" src={Camera} alt="" />
                      <span>Add</span>
                    </div>
                  </span>
                </label> :
                <label htmlFor={`imgupload${props.data.name}${uniqueID}`}>
                  <span className="action flex-both-cntr fs-14">
                    <div>
                      <p className="clr-dark font-heavy">
                        {props.label}
                        {this.getRequiredOptionalLabel()}
                      </p>
                    </div>
                    <div className="font-heavy clr-primary flex-both-cntr">
                      <img className="upload_doc-img" src={Camera} alt="" />
                      <span>Add</span>
                    </div>
                  </span>
                </label>
              }
            </div>
          }

          {
            props.uploadStatus === IMAGE_UPLOAD_STATUS.PENDING && (
              <div className="pos-abs upload-status-container flex-both-cntr">
                <DotLoader />
              </div>
            )
          }

          {
            props.uploadStatus === IMAGE_UPLOAD_STATUS.FAILED && (
              <div className="pos-abs upload-status-container flex-both-cntr">
                {nativeSupport.isNative() ? (
                  <label onClick={() => this.onNativeCameraOpen()} className="upload-new m-r-10 dsp-flex">
                    <img src={Camera} alt="camera" />
                  </label>
                ) : (
                  <label htmlFor={`imgupload${props.data.name}${uniqueID}`} className="upload-new m-r-10 dsp-flex">
                    <img src={Camera} alt="camera" />
                  </label>
                )}
                <label className="upload-new dsp-flex" onClick={this.retryUploading}>
                  <img src={Retry} alt="retry"/>
                </label>
              </div>
            )
          }
      </div>
    );
  }
}

ImageUpload.propTypes = {
  requiredField: PropTypes.bool,
  uploadStatus: PropTypes.oneOfType([...Object.values(IMAGE_UPLOAD_STATUS)])
}

ImageUpload.defaultProps = {
  requiredField: false,
  uploadStatus: IMAGE_UPLOAD_STATUS.INIT,
}

export default ImageUpload;
