import {  put, takeEvery } from 'redux-saga/effects';
import { UPLOAD_IMAGE } from "../actionTypes";
import { apiCall } from "../../services";
import { URL_CONSTANTS } from '../../constants/urlConstant'
import { toast } from 'react-toastify';
import { MESSAGE_CONSTANT } from '../../constants/msgConstant';


function* uploadImage(actions) {

  const params = {
    image: actions.data.image,
    name: actions.data.name
  };
  
  const response = yield apiCall({
    method: 'POST',
    url: URL_CONSTANTS.UPLOAD_IMAGE,
    data: {params},
    options: {
      defaultLoader: false
    }
  });

  if (response.data && response.data.success){
    actions.callback(response.data.success, response.data.data);
    toast.success(response.data.message || MESSAGE_CONSTANT.IMAGE_UPLOADED);
  } else {
    actions.callback(false, response.status);
  }
}

export default function* root() {
  yield takeEvery(UPLOAD_IMAGE, uploadImage);
}