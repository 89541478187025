import { UPDATE_DRAFT_DATA, UPDATE_FORM1_DRAFT_DATA, UPDATE_FIELD_DATA, UPDATE_FORM2_DRAFT_DATA, RESET_DRAFT_DATA, UPDATE_FORM2_IMAGE_DRAFT_DATA } from "../actionTypes";

const initialState = {
  data: {
    form_data: {}
  }  
};

const DraftReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_DRAFT_DATA:
      return { ...state, data: {...action.data} };
    case UPDATE_FORM1_DRAFT_DATA:
      return { ...state, data: {...state.data, form1: {...action.form1}} };
    case UPDATE_FORM2_DRAFT_DATA:
      return { ...state, data: {...state.data, form2: {...action.form2}} };
    case UPDATE_FORM2_IMAGE_DRAFT_DATA:
      return { ...state, data: {...state.data, form2: {...state.data.form2, ...action.imageData}} };
    case UPDATE_FIELD_DATA:
      const previousState = state.data.form_data;
      const actionData = action.data;
      
      if (actionData.type === 'ADD_IMAGE') {
        previousState[actionData.fieldName] = previousState[actionData.fieldName] || [];
        const imageObj = {
          id: actionData.image_id,
          link: actionData.imageUrl
        };
        previousState[actionData.fieldName].push(imageObj);
      } else if (actionData.type === 'REMOVE_IMAGE') {
        if (previousState[actionData.fieldName]) {
          const index = previousState[actionData.fieldName].findIndex((val) => {
            return val.id === actionData.image_id;
          });

          if (index !== -1) {
            previousState[actionData.fieldName].splice(index, 1);
          }
        }
      }
      return { ...state, data: {...state.data, form_data: {...previousState}} };
    case RESET_DRAFT_DATA:
      return { ...state, data: {...initialState.data} };
    default:
      return {...state};
  }
};

export default DraftReducer;
  