import React from 'react';
// LIBRARIES
import PropTypes from "prop-types";
import cx from 'classnames';
// STYLES
import './styles.scss';
import { currency } from '../../utils/currency-formatter';

const RupeeAmount = ({ amount, iconClass, ...others }) => {

  return (
    <div { ...others } className={cx('rupee-container dsp-inb', others.className)}>
        <span className={cx("rupee-icon font-light", iconClass)}>₹</span>
        <span className="rupee-currency">{currency.format(amount)}</span>
    </div>
  )
}

RupeeAmount.propTypes = {
  amount: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  iconClass: PropTypes.string
};

RupeeAmount.defaultProps = {
  amount: '',
  iconClass: ''
};

export default React.memo(RupeeAmount);
