import {  put, takeEvery } from 'redux-saga/effects';
import { SEND_OTP } from "../actionTypes";
import { apiCall } from "../../services";
import { URL_CONSTANTS } from '../../constants/urlConstant';
import { toast } from 'react-toastify';
import { showOtpModal } from '../actions/otpModal.action';

function* sendOtp(actions) {

  const formData = yield apiCall({
    method: 'POST',
    url: URL_CONSTANTS.SEND_OTP
  });

  if (formData.data && formData.data.success){
    toast.success(formData.data.message);
    yield put(showOtpModal());
    if (actions.callback) {
      actions.callback(formData.data.success);
    }
  } else {
    if (actions.callback) {
      actions.callback(false);
    }
  }
}

export default function* root() {
  yield takeEvery(SEND_OTP, sendOtp);
}