import { SET_FORM_CONFIG_DATA, RESET_FORM_CONFIG_DATA } from "../actionTypes";

const initialState = {
  data: {}  
};

const ConfigReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_FORM_CONFIG_DATA:
      return { ...state, data: {...action.data} };
    case RESET_FORM_CONFIG_DATA:
      return { ...state, data: {...initialState.data} };
    default:
      return {...state};
  }
};

export default ConfigReducer;
  