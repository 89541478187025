import React from 'react';
// LIBRARIES
import cx from 'classnames';
// STYLES
import './styles.scss';

import checkimg from '../../assets/icons/checked.svg';
import uncheck from '../../assets/icons/unchecked.svg';

class CheckBox extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isChecked: false
    };
  }

  componentDidMount() {
    this.setState({
      isChecked: this.props.hasChecked
    });
  }

  handleCheckBox() {
    if (this.props.doNotChange) {
      return;
    }

    this.setState({isChecked: !this.state.isChecked});
    this.props.handleCheckBox &&
    this.props.handleCheckBox(!this.props.hasChecked)

  }
  toggle = () => {
    if (this.props.doNotChange) {
      return;
    }

    if(this.state.isChecked){
      this.setState({
        isChecked: true
      });
    }else{
      this.setState({
        isChecked: false
      });
    }
  };
  render() {
    const { props } = this; 
    const {
        checkStatus,
        text,
        onChange,
        containerClassName,
        containerStyle,
        disabled = false
    } = props;

    return (
      <div
        className={cx('checkbox__container', containerClassName)}
        style={containerStyle}
      >
        <label className="container_checkbox--default">
          <input 
              className="main_input_checkbox"
              type="checkbox" 
              name={text} 
              value={text}
              checked={props.hasChecked}
              disabled={props.disabled}
              onChange={() => {this.handleCheckBox()}}
          />

          <span className="box" onClick={()=> this.toggle()} >
            { this.state.isChecked ? <img className="check_icon" src={checkimg} /> : <img className="check_icon" src={uncheck} /> }
          </span>
          <span className="text">{text}</span>
        </label>
      </div>
    );
  }
}

export default CheckBox;
