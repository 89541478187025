import {  put, takeEvery } from 'redux-saga/effects';
import { POST_FORM_DETAILS } from "../actionTypes";
import { apiCall } from "../../services";
import { URL_CONSTANTS } from '../../constants/urlConstant';

function* postDetails(actions) {

  const params = {...actions.data};
  // below property not send to sever
  delete params.mobile;
  
  const response = yield apiCall({
    method: 'POST',
    url: URL_CONSTANTS.POST_FORM_DETAILS,
    data: {
      params
    }
  });
  if (response.data && response.data.success){
    actions.callback && actions.callback();
  }
}

export default function* root() {
  yield takeEvery(POST_FORM_DETAILS, postDetails);
}