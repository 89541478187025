import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
// STYLES
import './styles.scss';
import { GLOBAL_CONSTANT } from '../../constants/globalConstant';

const MSG_CONST = {
  'required': '$1 field required',
  'min': 'Minimum $1 characters required',
  'max': 'Maximum $1 characters allowed',
  'date_min': 'Date must be not less than $1',
  'date_max': 'Date must be not greater than $1',
  'number_min': 'Value must be not less than $1',
  'number_max': 'Value must be not greater than $1',
  'regex': 'Enter valid $1'
};

const FormError = ({ defaultMsg, show, customClass, type, subString, inputType, ...props }) => {

  const [msg, setMsg] = useState('');

  /**
   * @description show error message according to error type
   */
  useEffect(() => {
    let msg = MSG_CONST[type] || '';
    if (inputType === GLOBAL_CONSTANT.FORM_FIELD_TYPES.DATE) {
      msg = MSG_CONST[`date_${type}`];
    } else if(inputType === GLOBAL_CONSTANT.FORM_FIELD_TYPES.NUMBER) {
      msg = MSG_CONST[`number_${type}`];
    }
    msg = msg?.replace('$1', subString);
    setMsg(msg)
  }, [type, subString, inputType]);

  return (
    <Fragment>
      { show && <div className={customClass} {...props}>
          <div className="form-error">{msg || defaultMsg}</div>
        </div>
      }
    </Fragment>
  )
}

FormError.propTypes = {
  defaultMsg: PropTypes.string,
  subString: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.string
  ]),
  show: PropTypes.bool,
  type: PropTypes.string,
  inputType: PropTypes.string,
  customClass: PropTypes.string
};

FormError.defaultProps = {
  defaultMsg: 'Invalid value',
  subString: [],
  show: false,
  type: '',
  inputType: GLOBAL_CONSTANT.FORM_FIELD_TYPES.TEXT,
  customClass: ''
};

export default React.memo(FormError);
