import React, { useCallback, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import DigitalWatch from '../../components/DigitalWatch';
import resendLoginOtp from '../../hoc/ResendLoginOtp';
import { VALIDATOR_CONSTANTS } from '../../constants/validatorConstant';
import useFormField from '../../hooks/useFormField';
import { InputDefault } from '../../components';
import Button from '../../components/Button';
import { GLOBAL_CONSTANT } from '../../constants/globalConstant';
import { verifyLoginOtpAction } from '../../redux/actions/verifyLoginOtp.action';
import { requestLoginOtpReset } from '../../redux/actions/loginOtp.action';
import { connect } from 'react-redux';

const LoginOtpContainer = ({
  requestLoginOtpReducer,
  verifyLoginOtpAction,
  requestLoginOtpReset,
  requestToResendOtp,
  resetResendOtpData,
  resendOtpData,
}) => {
  const [otp, setOtp, isValid] = useFormField('', {
    max: 4,
    regex: VALIDATOR_CONSTANTS.OTP,
  });
  const [showOtpTimer, setShowOtpTimer] = useState(true);

  /**
   * @description mount/unmount of the component
   */
  useEffect(() => {
    return () => {
      requestLoginOtpReset();
      resetResendOtpData();
    };
  }, []);

  /**
   * @description on resend OTP success restart OTP timer
   */
  useEffect(() => {
    if (resendOtpData.success) {
      setShowOtpTimer(true);
    }
  }, [resendOtpData.isFetching]);

  /**
   * @function verifyOtp
   * @description verify login OTP
   */
  const verifyOtp = useCallback(
    event => {
      const requestData = {
        otp,
        uuid: requestLoginOtpReducer?.data?.uuid,
      };
      verifyLoginOtpAction(requestData);
      event.preventDefault();
    },
    [otp, requestLoginOtpReducer.data]
  );

  /**
   * @function onFormFieldChange
   * @description on form input fields change
   */
  const onFormFieldChange = useCallback(event => {
    const { value } = event.target;
    setOtp(value);
  }, []);

  /**
   * @function onResendTimerEnd
   * @description trigger on OTP timer end
   */
  const onResendTimerEnd = useCallback(() => {
    setShowOtpTimer(false);
  }, []);

  /**
   * @function onRequestToResendOtp
   * @description request for resend OTP to server
   */
  const onRequestToResendOtp = useCallback(() => {
    const requestData = {
      uuid: requestLoginOtpReducer?.data?.uuid,
    };
    requestToResendOtp(requestData);
  }, []);

  /**
   * @function goToMobileNumber
   * @description go to mobile number screen
   */
  const goToMobileNumber = useCallback(() => {
    requestLoginOtpReset();
  }, [requestLoginOtpReset]);

  return (
    <div className="login-otp-container">
      <form onSubmit={verifyOtp}>
        <div className="mr-v-15">
          <InputDefault
            required
            hasLabel
            type="tel"
            label="Enter OTP"
            requiredField
            value={otp}
            onChange={onFormFieldChange}
          />

          <div className="dsp-flex justify-space-between flex-align-start fs-12 m-t-10 clr-primary">
            <span className="underline font-heavy" onClick={goToMobileNumber}>Change Mobile</span>
            {showOtpTimer ? (
              <div className="font-heavy clr-dark fs-10">
                <DigitalWatch
                  timeInMs={GLOBAL_CONSTANT.OTP_TIMER}
                  onTimeEnd={onResendTimerEnd}
                />
              </div>
            ) : (
              <Button
                variant="transparent"
                buttonClassName="fs-12"
                onClick={onRequestToResendOtp}
              >
                Resend OTP
              </Button>
            )}
          </div>
        </div>

        <Button
          type="submit"
          text="Login"
          buttonClassName="w-100"
          disabled={!isValid}
        />
      </form>
    </div>
  );
};

LoginOtpContainer.propTypes = {
  requestLoginOtpReducer: PropTypes.object,
  verifyLoginOtpAction: PropTypes.func,
  requestLoginOtpReset: PropTypes.func,
  requestToResendOtp: PropTypes.func,
  resendOtpData: PropTypes.object,
  resetResendOtpData: PropTypes.func,
};

LoginOtpContainer.defaultProps = {
  requestLoginOtpReducer: {},
  verifyLoginOtpAction: () => {},
  requestLoginOtpReset: () => {},
  requestToResendOtp: () => {},
  resendOtpData: {},
  resetResendOtpData: () => {},
};

const mapStateToProps = ({
  RequestLoginOtpReducer,
}) => ({
  requestLoginOtpReducer: RequestLoginOtpReducer,
});

const mapDispatchToProps = dispatch => ({
  verifyLoginOtpAction: (payload) => dispatch(verifyLoginOtpAction(payload)),
  requestLoginOtpReset: () => dispatch(requestLoginOtpReset()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps)(resendLoginOtp(LoginOtpContainer));
