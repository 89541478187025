import React, { Fragment, createRef } from 'react';
// STYLES
import './styles.scss';
import cx from 'classnames';
import PropTypes from 'prop-types';

class DateInput extends React.PureComponent {
  
  uniqueValue = new Date().getTime();
  ref = {DD: createRef(), MM: createRef(), YYYY: createRef()};
  refList = [];
  constructor(props) {
    super(props);

    this.state = {
      d: '',
      m: '',
      y: ''
    };
  }

  componentDidMount() {
    this.updateDate(this.props.value);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.value !== this.props.value) {
      this.updateDate(this.props.value);
    }
  }

  getElements(key, index) {
    const DATE_ELEMENTS = {
      DD: <input type="number" readonly={this.props.readonly} value={this.state.d} ref={this.ref.DD} className="d" placeholder="DD" id={index === 0 ? "customDobLabel": ''} required maxLength="2" onChange={({target}) => this.inputChange('d', target.value, 2, index)} onBlur={() => this.onBlur('d')} />,
      MM: <input type="number" readonly={this.props.readonly} value={this.state.m} ref={this.ref.MM} className="m" placeholder="MM" id={index === 0 ? "customDobLabel": ''} required maxLength="2" onChange={({target}) => this.inputChange('m', target.value, 2, index)} onBlur={() => this.onBlur('m')} />,
      YYYY: <input type="number" readonly={this.props.readonly} value={this.state.y} ref={this.ref.YYYY} className="y" placeholder="YYYY" id={index === 0 ? "customDobLabel": ''} required maxLength="4" onChange={({target}) => this.inputChange('y', target.value, 4, index)} onBlur={() => this.onBlur('y')} />
    };
    return DATE_ELEMENTS[key];
  }

  // return elements list according to Date format
  renderElements() {
    const saperatorElem = <span className="saperator">{this.props.saperator}</span>;
    const elementsList = [];

    const formatSeq = this.props.format.split(this.props.saperator);

    // return elements according to format
    let count = 0;
    this.refList.length = 0;
    formatSeq.forEach((val, index) => {
      count++;
      elementsList.push(<Fragment key={count}>{this.getElements(val, index)}</Fragment>);
      this.refList.push(this.ref[val]);
      if (index < (formatSeq.length - 1)) {
        count++;
        elementsList.push(<Fragment key={count}>{saperatorElem}</Fragment>);
      }
    });
    return elementsList;
  }

  // update date
  updateDate(date) {
    if (date) {
      const cDate = new Date(date);
      
      if (!isNaN(cDate)) {
        this.setState({
          d: this.formatNumber(cDate.getDate()),
          m: this.formatNumber(cDate.getMonth() + 1),
          y: cDate.getFullYear()
        });
      } else {
        this.props.onFormValueChange('');
        console.error('date format not valid');
      }
    }
  }

  // on Input value change
  inputChange(key, value, maxLength, index) {
    if (value && value.length > maxLength) {
      return;
    }
    
    this.setState({
      [key]: value
    });

    // go to next input
    this.goToNext(key, index);
  }

  // input focus change
  onBlur(key) {

    if (key !== 'y') {
      const formatVal = this.formatNumber(this.state[key]);
      if (key !== formatVal) {
        this.setState({
          [key]: formatVal
        });
      }
    }

    const {d, m, y} = this.state;
    if (d && m && y && y.toString().length === 4) {
      this.props.onFormValueChange(new Date(`${m}/${d}/${y}`));
    } else {
      this.props.onFormValueChange('');
    }
  }
  
  // go to next input
  goToNext(key, index) {
    setTimeout(() => {
      const inputValue = this.state[key].toString();
      let isNext = false;
      if (key === 'd' && Number(inputValue) > 3) {
        isNext = true;
      } else if (key === 'm' && Number(inputValue) > 1) {
        isNext = true;
      } else if ((key === 'd' || key === 'm') && inputValue.length === 2) {
        isNext = true;
      } else if (key === 'y' && inputValue.length === 4) {
        isNext = true;
      }

      if (isNext && this.refList[index + 1]) {
        this.refList[index + 1].current.focus();
      }
    }, 100);
  }

  // format Number
  formatNumber(num) {
    num = num.toString();
    if (num === "" || num === "0") {
      return '';
    }

    return Number(num) < 10 ? `0${Number(num)}`: num;
  }

  render() {
    const { label, customClass, readonly, requiredField } = this.props;
    
    return (
      <div className={cx("flex-both-cntr dob-container", readonly? 'readonly': '', customClass)}>
        {
          this.renderElements()
        }
        {label && <label htmlFor="customDobLabel" className="label">
          {label}&nbsp;
          {requiredField ? <span className="required-text">*</span> : <span>(Optional)</span>}
        </label>}
      </div>
    )
  }
}

DateInput.propTypes = {
  format: PropTypes.string,
  saperator: PropTypes.string,
  value: PropTypes.string,
  customClass: PropTypes.string,
  readonly: PropTypes.bool,
  onFormValueChange: PropTypes.func,
  requiredField: PropTypes.bool,
};

DateInput.defaultProps = {
  format: 'DD/MM/YYYY',
  saperator: '/',
  value: '',
  customClass: '',
  readonly: false,
  onFormValueChange: () => {},
  requiredField: false,
};

export default DateInput;
