import { createStore, applyMiddleware, compose } from "redux";
import rootReducer from "../reducers";
import sagas from "../sagas";
import createSagaMiddleware from "redux-saga";
import { createLogger } from 'redux-logger';


const sagaMiddleware = createSagaMiddleware();
const env = process.env.REACT_APP_ENV || "development";
const logger = createLogger({
});

const store = env ===  "development" ?
  createStore(
    rootReducer,
    compose(
      applyMiddleware(
        logger,
        sagaMiddleware,
      )
    ),
  ) :
  createStore(
    rootReducer,
    applyMiddleware(
      sagaMiddleware,
    )
  );

export default store;

sagaMiddleware.run(sagas)

