import { SET_LOADING_STATUS, REMOVE_LOADING_STATUS } from "../actionTypes";

export function setLoader() {
  return {
    type: SET_LOADING_STATUS
  };
}

export function removeLoader() {
  return {
    type: REMOVE_LOADING_STATUS
  };
}
