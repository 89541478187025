import React from "react";
import "./styles.scss";
import bharatpeLimitLogo from '../../assets/bharatpe-limit-logo.png';
import qrImg from '../../assets/qr-img.png';
import AppHeader from "../AppHeader";

const NotEligibleLoanScreen = (props) => {
  return (
    <div className="not-eligible-loan-screen bg-white">
      <AppHeader noTitle />
      <div className="p-18 not-eligible-loan-body">
        <div className="algn-cntr">
          <p className="font-regular clr-dark">You are not eligible for</p>
          <div className="mr-v-10">
            <img src={bharatpeLimitLogo} alt="BharatPe_logo" className="limit-logo" />
          </div>
          <div className="m-t-25">
            <img src={qrImg} alt="qr_image" className="qr-img w-80" />
          </div>
        </div>

        <div className="m-t-25">
          <ul className="not-eligible-list fs-12 clr-dark font-light">
            <li className="m-t-15 flex-both-cntr justify-start p-5">
              <span className="m-r-10 icon pay-icon">&nbsp;</span>
              <span>Get  your Customers to Pay using <span className="font-heavy">BharatPe QR</span> everyday</span>
            </li>
            <li className="m-t-15 flex-both-cntr justify-start p-5">
              <span className="m-r-10 icon qr-icon">&nbsp;</span>
              Do transaction everyday for next 30 days
            </li>
            <li className="m-t-15 flex-both-cntr justify-start p-5">
              <span className="m-r-10 icon more-txns-icon">&nbsp;</span>
              More transactions = Higher Loan Amount
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

NotEligibleLoanScreen.propTypes = {};
NotEligibleLoanScreen.defaultProps = {};

export default React.memo(NotEligibleLoanScreen);
