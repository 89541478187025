import React, { useCallback } from "react";
import "./styles.scss";
import PropTypes from 'prop-types';

const VStep = ({successIcon, pendingIcon, failureIcon, customIconClass, ...props}) => {

  /**
   * @function getStepperIcon
   * @description return stepper icon class based on props value
   */
  const getStepperIcon = useCallback(() => {
    let stepperIcon = '';
    if (successIcon) {
      stepperIcon = 'success';
    } else if (failureIcon) {
      stepperIcon = 'failure';
    } else if (pendingIcon) {
      stepperIcon = 'pending';
    }

    return stepperIcon;
  }, [successIcon, pendingIcon, failureIcon]);

  return (
    <div className={`v-step ${getStepperIcon()} ${customIconClass}`}>
      {props.children}
    </div>
  );
}

VStep.propTypes = {
  successIcon: PropTypes.bool,
  pendingIcon: PropTypes.bool,
  failureIcon: PropTypes.bool,
  customIconClass: PropTypes.string,
};
VStep.defaultProps = {
  successIcon: false,
  pendingIcon: false,
  failureIcon: false,
  customIconClass: '',
};

export default VStep;
