import { VERIFY_LOGIN_OTP_ACTION, VERIFY_LOGIN_OTP_ACTION_SUCCESS, VERIFY_LOGIN_OTP_ACTION_FAILURE, VERIFY_LOGIN_OTP_ACTION_RESET } from "../actionTypes";

export const verifyLoginOtpAction = payload => {
  return {
    type: VERIFY_LOGIN_OTP_ACTION,
    payload,
  };
};

export const verifyLoginOtpActionSuccess = data => {
  return {
    type: VERIFY_LOGIN_OTP_ACTION_SUCCESS,
    data,
  };
};

export const verifyLoginOtpActionFailure = error => {
  return {
    type: VERIFY_LOGIN_OTP_ACTION_FAILURE,
    error,
  };
};

export const verifyLoginOtpActionReset = () => {
  return {
    type: VERIFY_LOGIN_OTP_ACTION_RESET,
  };
};
