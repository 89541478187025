import React, { useState, useCallback, useEffect } from "react";
import "./styles.scss";
import LoginMobileContainer from "../LoginMobileContainer";
import LoginOtpContainer from "../LoginOtpContainer/LoginOtpContainer";
import Header from "../../components/Header";
import bharatpeFullLogo from '../../assets/bharat-pe-logo.svg';
import { Card } from "../../components";
import loginBanner from '../../assets/login-banner.png';
import { connect } from "react-redux";

const LoginContainer = ({requestLoginOtpReducer}) => {
  const [showOtpForm, setShowOtpForm] = useState(false);

  useEffect(() => {
    if (requestLoginOtpReducer.success) {
      setShowOtpForm(true);
      return;
    }
    setShowOtpForm(false);
  }, [requestLoginOtpReducer.success]);

  /**
   * @function getHeader
   * @description return login header
   */
  const getHeader = useCallback(() => {
    return (
      <div className="dsp-flex">
        <img
          src={bharatpeFullLogo}
          alt="bharatpe-logo"
          className="login-header-logo"
        />
      </div>
    );
  }, []);
  
  return (
    <div className="login-container">
      <Header title={getHeader()} hideBack headerContainer="login-header" />
      <div className="login-body mr-16">
        <img
          src={loginBanner}
          alt="login-banner"
          className="w-100 login-banner"
        />
        <Card>
          <p className="clr-dark font-heavy">Retailer Verification</p>
          { showOtpForm ? 
            <LoginOtpContainer /> :
            <LoginMobileContainer /> }
        </Card>
      </div>
    </div>
  )
}

const mapStateToProps = ({
  RequestLoginOtpReducer,
}) => ({
  requestLoginOtpReducer: RequestLoginOtpReducer,
});

export default connect(
  mapStateToProps,
  null)(LoginContainer);
  