import React, { useState, useEffect } from 'react';
// LIBRARIES
import PropTypes from "prop-types";
// STYLES
import './styles.scss';
import Footer from '../Footer';
import CheckBox from '../Checkbox/CheckBox';
import Modal from '../Modal';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { GLOBAL_CONSTANT } from '../../constants/globalConstant';

const AppFooter = ({ tnc, configReducer, footerClass, ...props }) => {

  const [showTnC, setShowTnC] = useState(false);
  const [acceptTnC, setAcceptTnC] = useState(false);
  const [showTncModal, setShowTncModal] = useState(false);
  const [showConsent, setShowConsent] = useState(false);
  const [acceptConsent, setAcceptConsent] = useState(true);
  const [isFooterDisable, setIsFooterDisable] = useState(false);
  const [tncLink, setTncLink] = useState('');

  /**
   * @description check show TnC lines in footer or not
   */
  useEffect(() => {
    const { stepId } = props.match.params || {};
    const screen = (configReducer.screens && configReducer.screens[stepId]) || {};
    setShowTnC(screen.tnc_flag);
    setShowConsent(screen.consent_flag);
    setTncLink(tnc);
  }, []);

  /**
   * @description enable/disable footer according to agreements checks
   */
  useEffect(() => {
    const checkDisable = (showConsent && !acceptConsent);
    setIsFooterDisable(checkDisable)
  }, [showTnC, acceptTnC, showConsent, acceptConsent])

  // /**
  //  * @function openTncModal
  //  * @description open TnC modal
  //  */
  // const openTncModal = (type) => {
  //   setShowTncModal(true);
  //   if (type === GLOBAL_CONSTANT.TNC_TYPE.AGREEMENT) {
  //     setTncLink(tnc);
  //     return;
  //   }
  //   setTncLink(GLOBAL_CONSTANT.CONSENT_TNC);
  // }

  // /**
  //  * @function closeTncModal
  //  * @description close TnC modal
  //  */
  // const closeTncModal = () => {
  //   setShowTncModal(false);
  // }

  return (
    <div className="app-footer">
      {/* Consent buttons show according to server */}
      { //(showConsent) &&
        // <div className="fs-11 flex-both-cntr mr-16">
        //   <CheckBox hasChecked={acceptConsent} containerClassName="algn-self-start m-r-10" handleCheckBox={() => {
        //     setAcceptConsent((prev) => !prev)
        //   }} />
        //   <div className="w-100">
        //   <p>I authorize BharatPe to pull my credit report and agree to <span className="clr-primary">Terms &amp; Conditions</span></p>
        //   </div>
        // </div>
      }

      {/* TnC buttons show according to server */}
      {/* { (showTnC) &&
        <div className="fs-11 flex-both-cntr mr-16">
          <CheckBox hasChecked={acceptTnC} containerClassName="algn-self-start m-r-10" handleCheckBox={() => {
            setAcceptTnC((prev) => !prev)
          }} />
          <div className="w-100">
            <p>I have read and agree to the <span onClick={() => openTncModal(GLOBAL_CONSTANT.TNC_TYPE.AGREEMENT)} className="clr-primary">Terms of Loan</span></p>
            <p>BharatPe is the processing partner for this loan.<br />
            Loan is on the books of Hindon Mercantile which is a RBI regulated NBFC.</p>
          </div>
        </div> */
      }
      <Footer containerClassName={`${isFooterDisable ? 'isDisabled': ''} ${footerClass}`}>
        {props.children}
      </Footer>

      {/* <div className="tnc-container">
        <Modal visible={showTncModal}
          onClose={() => closeTncModal()}
          animation="slideUp"
          showCloseButton={true}
          closeMaskOnClick={false}
          height={'100%'}>
          <iframe title="Terms and Conditions" src={tncLink} style={{ minHeight: "90%", marginTop: "10%", width: "100%"}}></iframe>
        </Modal>
      </div> */}
    </div>
  )
}

AppFooter.propTypes = {
  configReducer: PropTypes.object,
  tnc: PropTypes.string,
  footerClass: PropTypes.string,
};

AppFooter.defaultProps = {
  configReducer: {},
  tnc: '',
  footerClass: '',
};

const mapStateToProps = ({
  DraftReducer,
  FormConfigReducer,
}) => ({
  tnc: DraftReducer.data?.tnc,
  configReducer: FormConfigReducer?.data
});

const mapDispatchToProps = null;

export default connect(
  mapStateToProps,
  mapDispatchToProps)(withRouter(AppFooter));
