/* eslint-disable no-console */
import { NativeSupportHandlerLegacy } from "native-support-handler";
import history from "./history";
import openLink from "./openLink";
import { GLOBAL_CONSTANT } from "../constants/globalConstant";

const initNativeSupport = async () => {
  await NativeSupportHandlerLegacy.init();
};

// get set isNative flag
let isNative_ = false;
const isNative = (isNative) => {
  if (isNative !== undefined) {
    isNative_ = isNative;
  }
  return isNative_;
};

const isIOS = () => {
  return (
    [
      'iPad Simulator',
      'iPhone Simulator',
      'iPod Simulator',
      'iPad',
      'iPhone',
      'iPod',
    ].includes(navigator.platform) ||
    // iPad on iOS 13 detection
    (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
  );
};

let goToNative = true;
const setNativeRoute = (isNative) => {
  goToNative = isNative;
};

const getNativeRoute = () => {
  return goToNative;
};

/**
 * @function subscribeBackButton
 * @description init native back button functionality
 */
const subScribeBackButton = async () => {
  try {
    const isSubscribed = await NativeSupportHandlerLegacy.subscribeBackButton();
    if (isSubscribed) {
      window.callbackSubscribeBackPressed = () => {
        if (goToNative) {
          closeWebView();
          return;
        }
        openRetailerApp();
      }
    }
  } catch (e) {
    console.error("Nothing to worry ! We handled it well");
    console.warn(e);
  }
};

const closeWebView = () => {
  if (isNative()) {
    NativeSupportHandlerLegacy.closeWebView().catch(() => {
      // no need to handle
    });
  } else {
    history.push('/');
  }
}

/**
 * @function openDeepLink
 * @param {string} link 
 * @description open deep link basis of Merchant or Browser
 */
const openDeepLink = async (link) => {
  if (isNative()) {
    if (isIOS()) {
      await NativeSupportHandlerLegacy.openDeeplink(link);
    } else {
      await NativeSupportHandlerLegacy.openSimBindingDeepLink(link);
    }
  } else {
    openLink(link);
    await Promise.resolve();
  }
}

/**
 * @function openDeepLinkAndClose
 * @param {string} link 
 * @description open deep link and closed current app
 */
const openDeepLinkAndClose = (key, wroute, wid, fullLink) => {
  if (isNative()) {
    const link = fullLink ? fullLink : `bharatpe://dynamic?key=${key}&wid=${wid}&wroute=${wroute}`;
    NativeSupportHandlerLegacy.openDeeplink(link);
  }
}

/**
 * @function openRetailerApp
 * @param {string} link 
 * @description open retailer app
 */
const openRetailerApp = () => {
  if (isDevMode()) {
    openDeepLinkAndClose(GLOBAL_CONSTANT.RETAILER_APP_DEV_DEEP_LINK);
    return;
  }
  openDeepLinkAndClose(GLOBAL_CONSTANT.RETAILER_APP_DEEP_LINK);
}

/**
 * @function onNativeCameraOpen
 * @param {string} type
 * @description request to open native camera
 */
const onNativeCameraOpen = async (type = 'BACK') => {
  if (type === 'BACK') {
    const res = await NativeSupportHandlerLegacy.openBackCamera();
    const fileName = 'image.jpg';
    return {base64: `data:image/jpeg;base64,${res}`, fileName};
  }
}

/**
 * @function isDevMode
 * @description check is development mode
 */
const isDevMode = () => {
  return process.env.NODE_ENV === "development";
};

export default {
  initNativeSupport,
  subScribeBackButton,
  setNativeRoute,
  getNativeRoute,
  isNative,
  isIOS,
  openDeepLink,
  closeWebView,
  onNativeCameraOpen,
  isDevMode,
  openDeepLinkAndClose,
  openRetailerApp,
};
