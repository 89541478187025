import { REQUEST_LOGIN_OTP_ACTION, REQUEST_LOGIN_OTP_ACTION_SUCCESS, REQUEST_LOGIN_OTP_ACTION_FAILURE, REQUEST_LOGIN_OTP_ACTION_RESET } from '../actionTypes';
import { INITIAL_STATE } from '../../constants/globalConstant';

const getRequestLoginOtpReducer = (state = INITIAL_STATE, action) => {
  const { type, data, error } = action;
  switch (type) {
    case REQUEST_LOGIN_OTP_ACTION:
      return {
        ...state,
        dataFetched: false,
        isFetching: true,
      };
    case REQUEST_LOGIN_OTP_ACTION_SUCCESS:
      return {
        ...state,
        isFetching: false,
        dataFetched: true,
        success: true,
        data,
      };
    case REQUEST_LOGIN_OTP_ACTION_FAILURE:
      return {
        ...state,
        isFetching: false,
        dataFetched: true,
        success: false,
        error,
      };
    case REQUEST_LOGIN_OTP_ACTION_RESET:
      return INITIAL_STATE;
    default:
      return state;
  }
};

export { getRequestLoginOtpReducer };
