// FRAMEWORK7 COMPONENTS
import React from 'react';
import { connect } from 'react-redux';
import "./styles.scss";
import ApplicationStatus from '../ApplicationStatus';
import LoanEligibility from '../LoanEligibility';
import DynamicFormContainer from '../DynamicFormContainer';
import Otp from '../../components/Otp';
import Modal from '../../components/Modal';
import { hideOtpModal } from '../../redux/actions/otpModal.action';
import DocumentSubmitted from '../DocumentSubmitted';
import { firebase } from '../../utils';

class RouteManager extends React.Component {

  /**
   * @function getComponent
   * @description return component accoding to config screen object
   */
  getComponent() {
    const { stepId } = this.props.match.params || {};
    const screen = this.props.formConfig.screens[stepId];
    if (screen) {
      firebase.logEvent(screen.type, {title: screen.title});
      return this.getComponentByType(screen.type);
    } else {
      return null;
    }
  }

  /**
   * @function getComponentByType
   * @param {string} type 
   * @description get component by screen type
   */
  getComponentByType(type) {
    const { stepId } = this.props.match.params || {};
    switch(type) {
      case 'CREDIT_CHECK':
        return <LoanEligibility {...this.props} key={stepId} />;
      case 'APPLICATION_STATUS':
        return <ApplicationStatus {...this.props} key={stepId} />;
      case 'SUBMITTED':
        return <DocumentSubmitted {...this.props} key={stepId} />
      default:
        return <DynamicFormContainer {...this.props} key={stepId} />;
    }
  }

  // close OTP modal
  closeOtpModal() {
   this.props.hideOtpModal();
  }

  render() {
    const { otpModalReducer } = this.props;
    return (
      <div className="route-manager-container">
        { this.getComponent() }

        {/* OTP container */}
        <Modal visible={otpModalReducer.show}
          onClose={() => this.closeOtpModal()}
          animation="slideUp"
          showCloseButton={true}
          closeMaskOnClick={false}
          height={'auto'}>
          <Otp showModal={otpModalReducer.show} />
        </Modal>
      </div>
    )
  }
}

const mapStateToProps = ({
  FormConfigReducer,
  OtpModalReducer
}) => ({
  formConfig: FormConfigReducer.data,
  otpModalReducer: OtpModalReducer.data,
});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  hideOtpModal: () => dispatch(hideOtpModal()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps)(RouteManager);
