import React from "react";
import "./styles.scss";
import RupeeAmount from "../RupeeAmount";
import PropTypes from 'prop-types';
import { GLOBAL_CONSTANT } from "../../constants/globalConstant";

const ApplicationStatusHeader = ({ amount, experianType, distributorName, retailerName }) => {

  return (
    <div className="application-status-header p-15">
      <div className="fs-12 clearfix w-100">
        {retailerName && (<div className="flt-lft">
            <p className="clr-blue-grey">Retailer:</p>
            <p className="font-heavy">{retailerName}</p>
          </div>
        )}
        { distributorName && (<div className="flt-rgt">
          <p className="clr-blue-grey">Distributor:</p>
          <p className="font-heavy">{distributorName}</p>
        </div>
        )}
      </div>
      <div className="m-t-15 amount-card p-25">
        <p className="clr-white algn-cntr fs-12">You are eligible for BharatCredit Limit</p>
        <p className="clr-success fs-32 font-heavy algn-cntr m-t-10">
          {
            experianType === GLOBAL_CONSTANT.EXPERIAN_STATUS.MANUAL ?
            (<div>
              <span className="fs-12 dsp-inb m-r-10">UP TO</span>
              <RupeeAmount amount={amount} />
            </div>) : 
            <RupeeAmount amount={amount} />
          }
          
        </p>
      </div>
    </div>
  );
}

ApplicationStatusHeader.propTypes = {
  amount: PropTypes.string,
  experianType: PropTypes.string,
  retailerName: PropTypes.string,
  distributorName: PropTypes.string,
};
ApplicationStatusHeader.defaultProps = {
  amount: '',
  experianType: '',
  retailerName: '',
  distributorName: '',
};

export default React.memo(ApplicationStatusHeader);
