import {  put, takeEvery } from 'redux-saga/effects';
import { EXPERIAN_CHECK } from "../actionTypes";
import { apiCall } from "../../services";
import { URL_CONSTANTS } from '../../constants/urlConstant';
import { experianCheckSuccess, experianCheckFailure } from '../actions/experian.action';
import { RouteManagerUtil } from '../../utils/routeManagerUtil';
import { GLOBAL_CONSTANT } from '../../constants/globalConstant';

function* checkExperian(actions) {

  const response = yield apiCall({
    method: 'POST',
    url: URL_CONSTANTS.EXPERIAN_CHECK
  });

  const resData = response.data;
  if (resData && resData.success){
    yield put(experianCheckSuccess(resData.data));
    // if experian checked manually then no need to show check Credit Limit page
    if (resData.data.status === GLOBAL_CONSTANT.EXPERIAN_STATUS.MANUAL) {
      RouteManagerUtil.goToNextRoute(actions.data.stepId);
    }
  } else {
    yield put(experianCheckFailure(resData));
  }
}

export default function* root() {
  yield takeEvery(EXPERIAN_CHECK, checkExperian);
}