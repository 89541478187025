import React from "react";
// LIBRARIES
import cx from "classnames";
import "./style.scss";
//UTILS
import { history, nativeSupport } from "../../utils";
// ASSETS
import backIcon from "../../assets/back-common.svg";
class Header extends React.Component {
  gotohome() {
    nativeSupport.closeWebView();
  }
  
  handleNavigate() {
    if (!this.props.stopback) {
      if (this.props.backToNative) {
        if (nativeSupport.isNative()) {
          this.gotohome();
        } else {
          history.replace("/");
        }
      } else if(this.props.routeToHome) {
        history.replace("/");
      } else {
        history.goBack();
      }
    } else {
      this.props.gotoBack();
    }
  };

  // render header extra components
  renderHeaderComponents() {
    return (this.props.components instanceof Array) ? this.props.components?.map((Val, key) => Val) : this.props.components ? this.props.components : null;
  }

  render() {
    const { props } = this;
    const {
      title,
      headerContainer,
      headerTextClass,
      subTitle,
      hideBack,
    } = props;

    return (
      <div className={cx("header-main-container", headerContainer)}>
        <div className="headr-inner-main">
          { !hideBack && (
            <div className="img-sec" onClick={() => this.handleNavigate()}>
              <img src={backIcon} alt="back_icon" />
            </div>
          )}
          <div className={cx("header-right-text", headerTextClass)}>
            <div className="header-text">{title}</div>
            { subTitle && <div className="sub-header-text fs-14">{subTitle}</div> }
          </div>
        </div>

        
        {/* Render Dynamic components in header */}
        <div className="header-components">
          {
            this.renderHeaderComponents()
          }
        </div>
      </div>
    );
  }
}

export default Header;
