import React from "react";
//COMPONENT
import Card from "../../components/Card";
import "./styles.scss";
import EligibleLoanScreenHeader from "../EligibleLoanScreenHeader";
import AppHeader from "../AppHeader";
import PropTypes from 'prop-types';

const EligibleLoanScreen = ({amount, ...props}) => {

  return (
    <div className="eligible-loan-screen">
      <AppHeader title="Credit Limit Unlocked" components={<EligibleLoanScreenHeader amount={amount} />} />
      <div className="p-18 eligible-loan-body">
        <Card containerClassName="unlock-step font-regular">
          <p className="font-heavy p-15">Unlock in 2 Step</p>
          <ul className="step-items fs-14">
            <li className="p-15 flex-both-cntr justify-start">
              <span className="icon kyc-icon m-r-10">&nbsp;</span>
              <span>Complete KYC</span>
            </li>
            <li className="p-15 flex-both-cntr justify-start">
              <span className="icon nach-icon m-r-10">&nbsp;</span>
              <span>Complete NACH</span>
            </li>
          </ul>
        </Card>
      </div>
    </div>
  );
}

EligibleLoanScreen.propTypes = {
  amount: PropTypes.string,
};
EligibleLoanScreen.defaultProps = {
  amount: '',
};

export default React.memo(EligibleLoanScreen);
