// LIBRARIES
import React from "react";
import { connect } from 'react-redux';
import "./styles.css";
import { InputDefault } from "..";
import Button from "../Button";
import { sendOtp } from '../../redux/actions/sendOtp.action';
import { verifyOtp } from '../../redux/actions/verifyOtp.action'
import { withRouter } from "react-router-dom";
import { firebase, FirebaseEventConstant } from "../../utils";

class Otp extends React.Component {

  constructor(props) {
    super(props);
    this.state ={
      otp: ''
    };
  }

  handleInputChange(key, value, maxLength) {
    if (value.length > maxLength) {
      return;
    }
    this.state[key] = value;
    this.setState({});
  }

  componentDidUpdate(prevProps) {
    // reset OTP field value on close Modal
    if (prevProps.showModal !== this.props.showModal) {
      this.setState({otp: ''});
    }
  }

  // request for OTP to server
  requestForOTP() {
    this.props.sendOtp();
    firebase.logEvent(FirebaseEventConstant.OTP_RESENT);
  }

  verifyOTP() {
    const { stepId } = this.props.match.params || {};
    this.props.verifyOtp({otp: this.state.otp, currentStep: stepId});
    firebase.logEvent(FirebaseEventConstant.OTP_VERIFY);
  }

  render() {
    const { props, state } = this;
    return (
      <div>
          <h1 className="fs-20">OTP</h1>
          <p className="m-t-15 fs-13 clr-dusk">Kindly enter 4 digit code Sent to your Mobile No. <b>{props.DraftReducer?.primary_mobile}</b></p>
          <div className="m-t-25">
            <InputDefault
              label="OTP"
              onChange={({ target }) => this.handleInputChange('otp', target.value, 4)}
              value={state.otp}
              name="otp"
              type="number"
              required
              hasLabel
              requiredField
              borderstyle={{ border: "1px solid", borderColor: "darkgray" }}
              labelstyle={{ color: "darkgray" }}
            />
          </div>

          <div className="m-t-25 otp-footer">
            <span className="clr-dark fs-12 m-r-30 p-10" onClick={() => this.requestForOTP()}>Resend OTP</span>
            <Button text="Verify" disabled={state.otp.length !== 4} style={{padding: '12px 36px'}} onClick={() => this.verifyOTP()} />
          </div>
      </div>
    );
  }
}

const mapStateToProps = ({ DraftReducer }) => ({
  DraftReducer: DraftReducer.data?.form_data
});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  sendOtp: (data) => dispatch(sendOtp(data)),
  verifyOtp: (data) => dispatch(verifyOtp(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Otp));
