import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import './LoginMobileContainer.scss';
import { Button, InputDefault } from '../../components';
import FormError from '../../components/FormError';
import useFormField from '../../hooks/useFormField';
import { VALIDATOR_CONSTANTS } from '../../constants/validatorConstant';
import { requestLoginOtp } from '../../redux/actions/loginOtp.action';
import { connect } from 'react-redux';

const LoginMobileContainer = ({ requestLoginOtp }) => {
  const [mobile, setMobile, isValid, isTouched] = useFormField('', {
    max: 10,
    regex: VALIDATOR_CONSTANTS.MOBILE,
  });

  /**
   * @function requestForOtp
   * @description request for login OTP using mobile number
   */
  const requestForOtp = useCallback(
    event => {
      const requestData = {
        phone: mobile,
      };
      requestLoginOtp(requestData);
      event.preventDefault();
    },
    [mobile]
  );

  /**
   * @function onFormFieldChange
   * @description on mobile input field change
   */
  const onFormFieldChange = useCallback(event => {
    const { value } = event.target;
    setMobile(value);
  }, []);

  return (
    <div className="login-mobile-container">
      <form onSubmit={requestForOtp}>
        <div className="mr-v-15">
          <InputDefault
            required
            hasLabel
            type="tel"
            label="Enter Retailer Mobile Number"
            requiredField
            value={mobile}
            onChange={onFormFieldChange}
          />
          <FormError
            show={!isValid && isTouched}
            defaultMsg="Mobile Number must have 10 digits"
          />
        </div>
        <Button
          type="submit"
          text="Send OTP"
          buttonClassName="w-100"
          disabled={!isValid}
        />
      </form>
    </div>
  );
};

LoginMobileContainer.propTypes = {
  requestLoginOtp: PropTypes.func,
};

LoginMobileContainer.defaultProps = {
  requestLoginOtp: () => {},
};

const mapStateToProps = null;

const mapDispatchToProps = dispatch => ({
  requestLoginOtp: (payload) => dispatch(requestLoginOtp(payload)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps)(LoginMobileContainer);
