// FRAMEWORK7 COMPONENTS
import React from 'react';
import { connect } from 'react-redux';
import "./styles.scss";
import { Button } from '../../components';
import DynamicFormField from '../../components/DynamicFormField';
import formData from '../../utils/getFormData';
import { uploadImagePending, deleteImage } from '../../redux/actions/upload-image.action';
import { postDetails } from '../../redux/actions/postDetails.action';
import { sendOtp } from '../../redux/actions/sendOtp.action';
import { fetchCityState } from '../../redux/actions/getCityState.action';
import { getInfo, updateFieldData } from '../../redux/actions/draft-data.action';
import { RouteManagerUtil } from '../../utils/routeManagerUtil';
import AppFooter from '../../components/AppFooter';
import AppHeader from '../../components/AppHeader';
import { IMAGE_UPLOAD_STATUS } from '../../components/ImageUpload';

class DynamicFormContainer extends React.Component {

  constructor(props) {
    super(props);
    this.onFormValueChange = this.onFormValueChange.bind(this);
    this.cloneFormField = this.cloneFormField.bind(this);
  };

  // submit form data
  RouteToNextpage() {
    const { stepId } = this.props.match.params || {};
    const screen = this.props.formConfig.screens[stepId];
    const finalData = formData.generateFormData(screen);
    if (finalData.isValid) {
      const requestObj = {
        ...finalData.values,
        page_index: stepId
      };
      this.props.postDetails(requestObj, () => {

        formData.indexCountObj = {};
        // go for running next task
        RouteManagerUtil.goToNext({currentStep: stepId});
        // call getInfo so that draft reducer sync with server
        this.props.getInfo({}, undefined, {defaultLoader: false});
      });
    } else {
      this.setState({});
    }
  }

  onFormValueChange(type, fieldData, parent, callFor, callIndex) {

    switch (type) {
      case 'FILE':
        if (callFor === 'upload') {
          if (fieldData.isValid) {
            const { stepId } = this.props.match.params || {};
            const requestObj = {
              name: fieldData.column_input_name,
              image: fieldData.imageData,
              page_index: stepId,
            };
        
            this.props.uploadImagePending(requestObj, (status, data) => {
              if (status === true) {
                fieldData.image_id = data.image_id;
                fieldData.uploadStatus = IMAGE_UPLOAD_STATUS.SUCCESS;
                this.setState({});

                // update Draft reducer locally
                this.props.updateFieldData({
                  type: 'ADD_IMAGE',
                  imageUrl: fieldData.value,
                  image_id: fieldData.image_id,
                  fieldName: fieldData.column_input_name
                });
              } else {
                fieldData.value = '';
                fieldData.uploadStatus = IMAGE_UPLOAD_STATUS.FAILED;
                this.setState({});
              }
            });
          }
        } else {
          const requestObj = {
            image_id: fieldData.image_id
          };
          this.props.deleteImage(requestObj, (status) => {
            if (status === true) {

              // update Draft reducer locally
              this.props.updateFieldData({
                type: 'REMOVE_IMAGE',
                fieldName: fieldData.column_input_name,
                image_id: fieldData.image_id
              });

              // filter out is it last item
              let commonKeyFields = parent.filter((val) => {
                return val.column_input_name === fieldData.column_input_name;
              });

              if (commonKeyFields.length > 1 && fieldData.column_input_name !== 'director_kyc' && fieldData.column_input_name !== 'partnership_kyc') {
                if ((commonKeyFields.length - 1) === callIndex) {
                  commonKeyFields[commonKeyFields.length - 2].showAddMore = true;
                } else {
                  commonKeyFields[commonKeyFields.length - 1].showAddMore = true;
                }

                // decrease index by 1 after callIndex items
                parent.forEach((val, index) => {
                  if (index > callIndex) {
                    val.imageIndex = val.imageIndex - 1;
                  }
                });

                this.cloneFormField(callIndex, parent, fieldData, true);
              } else {
                fieldData.value = '';
                delete fieldData.imageData;
                delete fieldData.image_id;
                fieldData.uploadStatus = IMAGE_UPLOAD_STATUS.INIT;
                fieldData.showAddMore = true;
                this.setState({});
              }
            }
          });
        }
        break;
      case 'TEXT':
        if (fieldData.column_input_name === 'business_pincode' || fieldData.column_input_name === 'self_pincode') {
          if (fieldData.isValid) {
            const requestObj = {
              pincode: fieldData.value
            };

            let state = '';
            let city = '';
            this.props.fetchCityState(requestObj, (status, data) => {
              if (status === true) {
                state = data.state;
                city = data.city;
              }
              this.updateCityState(fieldData.column_input_name, parent, city, state);
              this.setState({});
            });
          }
        }
        break;
      default:
      // nothing for default
    }
  }

  /**
   * @function updateCityState
   * @description update city, state field value throw PIN code
   */
  updateCityState(columnName, parent, city, state) {
    // update into form field
    if (columnName === 'business_pincode') {
      for (const val of parent) {
        if (val.column_input_name === 'business_state') {
          val.value = state;
        }
        if (val.column_input_name === 'business_city') {
          val.value = city;
        }
      }
    } else if (columnName === 'self_pincode') {
      for (const val of parent) {
        if (val.column_input_name === 'self_state') {
          val.value = state;
        }
        if (val.column_input_name === 'self_city') {
          val.value = city;
        }
      }
    }
  }

  /**
   * @function cloneFormField
   * @description clone same form field to the next of index
   */
  cloneFormField(index, parent, data, isRemove) {
    if (parent) {
      delete formData.indexCountObj[data.column_input_name];
      if (isRemove) {
        parent.splice(index, 1);
        this.setState({});
        return;
      }

      const cloneData = JSON.parse(JSON.stringify(data));
      cloneData.value = '';
      delete cloneData.imageData;
      delete cloneData.image_id;
      if (data.column_type === 'FILE') {
        cloneData.imageIndex = data.imageIndex + 1;
        
        data.showAddMore = false;
        cloneData.showAddMore = true;
      }
      parent.splice(index, 0, cloneData);
      this.setState({});
    } else {
      console.error('parent not found!');
    }
  }

  render() {
    const { props, state } = this;
    const { stepId } = props.match.params || {};
    const screen = props.formConfig.screens[stepId];
    const fields = formData.formatFieldsData(screen.fields, props.DraftReducer);
    return (
      <div className="dynamic-form-container">
        <AppHeader />

        <div className="dynamic-form-body">
          {
            fields.map((val, index) => {
              return (
                <DynamicFormField
                  key={`${val.column_input_name}-${index}-${fields.length}`}
                  type={val.column_type}
                  data={val}
                  parent={fields}
                  index={index}
                  draftReducer={props.DraftReducer}
                  onFormValueChange={this.onFormValueChange}
                  cloneFormField={this.cloneFormField}
                  dependencies={screen.dependencies && formData.formatDependencies(screen.dependencies, val.column_input_name, val)}
                />
              );
            })
          }
        </div>

        <AppFooter>
          <Button
            buttonClassName="w-100"
            text="Submit"
            onClick={() => this.RouteToNextpage()}
          />
        </AppFooter>
      </div>
    )
  }
}

const mapStateToProps = ({
  FormConfigReducer,
  DraftReducer,
  RouteConfigReducer
}) => ({
  formConfig: FormConfigReducer.data,
  DraftReducer: DraftReducer.data?.form_data,
  tnc: DraftReducer.data?.tnc,
  config: RouteConfigReducer.data
});

const mapDispatchToProps = dispatch => ({
  dispatch,
  uploadImagePending: (data, callback) => dispatch(uploadImagePending(data, callback)),
  updateFieldData: (data) => dispatch(updateFieldData(data)),
  deleteImage: (data, callback) => dispatch(deleteImage(data, callback)),
  postDetails: (data, callback) => dispatch(postDetails(data, callback)),
  sendOtp: (data, callback) => dispatch(sendOtp(data, callback)),
  fetchCityState: (data, callback) => dispatch(fetchCityState(data, callback)),
  getInfo: (data, callback, options) => dispatch(getInfo(data, callback, options)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps)(DynamicFormContainer);
