import {  put, takeEvery } from 'redux-saga/effects';
import { ADD_BUSINESS_DETAILS } from "../actionTypes";
import { apiCall } from "../../services";
import { URL_CONSTANTS } from '../../constants/urlConstant';
import { updateForm1DraftData } from '../actions/draft-data.action';
import { history } from '../../utils';

function* addBusinessDetails(actions) {

  const params = {...actions.data};
  // below property not send to sever
  delete params.mobile;
  
  const formData = yield apiCall({
    method: 'POST',
    url: URL_CONSTANTS.ADD_BUSINESS_DETAILS,
    data: {
      params
    }
  });

  if (formData.data && formData.data.success){
    yield put(updateForm1DraftData(actions.data));
    history.push("/upload-documents");
  }
}

export default function* root() {
  yield takeEvery(ADD_BUSINESS_DETAILS, addBusinessDetails);
}