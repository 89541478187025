let StorageMap = {};

const strToJson = (str) => {
    try {
        return JSON.parse(str);
    } catch (e) {
        return str;
    }
}

const get = (key) => {
    const value = StorageMap[key] || window.localStorage.getItem(key);
    const jsonValue = strToJson(value);
    return jsonValue;
}


const set = (key, value) => {
    let stringVal = '';
    if (typeof value === 'object') {
        stringVal = JSON.stringify(value);
    }
    try {
        window.localStorage.setItem(key, stringVal);
    } catch (e) {
        StorageMap[key] = stringVal;
    }
}

export default { get, set };
