import { REQUEST_LOGIN_OTP_ACTION, REQUEST_LOGIN_OTP_ACTION_SUCCESS, REQUEST_LOGIN_OTP_ACTION_FAILURE, REQUEST_LOGIN_OTP_ACTION_RESET } from "../actionTypes";

export const requestLoginOtp = payload => {
  return {
    type: REQUEST_LOGIN_OTP_ACTION,
    payload,
  };
};

export const requestLoginOtpSuccess = data => {
  return {
    type: REQUEST_LOGIN_OTP_ACTION_SUCCESS,
    data,
  };
};

export const requestLoginOtpFailure = error => {
  return {
    type: REQUEST_LOGIN_OTP_ACTION_FAILURE,
    error,
  };
};

export const requestLoginOtpReset = () => {
  return {
    type: REQUEST_LOGIN_OTP_ACTION_RESET,
  };
};
