import { EXPERIAN_CHECK, EXPERIAN_CHECK_SUCCESS, EXPERIAN_CHECK_FAILURE } from "../actionTypes";

export function checkExperian(data) {
  return {
    type: EXPERIAN_CHECK,
    data
  };
}

export function experianCheckSuccess(data) {
  return {
    type: EXPERIAN_CHECK_SUCCESS,
    data
  };
}

export function experianCheckFailure(data) {
  return {
    type: EXPERIAN_CHECK_FAILURE,
    data
  };
}