//Loader
export const SET_LOADING_STATUS = "LOADER/SET_LOADING_STATUS";
export const REMOVE_LOADING_STATUS = "LOADER/REMOVE_LOADING_STATUS";

// get Info
export const GET_INFO = 'INFO/GET_INFO';

// form config actions
export const GET_FORM_CONFIG_DATA = 'FORM/GET_CONFIG';
export const SET_FORM_CONFIG_DATA = 'FORM/SET_CONFIG';
export const RESET_FORM_CONFIG_DATA = 'FORM/RESET_CONFIG';

// route onfig actions
export const SET_ROUTE_CONFIG_DATA = 'ROUTE/SET_ROUTE_CONFIG';

// Draft actions
export const UPDATE_DRAFT_DATA = 'DRAFT/UPDATE_DRAFT_DATA';
export const UPDATE_FORM1_DRAFT_DATA = 'DRAFT/UPDATE_FORM1_DRAFT_DATA';
export const UPDATE_FORM2_DRAFT_DATA = 'DRAFT/UPDATE_FORM1_DRAFT_DATA';
export const RESET_DRAFT_DATA = "DRAFT/RESET_DRAFT_DATA";
export const UPDATE_FORM2_IMAGE_DRAFT_DATA = 'DRAFT/UPDATE_FORM2_IMAGE_DRAFT_DATA';
export const UPDATE_FIELD_DATA = 'DRAFT/UPDATE_FIELD_DATA';

//Add Business Details
export const ADD_BUSINESS_DETAILS = "DATA/ADD_BUSINESS_DETAILS";
export const ADD_BUSINESS_DETAILS_SUCCESS = "DATA/ADD_BUSINESS_DETAILS_SUCCESS";

// post form Details
export const POST_FORM_DETAILS = "FORM/POST_DETAILS";

// get city state from pin code
export const GET_CITY_STATE = "CITY_STATE/GET";

//Send OTP
export const SEND_OTP = "OTP/SEND_OTP";

//Verify OTP
export const VERIFY_OTP = "VERIFY/VERIFY_OTP";

// show OTP modal
export const SHOW_OTP_MODAL_SUCCESS = "OTP/SHOW_OTP_MODAL_SUCCESS";
export const SHOW_OTP_MODAL_FAILURE = "OTP/SHOW_OTP_MODAL_FAILURE";

// Upload Images
export const UPLOAD_IMAGE = 'UPLOAD/UPLOAD_IMAGE';

// delete Images
export const DELETE_IMAGE = 'DELETE/DELETE_IMAGE';

// experian check action
export const EXPERIAN_CHECK = 'EXPERIAN/CHECK';
export const EXPERIAN_CHECK_SUCCESS = 'EXPERIAN/CHECK_SUCCESS';
export const EXPERIAN_CHECK_FAILURE = 'EXPERIAN/CHECK_FAILUIRE';

// login Otp actions
export const REQUEST_LOGIN_OTP_ACTION = 'REQUEST_LOGIN_OTP_ACTION';
export const REQUEST_LOGIN_OTP_ACTION_SUCCESS =
  'REQUEST_LOGIN_OTP_ACTION_SUCCESS';
export const REQUEST_LOGIN_OTP_ACTION_FAILURE =
  'REQUEST_LOGIN_OTP_ACTION_FAILURE';
export const REQUEST_LOGIN_OTP_ACTION_RESET = 'REQUEST_LOGIN_OTP_ACTION_RESET';

// verify login Otp actions
export const VERIFY_LOGIN_OTP_ACTION = 'VERIFY_LOGIN_OTP_ACTION';
export const VERIFY_LOGIN_OTP_ACTION_SUCCESS =
  'VERIFY_LOGIN_OTP_ACTION_SUCCESS';
export const VERIFY_LOGIN_OTP_ACTION_FAILURE =
  'VERIFY_LOGIN_OTP_ACTION_FAILURE';
export const VERIFY_LOGIN_OTP_ACTION_RESET = 'VERIFY_LOGIN_OTP_ACTION_RESET';
