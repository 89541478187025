import React, { useEffect } from 'react';
import './styles.scss';
import AppHeader from '../../components/AppHeader';
import { firebase, FirebaseEventConstant } from '../../utils';

export const User401 = () => {
    useEffect(() => {
        firebase.logEvent(FirebaseEventConstant.USER_401);
    }, []);
    
    return (
        <div className="user-401">
            <AppHeader backToNative/>
            <div className="flex-both-cntr user-401-body">
                <div>
                    <h1>You are not authorized to access this app</h1>
                    {/* <span className="clr-primary underline" onClick={() => {history.replace('/')}}>Go to Home</span> */}
                </div>
            </div>
        </div>
    )
}