import React from "react";
//COMPONENT
import Card from "../../components/Card";
import "./styles.scss";
import Button from "../../components/Button";
import { GLOBAL_CONSTANT } from "../../constants/globalConstant";
import getMobileOperatingSystem from "../../utils/platformDetection";
import AppHeader from "../../components/AppHeader";
import { nativeSupport } from "../../utils";

class DocumentSubmitted extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      downloadUrl: (getMobileOperatingSystem() === 'iOS') ? GLOBAL_CONSTANT.IOS_APP_URL : GLOBAL_CONSTANT.ANDROID_APP_URL
    }
  }


  render() {
    return (
      <div className="doc-submitted-container">
        <AppHeader noTitle backToNative />
        <div className="pa3 doc-submitted flex-both-cntr algn-cntr">
          <Card containerClassName="w-100">
            <h2 className="clr-primary font-heavy fs-18">Thank You for submitting<br/>your details!</h2>
            { !nativeSupport.isNative() && <div>
                <h2 className="font-heavy fs-12 mr-v-15">Download BharatPe app to boost your chances<br/>of getting a loan.</h2>
                <a href={this.state.downloadUrl}>
                  <Button text="Download Now" />
                </a>
              </div>
            }
          </Card>
        </div>
      </div>
    );
  }
}

export default DocumentSubmitted;
