import {  put, takeEvery } from 'redux-saga/effects';
import { GET_CITY_STATE } from "../actionTypes";
import { apiCall } from "../../services";
import { URL_CONSTANTS } from '../../constants/urlConstant';
import { history } from '../../utils';

function* fetchCityState(actions) {

  const params = {...actions.data};
  
  const response = yield apiCall({
    method: 'POST',
    url: URL_CONSTANTS.FETCH_CITY_STATE,
    data: {
      params
    },
    options: {
      defaultLoader: false
    }
  });
  if (response.data && response.data.success){
    actions.callback && actions.callback(true, response.data.data);
  } else {
    actions.callback && actions.callback(false);
  }
}

export default function* root() {
  yield takeEvery(GET_CITY_STATE, fetchCityState);
}