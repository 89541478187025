import React from 'react';
// LIBRARIES
import cx from 'classnames';
// STYLES
import './styles.css';

const loaderGIF = require('../../assets/icons/loader/loader.gif');

const Full = ({ isVisible, text }) => (
  <div
    className={cx('loader__container loader--full', {
      'is-visible': isVisible
    })}
  >
    <div className="loader__background">
      <img src={loaderGIF} alt="" className="loader__element" />
      {text && <p className="loader__text">{text}</p>}
    </div>

    <div className="loader__overlay" />
  </div>
);

Full.defaultProps = {
  isVisible: false,
  text: null
};
export default Full;
