import React from 'react';
import PropTypes from 'prop-types';
import './styles.scss';

const DotLoader = () => {
  return (
    <div className="dot-loader">
      <span>&nbsp;</span>
      <span>&nbsp;</span>
      <span>&nbsp;</span>
    </div>
  );
}

DotLoader.propTypes = {
  show: PropTypes.bool,
}

DotLoader.defaultProps = {
  show: false,
}

export default DotLoader;
