import { SHOW_OTP_MODAL_SUCCESS, SHOW_OTP_MODAL_FAILURE } from "../actionTypes";

const initialState = {
  data: {
    show: false,
  }
};

const OtpModalReducer = (state = initialState, action) => {
  switch (action.type) {
    case SHOW_OTP_MODAL_SUCCESS:
      return { ...state, data: {show: true} };
    case SHOW_OTP_MODAL_FAILURE:
      return { ...state, data: {show: false} };
    default:
      return {...state};
  }
};

export default OtpModalReducer;
  