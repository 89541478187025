import React from "react";
import "./styles.scss";
import EligibleLoanScreen from "../../components/EligibleLoanScreen";
import NotEligibleLoanScreen from "../../components/NotEligibleLoanScreen";
import { Button } from "../../components";
import AppFooter from "../../components/AppFooter";
import { withRouter } from "react-router-dom";
import { RouteManagerUtil } from "../../utils/routeManagerUtil";
import { connect } from "react-redux";
import { checkExperian } from "../../redux/actions/experian.action";
import { GLOBAL_CONSTANT } from "../../constants/globalConstant";

class LoanEligibility extends React.Component {
  
  componentDidMount() {
    const { stepId } = this.props.match.params || {};
    this.props.checkExperian({stepId});
  }

  goToNext() {
    const { stepId } = this.props.match.params || {};
    RouteManagerUtil.goToNext({currentStep: stepId});
  }

  render() {
    const { experianReducer } = this.props;
    return (
      <div className="loan-eligibility">
        { experianReducer.status &&
          (experianReducer.status === GLOBAL_CONSTANT.EXPERIAN_STATUS.SUCCESS ?
            <EligibleLoanScreen amount={experianReducer.amount} /> :
            <NotEligibleLoanScreen />
          )
        }

        <AppFooter>
          <Button text="Proceed To KYC" buttonClassName="w-100" onClick={() => this.goToNext()} />
        </AppFooter>
      </div>
    );
  }
}

const mapStateToProps = ({
  ExperianCheckReducer,
}) => ({
  experianReducer: ExperianCheckReducer.data,
});

const mapDispatchToProps = dispatch => ({
  dispatch,
  checkExperian: (data) => dispatch(checkExperian(data))
});
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(LoanEligibility));
