import React, { Component } from "react";
import "./styles.scss";
// library
import cx from "classnames";

export class Footer extends Component {
  render() {
    const { props } = this;
    const { containerClassName, ...others } = props;
    return (
      <div
        className={cx("footer-section", containerClassName)}
      >
        {props.children}
      </div>
    );
  }
}

export default Footer;
