import React, { useRef, useEffect } from "react";
import "./styles.scss";
import greenCheck from '../../assets/green-check.png';
import RupeeAmount from "../RupeeAmount";
import ConfettiGenerator from "confetti-js";
import PropTypes from 'prop-types';

const EligibleLoanScreenHeader = ({amount}) => {

  const confettiElemRef = useRef();

  /**
   * @description show confetti on header
   */
  useEffect(() => {
    const confettiSettings = { target: confettiElemRef.current, width: 310, height: 200, max: 25};
    const confetti = new ConfettiGenerator(confettiSettings);
    confetti.render();
    return () => {
      confetti.clear();
    }
  }, []);

  return (
    <div className="eligible-loan-header p-15 pos-rel">
      <canvas ref={confettiElemRef} className="loan-eligible-confetti pos-abs"></canvas>
      <div>
        <div className="flex-both-cntr flex-wrap">
          <img src={greenCheck} alt="icon" className="congo-icon" />
          <p className="clr-white fs-18 font-heavy w-100 algn-cntr m-t-10">Congratulations</p>
        </div>
        <div className="m-t-15">
          <p className="clr-white algn-cntr fs-12">You are eligible for BharatCredit Limit</p>
          <p className="clr-success fs-32 font-heavy algn-cntr">
            <RupeeAmount amount={amount} />
          </p>
        </div>
      </div>

      <div className="eligible-loan-footer clr-white algn-cntr fs-12 p-t-15 m-t-15">
        <p>Now, Pay for your orders via BharatCredit Limit Option</p>
      </div>
    </div>
  );
}

EligibleLoanScreenHeader.propTypes = {
  amount: PropTypes.string,
};
EligibleLoanScreenHeader.defaultProps = {
  amount: '',
};

export default React.memo(EligibleLoanScreenHeader);
