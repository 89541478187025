import { all } from "redux-saga/effects";
import addBusinessDetails from "./addBusinessDetails.saga";
import getInfo from './get-info.saga';
import sendOtp from "./sendOtp.saga";
import verifyOtp from "./verifyOtp.saga";
import uploadImage from './upload-image.saga';
import getFormConfig from './getFormConfig.saga';
import postDetails from './postDetails.saga';
import fetchCityState from './getCityState.saga';
import deleteImage from './delete-image.saga';
import checkExperian from "./checkExperian.saga";
import { getRequestLoginOtp } from "./loginOtp.saga";
import { getVerifyLoginOtpSaga } from "./verifyLoginOtp.saga";
import { loadResendLoginOtpSaga } from "../../hoc/ResendLoginOtp/saga";

export default function* root() {
    yield all([
        addBusinessDetails(),
        getInfo(),
        sendOtp(),
        verifyOtp(),
        uploadImage(),
        getFormConfig(),
        postDetails(),
        fetchCityState(),
        deleteImage(),
        checkExperian(),
        getRequestLoginOtp(),
        getVerifyLoginOtpSaga(),
        loadResendLoginOtpSaga(),
    ]);
  }
