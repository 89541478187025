import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { createStructuredSelector } from 'reselect';
import { selectResendOtpData } from './selectors';
import { resendOtpDataAction, resetResendOtpAction } from './actions';

/**
 * @function otpVerify
 * @param {Component} WrappedComponent
 * @description resend OTP to server
 */
function resendLoginOtp(WrappedComponent) {
  const ResendLoginOtpWrapped = ({
    resendOtpData,
    requestToResendOtp,
    resetResendOtpData,
    ...props
  }) => {
    return (
      <WrappedComponent
        resendOtpData={resendOtpData}
        requestToResendOtp={requestToResendOtp}
        resetResendOtpData={resetResendOtpData}
        {...props}
      />
    );
  };

  const mapStateToProps = createStructuredSelector({
    resendOtpData: selectResendOtpData,
  });

  const mapDispatchToProps = dispatch => {
    return {
      requestToResendOtp: payload => {
        dispatch(resendOtpDataAction(payload));
      },
      resetResendOtpData: () => {
        dispatch(resetResendOtpAction());
      },
    };
  };

  ResendLoginOtpWrapped.propTypes = {
    resendOtpData: PropTypes.object,
    requestToResendOtp: PropTypes.func,
    resetResendOtpData: PropTypes.func,
  };

  ResendLoginOtpWrapped.defaultProps = {
    resendOtpData: {},
    requestToResendOtp: () => {},
    resetResendOtpData: () => {},
  };

  return connect(mapStateToProps, mapDispatchToProps)(ResendLoginOtpWrapped);
}

export default resendLoginOtp;
