import { UPDATE_DRAFT_DATA, UPDATE_FORM1_DRAFT_DATA, UPDATE_FORM2_DRAFT_DATA, RESET_DRAFT_DATA, GET_INFO, UPDATE_FORM2_IMAGE_DRAFT_DATA, UPDATE_FIELD_DATA } from "../actionTypes";

export function getInfo(data = {}, callback = () => {}, options={}) {
  return {
    type: GET_INFO,
    data,
    callback,
    options,
  }
}

// update complete draft data
export function updateDraftData(data) {
  return {
    type: UPDATE_DRAFT_DATA,
    data
  }
}

// update field data
export function updateFieldData(data) {
  return {
    type: UPDATE_FIELD_DATA,
    data
  }
}

// update only form1 draft data
export function updateForm1DraftData(form1) {
  return {
    type: UPDATE_FORM1_DRAFT_DATA,
    form1
  }
}

// update only from2 draft data
export function updateForm2DraftData(form2) {
  return {
    type: UPDATE_FORM2_DRAFT_DATA,
    form2
  }
}

// update from2 image data
export function updateForm2ImageDraftData(imageData) {
  return {
    type: UPDATE_FORM2_IMAGE_DRAFT_DATA,
    imageData
  }
}

// reset complete draft data
export function resetDraftData() {
  return {
    type: RESET_DRAFT_DATA
  }
}
