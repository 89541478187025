// FRAMEWORK7 COMPONENTS
import React, { useCallback, useEffect, useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import InputDefault from '../Input';
import DateInput from '../DateInput';
import Dropdown from '../Dropdown';
import FancyRadioGroup from '../FancyRadioGroup';
import { ValidationV2 } from '../../utils/ValidationV2';
import FormError from '../FormError';
import moment from 'moment';
import ImageUpload, { IMAGE_UPLOAD_STATUS } from '../ImageUpload';
import './styles.scss';
import formData from '../../utils/getFormData';
import { GLOBAL_CONSTANT } from '../../constants/globalConstant';

const DynamicFormField = ({type, data, dependencies, onFormValueChange, index, parent, draftReducer, cloneFormField, ...props}) => {

  const [isValid, setIsValid] = useState(true);
  const [validationType, setValidationType] = useState('');
  const [validationValue, setValidationValue] = useState('');
  const [hasChild, setHasChild] = useState(false);
  const [childFields, setChildFields] = useState({});
  const [value, setValue] = useState('');
  const [isRequired, setIsRequired] = useState(false);
  const [showAddMore, setShowAddMore] = useState(() => {
    return (dependencies && (data.imageIndex < dependencies.length - 1) && data.showAddMore) || false;
  });
  const [imageUploadStatus, setImageUploadStatus] = useState(IMAGE_UPLOAD_STATUS.INIT);

  /**
   * @function checkValidation
   * @description check form field validation according to value
   */
  const checkValidation = useCallback(() => {
    const {isValid, validationType, validationValue} = ValidationV2.checkValidation(data.value, data.validation_rule, data.column_label, data.column_type);
    data.isValid = isValid;
    setIsValid(isValid);
    setValidationType(validationType);
    setValidationValue(validationValue);
  }, [data]);

  /**
   * @function updateChilds
   * @description inisilized childs to the relative field
   */
  const updateChilds = useCallback((val) => {
    if (dependencies) {
      const childField = dependencies instanceof Array && dependencies.find((childVal) => {
        return childVal.key === val;
      });

      if (childField && childField.child_fields) {
        const fields = formData.formatFieldsData(childField.child_fields.fields, draftReducer);
        childField.child_fields.fields = fields;
        setChildFields(childField.child_fields);
        setHasChild(true);
      } else {
        setHasChild(false);
      }
    }
  }, [dependencies]);

  /**
   * @function onChangeFormField
   * @description update particular object data on form field change event
   */
  const onChangeFormField = useCallback((val, data, callFor) => {
    data.value = val;
    setValue(val);
    checkValidation();
    
    updateChilds(val);

    // file upload code here
    onFormValueChange(type, data, parent, callFor);
  }, [checkValidation, type, onFormValueChange, parent, updateChilds]);

  /**
   * @description add isValid and value property in data object
   */
  useEffect(() => {
    data.isValid = true;

    const columnName = data.column_input_name;
    if (type === 'FILE') {
      formData.indexCountObj[columnName] = (formData.indexCountObj[columnName] === undefined) ? 0 : (formData.indexCountObj[columnName]) + 1;
      const imageDraft = draftReducer[columnName] && draftReducer[columnName][formData.indexCountObj[columnName]];

      if (imageDraft) {
        data.value = imageDraft.link || '';
        data.image_id = imageDraft.id || -1;
        data.imageIndex = formData.indexCountObj[columnName];
        data.uploadStatus = IMAGE_UPLOAD_STATUS.SUCCESS;
      } else {
        data.imageIndex = data.imageIndex || 0;
        data.uploadStatus = IMAGE_UPLOAD_STATUS.INIT;
      }

      // mark showAddMore false except last
      const commonKeyFields = parent.filter((val) => {
        return val.column_input_name === data.column_input_name;
      });
      commonKeyFields.forEach((val, index) => {
        if (index === (commonKeyFields.length - 1) && dependencies && val.imageIndex < dependencies.length - 1) {
          val.showAddMore = true;
        } else {
          val.showAddMore= false;
        }
      })
    } else {
      if (draftReducer[columnName] instanceof Array) {
        formData.indexCountObj[columnName] = (formData.indexCountObj[columnName] === undefined) ? 0 : (formData.indexCountObj[columnName]) + 1;
        data.value = draftReducer[columnName][formData.indexCountObj[columnName]];
      } else {
        data.value = draftReducer[columnName];
      }
    }
    
    setIsValid(data.isValid);

    updateChilds(data.value);

    // set isRequired field or not
    if (data.validation_rule) {
      const { required } = ValidationV2.getValidationRulesJson(data.validation_rule);
      setIsRequired(required?.toLowerCase() === 'true');
    }

    return () => {
      if (!formData.indexCountObj[columnName]) {
        delete formData.indexCountObj[columnName];
      } else {
        formData.indexCountObj[columnName] = formData.indexCountObj[columnName] - 1;
      }
    }
  }, []);

  useEffect(() => {
    setShowAddMore(data.showAddMore);
  }, [data.showAddMore]);

  useEffect(() => {
    setImageUploadStatus(data.uploadStatus);
  }, [data.uploadStatus]);

  /**
   * @description check is Valid or not on submit button clicked and show validation error message
   */
  useEffect(() => {
    if (!data.isValid) {
      checkValidation();
    }
  }, [data.isValid, checkValidation])

  /**
   * @description check is Valid or not on submit button clicked and show validation error message
   */
  useEffect(() => {
    setValue(data.value);
  }, [data.value])

  /**
   * @function fetchImages
   * @description file upload update
   */
  const fetchImages = useCallback((imageData, image) => {
    data.imageData = image.imageUrl;
    data.uploadStatus = IMAGE_UPLOAD_STATUS.PENDING;
    setImageUploadStatus(data.uploadStatus);
    onChangeFormField(image.imageName, data, 'upload');
  }, [data, onChangeFormField]);

  /**
   * @function deleteImage
   * @description delete uploaded file
   */
  const deleteImage = useCallback((index) => {
    onFormValueChange(type, data, parent, 'delete', index);
  }, [data, onFormValueChange, type, parent]);

  /**
   * @function getComponentByType
   * @description return form component by type
   */
  const getComponentByType = useCallback((type, data, dependencies, index) => {
    let InputComponent = null;
    const { FORM_FIELD_TYPES } = GLOBAL_CONSTANT;
    switch (type) {
      case FORM_FIELD_TYPES.TEXT:
        InputComponent = <InputDefault
              type="text"
              label={data.column_label}
              name={data.column_input_name}
              value={value}
              readOnly={data.editable !== 'TRUE'}
              onFormValueChange={(val) => onChangeFormField(val, data)}
              required
              requiredField={isRequired}
              hasLabel
              keyboardType={data.keyboard_type}
            />
        break;
      case FORM_FIELD_TYPES.NUMBER:
        InputComponent = <InputDefault
          type="number"
          label={data.column_label}
          name={data.column_input_name}
          value={value}
          readOnly={data.editable !== 'TRUE'}
          onFormValueChange={(val) => onChangeFormField(val, data)}
          required
          requiredField={isRequired}
          hasLabel
        />;
        break;
      case FORM_FIELD_TYPES.TEL:
        InputComponent = <InputDefault
          type="tel"
          label={data.column_label}
          name={data.column_input_name}
          value={value}
          readOnly={data.editable !== 'TRUE'}
          onFormValueChange={(val) => onChangeFormField(val, data)}
          required
          requiredField={isRequired}
          hasLabel
        />;
        break;
      case FORM_FIELD_TYPES.RADIO:
        InputComponent = <FancyRadioGroup
          options={dependencies || []}
          selectedValue={value}
          onFormValueChange={(val) => onChangeFormField(val, data)}
        />
        break;
      case FORM_FIELD_TYPES.DATE:
        InputComponent = <DateInput
          label={data.column_label}
          name={data.column_input_name}
          value={value && new Date(value)}
          onFormValueChange={(val) => { val = moment(val).format('YYYY-MM-DD'); onChangeFormField(val, data) }}
          requiredField={isRequired}
        />;
        break;
      case FORM_FIELD_TYPES.DROPDOWN:
        InputComponent = <Dropdown
          options={dependencies || []}
          labelColor
          selected={value}
          label={data.column_label}
          onFormValueChange={(val) => onChangeFormField(val, data)}
          requiredField={isRequired}
        />
        break;
      case FORM_FIELD_TYPES.FILE:
        InputComponent = (
          <div>
            <ImageUpload
              key={data.column_input_name}
              label={data.column_label}
              data={{ name: data.column_input_name, image: data.imageData || value }}
              sendImages={fetchImages}
              deleteImage={() => deleteImage(index)}
              requiredField={isRequired}
              uploadStatus={imageUploadStatus}
            />
            {data.column_input_name !== 'director_kyc' && data.column_input_name !== 'partnership_kyc' && dependencies && dependencies.length > 1 && showAddMore && <div className="clr-primary fs-11 font-heavy m-t-10" onClick={() => cloneFormField(index + 1, parent, data)}>+ Add Another Image</div>}
          </div>
        )
        break;
      default:
        // no case for default
    }

    return (
      <>
        <div className="first-inp">
          { InputComponent }
        </div>

        {/* Check if Child available then initilized  */}
        {
          hasChild && childFields.fields.map((val, key) => {
            // const childField = childFields.fields[val];
            return <DynamicFormField
              key={`${val.column_input_name}-${key}-${childFields.fields.length}`}
              type={val.column_type}
              data={val}
              parent={childFields.fields}
              index={key}
              draftReducer={draftReducer}
              onFormValueChange={onFormValueChange}
              cloneFormField={cloneFormField}
              dependencies={formData.formatDependencies(childFields.dependencies, val.column_input_name, val)}
            />
          })
        }
      </>
    )
  }, [onChangeFormField, fetchImages, deleteImage, hasChild, childFields, value, onFormValueChange, draftReducer, parent, cloneFormField, isRequired]);

  return (
    <div className="form-field pos-rel">
      { getComponentByType(type, data, dependencies, index) }
      <FormError show={!isValid} type={validationType} subString={validationValue} inputType={type} />
    </div>
  )
}

DynamicFormField.propTypes = {
  type: PropTypes.string,
  data: PropTypes.any,
  parent: PropTypes.any,
  index: PropTypes.number,
  draftReducer: PropTypes.any,
  dependencies: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array
  ]),
  cloneFormField: PropTypes.func,
  onFormValueChange: PropTypes.func
};

DynamicFormField.defaultProps = {
  type: 'TEXT',
  data: {},
  parent: {},
  index: -1,
  draftReducer: {},
  dependencies: [],
  cloneFormField: () => {},
  onFormValueChange: () => {}
};

export default DynamicFormField;
