import { takeLatest, all, put } from 'redux-saga/effects';
import { apiCall } from "../../services";
import { URL_CONSTANTS } from '../../constants/urlConstant';
import { VERIFY_LOGIN_OTP_ACTION } from '../actionTypes';
import { verifyLoginOtpActionSuccess, verifyLoginOtpActionFailure } from '../actions/verifyLoginOtp.action';

function* verifyLoginOtpSaga(action) {
  try {
    const response = yield apiCall({
      method: 'POST',
      url: URL_CONSTANTS.VERIFY_LOGIN_OTP_URL,
      data: action.payload,
      options: {
        allowHeaderToken: true,
        useProxyServer: true,
      }
    });

    const resData = response.data;
    if (resData && resData.success === true) {
      yield put(verifyLoginOtpActionSuccess(resData.data));
      return;
    }
    yield put(verifyLoginOtpActionFailure(resData));
  } catch (err) {
    yield put(verifyLoginOtpActionFailure(err));
  }
}

export function* getVerifyLoginOtpSaga() {
  yield all([takeLatest(VERIFY_LOGIN_OTP_ACTION, verifyLoginOtpSaga)]);
}
