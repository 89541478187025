import { put, takeLatest, all } from 'redux-saga/effects';
import { apiCall } from "../../services";
import { toast } from 'react-toastify';
import { URL_CONSTANTS } from '../../constants/urlConstant';
import { requestLoginOtpSuccess, requestLoginOtpFailure } from '../actions/loginOtp.action';
import { REQUEST_LOGIN_OTP_ACTION } from '../actionTypes';
import { MESSAGE_CONSTANT } from '../../constants/msgConstant';

function* requestLoginOtp(action) {
  try {
    const response = yield apiCall({
      method: 'POST',
      url: URL_CONSTANTS.REQUEST_LOGIN_OTP_URL,
      data: action.payload,
      options: {
        allowHeaderToken: true,
        useProxyServer: true,
      }
    });

    const resData = {
      ...response.data?.data,
      ...action.payload,
    };
    if (response.data && response.data.success) {
      yield put(requestLoginOtpSuccess(resData));
      toast.success(MESSAGE_CONSTANT.OTP_SENT);
      return;
    }

    yield put(requestLoginOtpFailure(resData));
  } catch (err) {
    yield put(requestLoginOtpFailure(err));
  }
}

export function* getRequestLoginOtp() {
  yield all([takeLatest(REQUEST_LOGIN_OTP_ACTION, requestLoginOtp)]);
}
