import { SET_ROUTE_CONFIG_DATA } from "../actionTypes";


// set data to Route Config reducer
export function setRouteConfigData(data) {
  return {
    type: SET_ROUTE_CONFIG_DATA,
    data
  }
}
