import { SET_ROUTE_CONFIG_DATA } from "../actionTypes";

const initialState = {
  data: []
};

const RouteConfigReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_ROUTE_CONFIG_DATA:
      return { ...state, data: [...action.data] };
    default:
      return {...state};
  }
};

export default RouteConfigReducer;
  