import { ValidationV2 } from "./ValidationV2";

const formArray = [];
export let indexCountObj = {};

/**
 * @function getFormData
 * @param {object} formData
 * @description get dynamic form data and check validation
 */
const setFormData = (formData) => {
  formArray.push(formData);
  return formArray.length - 1;
}

const removeFormData = (index) => {
  formArray[index] = null;
}

/**
 * @function getFormData
 * @param {object} formData
 * @description get dynamic form data and check validation
 */
const getFormData = () => {
  for (const val of formArray) {
    if (val) {
      val.isValid = false;
    }
  };
  return formArray;
}

/**
 * @function generateFormData
 * @param {object} formData 
 * @description generate dynamic form data using recursion
 */
const generateFormData = (formValues) => {

  const formData = {
    isValid: true,
    values: {}
  };
  
  function generateData(data, finalObj) {
    if (data && data.fields) {
      for (const field of data.fields) {
        let formValue = field.value || '';

        // in case of file upload value send as image_id
        if (field.column_type === 'FILE') {
          formValue = [field.image_id] || [];
        }
        
        if (finalObj.hasOwnProperty(field.column_input_name)) {
          if (finalObj[field.column_input_name] instanceof Array && field.column_type !== 'FILE') {
            finalObj[field.column_input_name].push(formValue);
          } else {

            // handled FILE type case
            if (field.column_type === 'FILE' && (finalObj[field.column_input_name]).length > 1) {
              finalObj[field.column_input_name] = [...finalObj[field.column_input_name], formValue];
            } else {
              finalObj[field.column_input_name] = [finalObj[field.column_input_name], formValue];
            }
          }
        } else {
          finalObj[field.column_input_name] = formValue;
        }

        // check validation
        const {isValid} = ValidationV2.checkValidation(field.value, field.validation_rule, field.column_label, field.column_type);
        field.isValid = isValid;
        if (formData.isValid) {
          formData.isValid = isValid;
        }

        // check selected field dependencies
        if (data.dependencies && data.dependencies[field.column_input_name]) {
          const dependencies = data.dependencies[field.column_input_name] || [];
          const selectedChild = dependencies.find((val) => {
            return val.key === field.value;
          });

          if (selectedChild && selectedChild.child_fields) {
              finalObj[`${field.column_input_name}_dependency`] = {};
              generateData(selectedChild.child_fields, finalObj[`${field.column_input_name}_dependency`]);

              // grouping form_fields if required
              if (selectedChild.hasGrouping) {
                const dependencyGroup = [];
                // const groupDependency = finalObj[`${field.column_input_name}_dependency`];
                const childKeysList = Object.entries(finalObj[`${field.column_input_name}_dependency`]);
                
                for (let index = 0; index < childKeysList.length; index++) {
                  
                  const [dependencyKey, dependencyVal] = childKeysList[index];
                  let obj = {};
                  if (dependencyVal instanceof Array) {
                    for (let index_ = 0; index_ < dependencyVal.length; index_++) {
                      obj = {
                        [dependencyKey]: dependencyVal[index_]
                      };
                      dependencyGroup[index_] = dependencyGroup[index_] ? {...dependencyGroup[index_], ...obj} : obj;
                    }
                  } else {
                    obj = {
                      [dependencyKey]: dependencyVal
                    };
                    dependencyGroup[0] = dependencyGroup[0] ? {...dependencyGroup[0], obj} : obj;
                  }
                }
                finalObj[`${field.column_input_name}_dependency`] = dependencyGroup;
              }
          }
        }
      }
    }
    return finalObj;
  }

  // calling recursion function
  generateData(formValues, formData.values);
  return formData;
}

/**
 * @function formatDependencies
 * @param {object|Array} dependencies
 * @description If dependencies got object then change into array (eg. range: {min: 5, max: 6} to [{key: 5, value: 5}, {key: 6, key: 6}])
 */
const formatDependencies = (dependencies, column_input_name) => {
  const dependency = dependencies[column_input_name];
  if (dependency && !(dependency instanceof Array)) {
    if (dependency.range) {
      const options = [];
      for (let count = dependency.range.min; count <= dependency.range.max; count++) {
        let childField = null;
        if (dependency.child_fields) {
          dependency.org_child_fields = JSON.parse(JSON.stringify(dependency.child_fields));
          childField = {
            fields: [],
            dependencies: {}
          };
          for (let childCount = 0; childCount < count; childCount++) {
            childField.fields.push(...JSON.parse(JSON.stringify(dependency.org_child_fields.fields)));
            childField.dependencies = JSON.parse(JSON.stringify(dependency.org_child_fields.dependencies));
          }
          // delete dependencies.child_fields;
        }
        options.push({
          key: count,
          value: count,
          hasGrouping: true,
          child_fields: childField
        });
      }
      // return options;
      dependencies[column_input_name] = options;
    }
  }
  return dependencies[column_input_name];
}
/**
 * @function formatFieldsData
 * @param {object} fieldsList 
 * @param {object} draftData 
 * @description update form fields array according to getInfo API data
 */
const formatFieldsData = (fieldsList, draftData) => {
  let newFieldList = [];
  if (fieldsList) {
    for (let index = 0;  index < fieldsList.length; index++) {
      let field = fieldsList[index];

      if (field.column_input_name === 'director_kyc' || field.column_input_name === 'director_name' || field.column_input_name === 'shareholding'  || field.column_input_name === 'partnership_kyc' || field.column_input_name === 'partner_name') {
        newFieldList = fieldsList;
        continue;
      }
      const draft = draftData[field.column_input_name];

      const commonKeyFields = newFieldList.filter((val) => {
        return val.column_input_name === field.column_input_name;
      })
      // prevent to add more field in FieldList array on component change detection
      if (field.column_type === 'FILE' && draft && draft.length !== 0 && commonKeyFields.length >= draft.length) {
        continue;
      }

      newFieldList.push(field);
      if (field.column_type === 'FILE') {
        if (draft?.length > 1) {
          for (let sIndex = 1; sIndex < draft.length; sIndex++) {
            if (newFieldList.length <= draft.length) {
              field = JSON.parse(JSON.stringify(field));
              newFieldList.push(field);
            }
          }
        }
      }
    }
    return newFieldList;
  } else {
    return fieldsList;
  }
}

export default {
  getFormData,
  setFormData,
  removeFormData,
  formatDependencies,
  generateFormData,
  indexCountObj,
  formatFieldsData
};
