import { createHashHistory } from 'history';
import formData from './getFormData';
// eslint-disable-next-line no-restricted-globals
const history = createHashHistory();
// eslint-disable-next-line no-restricted-globals
history.listen(_ => {
  formData.indexCountObj = {};
  window.scrollTo(0, 0)
});

export default history;