/* eslint-disable no-param-reassign */
import config from '../config';
import nativeSupport from './nativeSupport';

const PLATFORM = {
  NATIVE: 'MERCHANT_APP',
  WEB_APP: 'WEB_APP',
};
const FIREBASE_SCRIPTS = [
  {
    url: 'https://www.gstatic.com/firebasejs/7.23.0/firebase-app.js',
    id: 'firebase-app',
  },
  {
    url: 'https://www.gstatic.com/firebasejs/7.23.0/firebase-analytics.js',
    id: 'firebase-analytics-app',
  },
];

// Your web app's Firebase configuration
const FIREBASE_CONFIG = {
  apiKey: 'AIzaSyAYAtgxwors5kdlTrTDW7kbdOg25C9JhBM',
  authDomain: 'fin-dash-web.firebaseapp.com',
  databaseURL: 'https://fin-dash-web.firebaseio.com',
  projectId: 'fin-dash-web',
  storageBucket: 'fin-dash-web.appspot.com',
  messagingSenderId: '1068034539759',
  appId: '1:1068034539759:web:8493fcf0203686b4029b1a',
  measurementId: 'G-15Y81PD6FX',
};

const getCommonProperties = () => {
  return {
    app_name: config.appName,
    platform: getPlatform(),
  };
};

const getPlatform = () => {
  if (nativeSupport.isNative()) return PLATFORM.NATIVE;
  return PLATFORM.WEB_APP;
};

const formatEventName = eventName => `${config.APP_SHORT_NAME}_${eventName}`;

// eslint-disable-next-line consistent-return
const addScript = (count = 0, callback) => {
  const script = FIREBASE_SCRIPTS[count];
  if (document.querySelector(script?.id)) {
    // eslint-disable-next-line no-plusplus
    if (FIREBASE_SCRIPTS[++count]) return addScript(count, callback);
    callback();
  }

  const scriptElem = document.createElement('script');
  scriptElem.setAttribute('src', script.url);
  scriptElem.setAttribute('id', script.id);

  const removeEvents = () => {
    scriptElem.removeEventListener('error', scriptFailed);
    scriptElem.removeEventListener('load', scriptLoad);
  };

  // eslint-disable-next-line consistent-return
  const scriptLoad = () => {
    removeEvents();
    // eslint-disable-next-line no-param-reassign
    // eslint-disable-next-line no-plusplus
    if (FIREBASE_SCRIPTS[++count]) return addScript(count, callback);
    callback();
  };

  const scriptFailed = () => {
    removeEvents();
    document.head.removeChild(scriptElem);
  };

  // eslint-disable-next-line consistent-return
  scriptElem.addEventListener('load', scriptLoad);
  // eslint-disable-next-line consistent-return
  scriptElem.addEventListener('error', scriptFailed);

  document.head.appendChild(scriptElem);
};

const initFirebase = () => {
  if (nativeSupport.isDevMode()) return Promise.reject();

  return new Promise(resolve => {
    addScript(0, () => {
      // Initialize Firebase
      window.firebase.initializeApp(FIREBASE_CONFIG);
      window.firebase.analytics().setUserProperties(getCommonProperties());
      resolve();
    });
  });
};

const logEvent = async (eventName, properties = {}) => {
  try {
    const appName = config.appName;

    if (!window.firebase || !window.firebase.analytics) await initFirebase();

    if (!eventName || !appName) {
      // eslint-disable-next-line no-console
      console.error('eventName or appName not found!');
      return;
    }
    properties = { ...properties, ...getCommonProperties() };
    window.firebase.analytics().logEvent(formatEventName(eventName), properties);
  } catch (e) {
    console.error('FirebaseNotInject: firebase library not injected!', e);
  }
};

export default {
  initFirebase,
  logEvent,
};
