import './utils/polifill';
import React from 'react';
import ReactDOM from 'react-dom';
// REDUX
import { Provider } from 'react-redux';
import store from './redux/store';
// CONTAINERS
import App from './App';
// STYLES
import './styles/variables.css';
import './styles/index.css';
import './styles/classes.css';
import './styles/common.css';
import 'react-toastify/dist/ReactToastify.css';
import { initSentry } from './utils';

import packageFile from "../package.json"

console.log(`------ Version: ${packageFile.version} - Build: ${process.env.NODE_ENV}`);

initSentry();

ReactDOM.render(
	<Provider store={store}>
		<App/>
	</Provider>, 
	document.getElementById('root')
);