export default {
  app_version: '1.2.0',
  appName: 'ONBOARDING_APP',
  APP_SHORT_NAME: 'OA',
  SENTRY_DNS: 'https://99218548b7eb41769ddabc97d7645ff4@sentry.bharatpe.in/41',
  BASE_PATH_DEV: 'https://invoicefinancing.bharatpe.io/api/retailer',
  // BASE_PATH_PROD: 'https://invoicefinancing.bharatpe.in/api/retailer',
  PROXY_BASE_PATH_DEV: 'https://api-retailer-finance.bharatpe.io/retailer',
  // PROXY_BASE_PATH_PROD: 'https://retailer-api.bharatpe.in/retailer',
  PROXY_2_BASE_PATH_DEV : 'https://api-channel-finance.bharatpe.io/',
  // PROXY_2_BASE_PATH_PROD : 'https://channel-api.bharatpe.in/',
  PROXY_2_BASE_PATH_PROD : 'https://api-channel-finance.bharatpe.io/',
  BASE_PATH_PROD: 'https://invoicefinancing.bharatpe.io/api/retailer',
  PROXY_BASE_PATH_PROD: 'https://api-retailer-finance.bharatpe.io/retailer',

};