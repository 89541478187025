// LIBRARIES
import { UPLOAD_IMAGE, DELETE_IMAGE } from '../actionTypes';

// request to upload image to server
export const uploadImagePending = (data, callback = () => {}) => {

  return {
    type: UPLOAD_IMAGE,
    data,
    callback
  }
};

// request to detele image from server
export const deleteImage = (data, callback = () => {}) => {
  return {
    type: DELETE_IMAGE,
    data,
    callback
  }
};
