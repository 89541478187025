import { put, takeLatest, all } from 'redux-saga/effects';
import { apiCall } from "../../services";
import { RESEND_OTP_URL, RESEND_OTP_ACTION_LOADING } from './constants';
import { resendOtpSuccessAction, resendOtpFailureAction } from './actions';

/**
 * @function resendOtpToServer
 * @param {object} action
 * @description request for resend OTP to server
 */
function* resendOtpToServer(action) {
  try {
    const response = yield apiCall({
      method: 'POST',
      url: RESEND_OTP_URL,
      data: action.payload,
      options: {
        allowHeaderToken: true,
        useProxyServer: true,
      }
    });
  
    const resData = response.data;
    if (resData && resData.success) {
      yield put(resendOtpSuccessAction(resData.data));
      return;
    }

    yield put(resendOtpFailureAction(resData));
  } catch (error) {
    yield put(resendOtpFailureAction(error));
  }
}

export function* loadResendLoginOtpSaga() {
  yield all([takeLatest(RESEND_OTP_ACTION_LOADING, resendOtpToServer)]);
}
