export const GLOBAL_CONSTANT = {
    RESIDENTIAL_PROOF_LIST: ["Aadhaar Card", "Voter ID Card", "Driving Licence", "NREGA Card", "Passport"],
    GENDER_LIST: ["Male", "Female", "Other"],
	STATE_LIST: ['Karnataka', 'Telangana'],
	ANDROID_APP_URL: 'https://play.google.com/store/apps/details?id=com.bharatpe.app',
	IOS_APP_URL: 'https://apps.apple.com/in/app/bharatpe/id1329385873',
	CONSENT_TNC: './consent.html',
	OTP_TIMER: 60000,
	EXPERIAN_STATUS: {
		SUCCESS: 'SUCCESS',
		FAIL: 'FAIL',
		MANUAL: 'MANUAL'
	},
	NACH_STATUS: {
		APPROVED: 'APPROVED',
		INPROCESS: 'INPROCESS'
	},
	FORM_FIELD_TYPES: {
		TEXT: 'TEXT',
		NUMBER: 'NUMBER',
		TEL: 'TEL',
		RADIO: 'RADIO',
		DATE: 'DATE',
		DROPDOWN: 'DROPDOWN',
		FILE: 'FILE',
	},
	TNC_TYPE: {
		CONSENT: 'CONSENT',
		AGREEMENT: 'AGREEMENT',
	},
	FORM_FIELD_VALIDATION_TYPES: {
		REQUIRED: 'required',
		MIN: 'min',
		MAX: 'max',
		REGEX: 'regex',
	},
	ORDER_QR_DEEP_LINK: 'bharatpe://dynamic?key=get-qr-web',
	RETAILER_APP_DEEP_LINK: 'retailer-app',
	RETAILER_APP_DEV_DEEP_LINK: 'retailer-app-dev',
	PLATFORMS: {
		WEB: 'WEB',
		APP: 'APP',
	},
    CITY_LIST: {
            Karnataka: [
                'Bagalkot',
	            'Bangalore',
	            'Bangalore Rural',
	            'Belgaum',
	            'Bellary',
	            'Bidar',
	            'Chamrajnagar',
	            'Chickmagalur',
	            'Chikkaballapur',
	            'Chitradurga',
	            'Dakshina Kannada',
	            'Davangere',
	            'Dharwad',
	            'Gadag',
                'Hassan',
                'Gulbarga',
	            'Haveri',
	            'Kodagu',
	            'Kolar',
	            'Koppal',
	            'Mandya',
	            'Mysore',
	            'Raichur',
	            'Ramanagar',
	            'Shimoga',
	            'Tumkur',
	            'Udupi',
	            'Uttara Kannada',
	            'Yadgir',
	            'Bijapur'
            ],
            Telangana: [
                'Adilabad',
	            'Hyderabad',
	            'K.V.Rangareddy',
	            'Karim Nagar',
	            'Khammam',
	            'Mahabub Nagar',
	            'Medak',
	            'Nalgonda',
	            'Nizamabad',
	            'Warangal'
            ]
        
        }
}

export const INITIAL_STATE = {
	isFetching: false,
	success: false,
	dataFetched: false,
	data: {},
	message: '',
};
  