import PropTypes from 'prop-types';
import React, { useEffect, useState, useCallback } from 'react';
import './styles.scss';
const FancyRadioGroup = ({selectedValue, title, onFormValueChange, options, extraClass, readonly, ...props}) => {

  const [uniqueName, setUniqueName] = useState(+new Date());
  const [selected, setSelected] = useState('');

  const onRadioChange = useCallback((val) => {
    setSelected(val.key);
    onFormValueChange(val.key)
  }, [onFormValueChange]);

  useEffect(() => {
    setSelected(selectedValue);
  }, [selectedValue])

  return (
    <div className={`fancy-radio-group ${extraClass}`}>
      {title && <div>{title}</div>}
      {
        options.map((val, key) => {
          return (
            <div className="radio-item">
              <input disabled={readonly} checked={val.key === selected} type="radio" id={`${val.key}-${uniqueName}`} name={val.key} value={val.key} onChange = { () => onRadioChange(val)} />
              <label htmlFor={`${val.key}-${uniqueName}`}>
                <span>{val.value}</span>
              </label>
            </div>
          )
        })
      }
    </div>
  )
}


FancyRadioGroup.propTypes = {
  title: PropTypes.string,
  selectedValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
  ]),
  onFormValueChange: PropTypes.func,
  options: PropTypes.array,
  readonly: PropTypes.bool,
  extraClass: PropTypes.string,
};

FancyRadioGroup.defaultProps = {
  title: '',
  selectedValue: '',
  onFormValueChange: () => {},
  options: [],
  readonly: false,
  extraClass: '',
};

export default FancyRadioGroup;