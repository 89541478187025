import PropTypes from 'prop-types';
import React from 'react';
import './styles.css';
import cx from 'classnames';

export class Radio extends React.Component { 
  render() {
    const {name, selectedValue, onChange} = this.context.radioGroup;
    const optional = {};
    if(selectedValue !== undefined) {
      optional.checked = (this.props.value === selectedValue);
    }
    if(typeof onChange === 'function') {
      optional.onChange = onChange.bind(null, this.props.value);
    }

    return (
      <div className={`custom-radio-btn ${optional.checked === true ? 'show': 'hide '} ${this.props.extraClass}`} style={{position: 'relative'}}>
          <label>
            <input
              {...this.props}
              role="radio"
              aria-checked={optional.checked}
              type="radio"
              name={name}
              {...optional} />
              <span className="checkmark"></span>
              {this.props.title}
          </label>
          <div className={`description ${optional.checked === true ? 'show': 'hide '}`}>
              {this.props.description}
          </div>
      </div>
    );
  }
};

Radio.contextTypes = {
  radioGroup: PropTypes.object
};

export  class RadioGroup extends React.Component {
  getChildContext() {
    const {name, selectedValue, onChange} = this.props;
    return {
      radioGroup: {
        name, selectedValue, onChange
      }
    }
  }

  renderLabel() {
    const { label, labelClassName } = this.props;

    return (
      <label className={cx('input__label', 'radio-group-label', labelClassName)}>
        {label}
      </label>
      // <label className={cx('input__label', labelClassName)} style={labelStyle}>
      //   {label}
      //   {required && this.renderIsImportant()}
      // </label>
    );
  }

  render() {
    const {Component, name, selectedValue, onChange, children, extraClass, ...rest} = this.props;
    return (
        <>
          {this.props.hasLabel && this.renderLabel()}
          <div className={`custom-radio-btn-group ${extraClass}`} role="radiogroup" {...rest}>
            {children}
          </div>
        </>
      );
  }
};


RadioGroup.propTypes = {
  name: PropTypes.string,
  selectedValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
  ]),
  onChange: PropTypes.func,
  children: PropTypes.node.isRequired,
  Component: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
    PropTypes.object,
  ]),
  extraClass: PropTypes.string
};

RadioGroup.childContextTypes = {
  radioGroup: PropTypes.object
};