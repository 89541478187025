import { GET_FORM_CONFIG_DATA, SET_FORM_CONFIG_DATA, RESET_FORM_CONFIG_DATA } from "../actionTypes";

// get config data from server
export function getConfigData(callback) {
  return {
    type: GET_FORM_CONFIG_DATA,
    callback
  }
}

// set data to Config reducer
export function setConfigData(data) {
  return {
    type: SET_FORM_CONFIG_DATA,
    data
  }
}

// reset data of config reducer
export function resetConfigData() {
  return {
    type: RESET_FORM_CONFIG_DATA
  }
}
