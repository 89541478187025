import {  put, takeEvery } from 'redux-saga/effects';
import { DELETE_IMAGE } from "../actionTypes";
import { apiCall } from "../../services";
import { URL_CONSTANTS } from '../../constants/urlConstant'
import { toast } from 'react-toastify';
import { MESSAGE_CONSTANT } from '../../constants/msgConstant';


function* deleteImage(actions) {
  
  const response = yield apiCall({
    method: 'POST',
    url: URL_CONSTANTS.DELETE_IMAGE,
    data: { params: actions.data }
  });

  if (response.data && response.data.success){
    actions.callback(response.data.success, response.data.data);
    toast.success(response.data.message || MESSAGE_CONSTANT.IMAGE_DELETED);
  } else {
    actions.callback(false, response.status);
  }
}

export default function* root() {
  yield takeEvery(DELETE_IMAGE, deleteImage);
}