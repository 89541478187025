import {
  RESEND_OTP_ACTION_LOADING,
  RESEND_OTP_ACTION_SUCCESS,
  RESEND_OTP_ACTION_FAILURE,
  RESET_RESEND_OTP_ACTION,
} from './constants';

// resend OTP actions
export const resendOtpDataAction = payload => {
  return {
    type: RESEND_OTP_ACTION_LOADING,
    payload,
  };
};

export const resendOtpSuccessAction = data => {
  return {
    type: RESEND_OTP_ACTION_SUCCESS,
    data,
  };
};

export const resendOtpFailureAction = error => {
  return {
    type: RESEND_OTP_ACTION_FAILURE,
    error,
  };
};

export const resetResendOtpAction = () => {
  return {
    type: RESET_RESEND_OTP_ACTION,
  };
};
