import { EXPERIAN_CHECK_SUCCESS, EXPERIAN_CHECK_FAILURE } from "../actionTypes";

const initialState = {
  data: {}
};

const ExperianCheckReducer = (state = initialState, action) => {
  switch (action.type) {
    case EXPERIAN_CHECK_SUCCESS:
      return { ...state, data: {...action.data} };
    case EXPERIAN_CHECK_FAILURE:
      return { ...state, data: {...initialState.data} };
    default:
      return {...state};
  }
};

export default ExperianCheckReducer;
  