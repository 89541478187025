import { combineReducers } from 'redux';
// REDUCERs
import loader from "./loader.reducer";
import DraftReducer from './draft-data.reducer';
import FormConfigReducer from './formConfig.reducer';
import RouteConfigReducer from './routeConfig.reducer';
import OtpModalReducer from './otpModal.reducer';
import ExperianCheckReducer from './experianCheck.reducer';
import { getRequestLoginOtpReducer } from './loginOtp.reducer';
import { getVerifyLoginOtpReducer } from './verifyLoginOtp.reducer';
import { getResendLoginOtpReducer } from '../../hoc/ResendLoginOtp/reducer';

const rootReducer = combineReducers({
	loader,
	DraftReducer,
	FormConfigReducer,
	RouteConfigReducer,
	OtpModalReducer,
	ExperianCheckReducer,
	RequestLoginOtpReducer: getRequestLoginOtpReducer,
	VerifyLoginOtpReducer: getVerifyLoginOtpReducer,
	ResendLoginOtpReducer: getResendLoginOtpReducer,
});
export default rootReducer;