import {  put, takeEvery } from 'redux-saga/effects';
import { GET_FORM_CONFIG_DATA } from "../actionTypes";
import { apiCall } from "../../services";
import { URL_CONSTANTS } from '../../constants/urlConstant'
import { setConfigData, resetConfigData } from '../actions/formConfig.action';
import { setRouteConfigData } from '../actions/routeConfig.action';
import store from '../store';
import { history } from '../../utils';


function* getFormConfig(actions) {

  const response = yield apiCall({
    method: 'POST',
    url: URL_CONSTANTS.CONFIG_DATA
  });

  if (response.data && response.data.success === true) {
    const draftReducer = store.getState().DraftReducer.data;
    const resData = response.data.data;
    yield put(setConfigData(resData));

    // get route info
    const routeList = [];
    for (const val of Object.values(resData.screens)) {
      const routeObj = {
        path: `/step/:stepId`,
        type: val.type,
      };
      routeList.push(routeObj);
    }
    yield put(setRouteConfigData(routeList));

    // find current active page and redirect
    if (draftReducer.activePageType && resData.screens) {
      let currentActivePage = 1;
      const screensKeys = Object.keys(resData.screens);
      for (const key of screensKeys) {
        if (resData.screens[key]?.type === draftReducer.activePageType) {
          currentActivePage = key;
          break;
        }
      }
      history.replace(`/step/${currentActivePage}`);
    }
  } else {
    yield put(resetConfigData());
  }
}

export default function* root() {
  yield takeEvery(GET_FORM_CONFIG_DATA, getFormConfig);
}