import history from "./history";
import nativeSupport from "./nativeSupport";
import store from "../redux/store";
import { sendOtp } from "../redux/actions";
import firebase from "./firebase";
import FirebaseEventConstant from "./firebaseEventConstant";


/**
 * @description perform next task decision making
 */
export const RouteManagerUtil = {
    // perform next task
    goToNext: (data) => {
      const formConfigReducer = store.getState().FormConfigReducer.data;
      const otpModalReducer = store.getState().OtpModalReducer.data;
      const draftReducer = store.getState().DraftReducer.data;
      const currentStep = Number(data.currentStep);
      const currentScreen = formConfigReducer.screens[currentStep];
    
      if (currentScreen) {
        // handling for OTP modal (in case of Merchant app no need to ask for OTP)
        if (currentScreen.otp_flag && !nativeSupport.isNative() && !otpModalReducer.show) {
          store.dispatch(sendOtp());
          firebase.logEvent(FirebaseEventConstant.OTP_SENT);
        } else if (draftReducer.nach_check && currentScreen.nach_flag) {  // check also global nach_check value
          nativeSupport.openDeepLink(draftReducer.deeplink).then(() => {
            RouteManagerUtil.goToNextRoute(currentStep);
          }).catch((e) => {
            // handle error
          });
          firebase.logEvent(FirebaseEventConstant.NACH_CLICKED);
        } else {
          RouteManagerUtil.goToNextRoute(currentStep);
        }
      }
    },

    // go to next route
    goToNextRoute: (currentStep) => {
      const formConfigReducer = store.getState().FormConfigReducer.data;
      const nextStep = Number(currentStep) + 1;
      const nextScreen = formConfigReducer.screens[nextStep];
      if (nextScreen) {
        history.push(`/step/${nextStep}`);
      } else {
        console.error('Next step not available!!!');
      }
    }
}
