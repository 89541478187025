import {  put, takeEvery } from 'redux-saga/effects';
import { GET_INFO } from "../actionTypes";
import { apiCall } from "../../services";
import { URL_CONSTANTS } from '../../constants/urlConstant'
import { updateDraftData, resetDraftData } from '../actions/draft-data.action';
import { history } from '../../utils';


function* getInfo(actions) {

  const response = yield apiCall({
    method: 'POST',
    url: URL_CONSTANTS.GET_INFO,
    options: actions.options,
  });
  
  if (response.data && response.data.success) {
    const resData = response.data.data;
    if (resData.deeplink) {
      resData.deeplink = `${resData.deeplink}&resultCode=true`;
    }
    yield put(updateDraftData(resData));
    actions.callback(true);
  } else {
    yield put(resetDraftData());
    actions.callback(false);
    if (response.status !== 401) {
      history.replace('/401');
    }
  }
}

export default function* root() {
  yield takeEvery(GET_INFO, getInfo);
}