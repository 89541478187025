import * as Sentry from '@sentry/react';
import config from '../config';

/**
 * @function initSentry
 * @description initialize sentry
 */
const initSentry  = () => {
    if (process.env.REACT_APP_ENV === "production") {
        Sentry.init({dsn: config.SENTRY_DNS});
    }
}

export default initSentry;