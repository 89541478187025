const FirebaseEventConstant = {
  USER_401: 'USER_NOT_AUTHORIZED',
  NACH_CLICKED: 'NACH_CLICKED',
  OTP_SENT: 'OTP_SENT',
  OTP_RESENT: 'OTP_RESENT',
  OTP_VERIFY: 'OTP_VERIFY',
  NO_MATCH: 'ROUTE_NOT_MATCH',
};

export default FirebaseEventConstant;
