import { takeEvery, put } from 'redux-saga/effects';
import { VERIFY_OTP } from "../actionTypes";
import { apiCall } from "../../services";
import { URL_CONSTANTS } from '../../constants/urlConstant';
import { resetDraftData } from '../actions/draft-data.action';
import { hideOtpModal } from '../actions/otpModal.action';
import { RouteManagerUtil } from '../../utils/routeManagerUtil';

function* verifyOtp(actions) {

  const data = {
    otp: actions.data.otp
  };
  
  const formData = yield apiCall({
    method: 'POST',
    url: URL_CONSTANTS.VERIFY_OTP,
    data
  });

  if (formData.data && formData.data.success){
    yield put(resetDraftData());
    // go for running next task
    RouteManagerUtil.goToNext({
      currentStep: actions.data.currentStep
    });
    yield put(hideOtpModal());
  }
}

export default function* root() {
  yield takeEvery(VERIFY_OTP, verifyOtp);
}