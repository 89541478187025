
import React from 'react';
import './styles.scss';
import PropTypes from 'prop-types';

class DigitalWatch extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      seconds: 0,
      minutes: 0,
      timeCount: 0,
    };
    this.intervalRef = null;
  }

  /**
   * @description start watch countdown on component inisilized
   */
  componentDidMount() {
    // show value on timer
    const time = this.getMinSec(this.props.timeInMs);
    this.setState({
      minutes: time.min,
      seconds: time.sec
    });

    // start countdown
    this.startTimer();
  }

  /**
   * @function clearTimer
   * @description stop and clear Digital Watch countdown
   */
  clearTimer() {
    clearInterval(this.intervalRef);
    this.intervalRef = null;
  }

  /**
   * @function startTimer
   * @description start Digital Watch countdown
   */
  startTimer() {
    const { notifyInEvery, notifyOnTimeBreak } = this.props;
    const intervalRef = setInterval(() => {
      this.setState(prev => ({timeCount: prev.timeCount + 1000 }));
      const timeDiff = this.props.timeInMs - this.state.timeCount;
      if (timeDiff < 0) {
        this.clearTimer();
        this.props.onTimeEnd();
        return;
      }
      const time = this.getMinSec(timeDiff);
      this.setState({
        minutes: time.min,
        seconds: time.sec,
      });

      // notify parent component in every *notifyInEvery* milliseconds
      if (notifyInEvery !== 0 && (this.state.timeCount % notifyInEvery) === 0) {
        notifyOnTimeBreak(timeDiff);
      }
    }, 1000);

    this.intervalRef = intervalRef;
  }

  /**
   * @function getMinSec
   * @param {number} millSec
   * @description convert milliseconds to minutes and seconds
   */
  getMinSec(millSec) {
    const minutes = Math.floor(millSec / 60000);
    const seconds = ((millSec % 60000) / 1000).toFixed(0);

    return {
      min: minutes,
      sec: seconds
    };
  }

  /**
   * @description stop watch timer on component destroy
   */
  componentWillUnmount() {
    this.clearTimer();
  }

  render() {
    const { props, state } = this;
    return (
      <span className={`digital-watch ${props.customClass}`}>
        {state.minutes < 10 ? '0' + state.minutes : state.minutes}{' '}
        <span className="font-light">MIN</span> :{' '}
        {state.seconds < 10 ? '0' + state.seconds : state.seconds}{' '}
        <span className="font-light">SEC</span>
      </span>
    );
  }
}

DigitalWatch.propTypes = {
  timeInMs: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  customClass: PropTypes.string,
  notifyInEvery: PropTypes.number,
  notifyOnTimeBreak: PropTypes.func,
  onTimeEnd: PropTypes.func,
};

DigitalWatch.defaultProps = {
  timeInMs: 0,
  customClass: '',
  notifyInEvery: 0,
  notifyOnTimeBreak: () => {},
  onTimeEnd: () => {},
};

export default React.memo(DigitalWatch);
